import { defineStore } from "pinia";
import axiosOne from "../axios_one.js";
// import axiosFormData from "../axiosformdata.js";
import { showNotificationConfirmation } from "@/functions/general.js";
import { useNotificationStore } from "./notification.js";

export const useEventScanner = defineStore("eventscanner", {
    state: () => ({
        // ==================== states used on event scanner page and its relation
        eventVerificationType: 'scan',
        eventData: null,
        cameras: null,
        scanner: {status: false}
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on event scanner page and its relation
        setEventVerificationType(type){
            this.eventVerificationType = type;
        },
        async setEventVerificationData(slug){
            try{
                const response = await axiosOne.get(`ev-attendance/participants/${slug}`);
                if(response.data?.id){
                    this.eventData = response.data;
                }else{
                    location.href = process.env.BASE_URL + 'not-found';
                }
                console.log(response);
            }catch(error){
                console.log(error);
            }
        },
        async sendQRCode(code){
            // console.log('QC : ' + code);
            const params = {
                event: this.eventData.id,
                unique: code
            };
            // params.device_id = getCookie('deviceid');
            // params.attachment = selfie;
            // console.log(params);
            // window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const response = await axiosOne.post(`ev-attendance/confirm-scan`, params, {headers: {"Content-Type": "multipart/form-data"}});
                // if(response.status == 200){
                //     showNotification('', response.data.message, 5000, null);
                //     // router.push('/presensi');
                //     return 1;
                // }
                // var rwdata = [
                //     {
                //         "field_value": "Yassha hadiansyah",
                //         "id": 20,
                //         "ev_attendance_id": 4,
                //         "type": "text",
                //         "label": "Nama",
                //         "placeholder": null,
                //         "name": "name",
                //         "required": true,
                //         "unique": false,
                //         "link": null,
                //         "parent_id": null,
                //         "description": null,
                //         "order": 0,
                //         "accept_files": null,
                //         "created_at": "2024-07-05T07:20:55.000000Z",
                //         "updated_at": "2024-07-09T06:23:33.000000Z",
                //         "query": null,
                //         "show_announce": true
                //     },
                //     {
                //         "field_value": "081222187416",
                //         "id": 21,
                //         "ev_attendance_id": 4,
                //         "type": "text",
                //         "label": "Nomor Ponsel",
                //         "placeholder": null,
                //         "name": "phone",
                //         "required": true,
                //         "unique": false,
                //         "link": "https://wa.me/{phone}?text=Selamat Siang berikut adalah link untuk daftar hadir anda: https://melindahospital.com/event/attendance/{user_id}",
                //         "parent_id": null,
                //         "description": null,
                //         "order": 0,
                //         "accept_files": null,
                //         "created_at": "2024-07-05T07:51:04.000000Z",
                //         "updated_at": "2024-07-09T06:23:42.000000Z",
                //         "query": null,
                //         "show_announce": true
                //     }
                // ];
                var rowsdata = [];
                for(var i = 0; i < response.data.data.length; i++){
                    rowsdata.push(
                        {
                            param: response.data.data[i].label,
                            value: response.data.data[i].field_value
                        }
                    );
                }
                useNotificationStore().setNotificationConfirmationData(
                    {
                        status: true,
                        title: '',
                        message: response.data.scan_before ? 'Peserta ini sudah pernah dipindai' : 'Ini pemindaian pertama untuk peserta ini',
                        rows: rowsdata,
                        background: 'var(--white)',
                        scanned: response.data.scan_before,
                        scanned_at: response.data.scan_at,
                        // debugger: response.data
                    }
                );
                showNotificationConfirmation();
                // alert(JSON.stringify(response));
                // return 1;
                // console.log(response);
            }catch(error){
                if(error.response?.status == 422){
                    useNotificationStore().setNotificationConfirmationData(
                        {
                            status: false,
                            message: 'Peserta tidak Valid'
                        }
                    );
                    showNotificationConfirmation();
                }else{
                    // useNotificationStore().setNotificationConfirmationData(
                    //     {
                    //         status: false,
                    //         message: JSON.stringify(error)
                    //     }
                    // );
                    // showNotificationConfirmation();
                    alert(`Error: ${JSON.stringify(error.response?.status)}`);
                }
                // console.log(error);
            }
        },
    }
});