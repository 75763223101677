<template>
    <section>
        <div class="section-bg"></div>
            <div class="section-content">
                <div class="sc-inner" v-if="medicine">
                    <!-- {{ medicine }} -->
                    <div class="sci-left">
                        <div class="sl-head">
                            <p class="small-title">Melinda Care</p>
                            <h1 class="main-title">{{ medicine.name }}</h1>
                            <!-- <p class="p-date">Melinda Care, 27 Juni 2022</p> -->
                        </div>
                        <div class="sl-body">
                            <div class="sl-img" v-if="medicine.images[0]">
                                <img :src="medicine.images[0].image" alt="melinda care" />
                            </div>
                            <div class="sl-content">
                                <div class="sl-content-description" v-html="medicine.description"></div>
                            </div>
                        </div>
                        <div class="sc-share">
                            <h2>Share to</h2>
                            <div class="sc-share-inner">
                                <div class="scs-part">
                                    <a href="https://www.facebook.com" target="_blank">
                                        <img src="../../assets/images/icons/cc-facebook.png" alt="melinda care">
                                    </a>
                                </div>
                                <div class="scs-part">
                                    <a href="https://www.twitter.com" target="_blank">
                                        <img src="../../assets/images/icons/cc-twitter.png" alt="melinda care">
                                    </a>
                                </div>
                                <div class="scs-part">
                                    <a href="https://www.linkedin.com" target="_blank">
                                        <img src="../../assets/images/icons/cc-linkedin.png" alt="melinda care">
                                    </a>
                                </div>
                                <div class="scs-part">
                                    <a href="https://www.instagram.com" target="_blank">
                                        <img src="../../assets/images/icons/cc-instagram.png" alt="melinda care">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sci-right">
                        <div class="sr-order">
                            <div class="sr-order-head">
                                <div class="soh-panel-button" @click="soToggle('sr-order-body');">Sembunyikan</div>
                            </div>
                            <div class="sr-order-body mhresize" id="sr-order-body">
                                <div class="sr-order-body-inner">
                                    <div class="so-row">
                                        <div class="so-row-param">Stock</div>
                                        <div class="so-row-value">
                                            <span id="srv-text-stock">{{ selectedStock.total }}</span>
                                        </div>
                                    </div>
                                    <div class="so-row">
                                        <div class="so-row-param">Harga</div>
                                        <div class="so-row-value">
                                            <input type="hidden" id="srv-input-id" :value="selectedStock.id" />
                                            <input type="hidden" id="srv-input-unit" :value="selectedStock.unit" />
                                            <input type="hidden" id="srv-input-price" :value="selectedStock.selling_price" />
                                            <!-- <input type="hidden" id="srv-input-stock" :value="medicine.stock[0].total" /> -->
                                            <div class="srv-select" v-if="medicine.stock">
                                                <div class="srv-button trigger" @click="priceToggle">
                                                    <div class="srv-button-text trigger">
                                                        <div class="sbt-price trigger">
                                                            <span class="med-price trigger">Rp{{ number_format(medicine.stock[0].selling_price, 0, ',', '.') }}</span>
                                                        </div>
                                                        <div class="sbt-unit trigger">
                                                            <span class="trigger">{{ medicine.stock[0].unit }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="srv-button-icon trigger" id="srv-button-icon">
                                                        <img src="../../assets/images/icons/arrow-1.png" alt="melinda care" />
                                                    </div>
                                                </div>
                                                <div class="srv-option float" id="srv-option">
                                                    <div class="srv-option-button" :id="'sob-' + index" v-for="(stock, index) in medicine.stock" :key="stock" @click="selectUnit('sob-' + index, stock);">
                                                        <div class="sbt-price trigger">
                                                            <span class="med-price trigger">Rp{{ number_format(stock.selling_price, 0, ',', '.') }}</span>
                                                        </div>
                                                        <div class="sbt-unit trigger">
                                                            <span class="trigger">{{ stock.unit }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="so-qty">
                                        <h3>Jumlah</h3>
                                        <div class="so-qty-inner">
                                            <div class="sq-button sq-min" @click="qtyChange('min')">-</div>
                                            <div class="sq-input">
                                                <input type="number" id="input-qty" minvalue="1" value="1" @blur="resetInputQty" />
                                            </div>
                                            <div class="sq-button sq-plus" @click="qtyChange('plus')">+</div>
                                        </div>
                                    </div>
                                    <div class="so-cart">
                                        <div class="so-cart-buy">
                                            <button class="buy-button" @click="directOrder(medicine);" v-if="loggedIn">Beli</button>
                                            <button class="buy-button" @click="showLogin" v-else>Beli</button>
                                            <!-- <router-link to="/order/products/confirmation"> -->
                                            <!-- </router-link> -->
                                        </div>
                                        <div class="so-cart-add">
                                            <button class="cart-button" @click="addToCart(medicine.healthfacility.id, medicine.healthfacility.name, medicine.name, medicine.images[0].image || null);">+ Keranjang</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="sr-part sr-categories" v-if="categories">
                            <h2>Categories</h2>
                            <div class="sr-link" v-for="category in categories" :key="category">
                                <router-link :to="'/obat/kategori/' + category.slug">
                                    <h3>{{ category.name }}</h3>
                                </router-link>
                            </div>
                        </div>
                        <!-- <div class="sr-part sr-tag">
                            <h2>Tags</h2>
                            <div class="sr-tag-inner">
                                <router-link to="">Obat Luar</router-link>
                                <router-link to="">Obat Keras</router-link>
                                <router-link to="">Anastesi</router-link>
                                <router-link to="">Vitamin</router-link>
                                <router-link to="">Suplemen</router-link>
                            </div>
                        </div> -->
                    </div>
                </div>
                <MedicineDetailSkeleton v-else/>
            </div>
    </section>
</template>

<script setup>
    import { useRoute } from 'vue-router';
    import { computed } from "@vue/runtime-core";
    import { useMedicineStore } from '@/stores/medicine';
    import MedicineDetailSkeleton from '../skeletons/MedicineDetailSkeleton.vue';
    import { priceToggle, selectUnit, directOrder, resetInputQty } from '@/functions/medicine';
    import { showLogin, number_format } from '@/functions/general';
    import { soToggle, qtyChange, addToCart } from "../../functions/cart";
    import { useAccountStore } from '@/stores/account';
    const medicine = computed(() => useMedicineStore().medicineDetail);
    // const mdstock = computed(() => useMedicineStore().medicineDetailStock);
    const selectedStock = computed(() => useMedicineStore().selectedStock);
    const categories = computed(() => useMedicineStore().categories);
    const loggedIn = computed(() => useAccountStore().loggedIn);
    useMedicineStore().setDetail(useRoute().params.detail);
    useMedicineStore().setCategories();
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
</script>

<style scoped>
    section{
        position: relative;
        width: 100%;
        min-height: 15rem;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        color: #1B4674;
        transition: 0.2s;
    }
    .section-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 26rem;
        background: #FEF8F0;
        z-index: -1;
        transition: 0.2s;
    }
    .small-title{
        color: #75C6EF;
        font-weight: 600;
        font-size: 1.125rem;
    }
    .main-title{
        font-family: poppins;
        font-size: 3.25rem;
        font-weight: 700;
        margin-top: 1rem;
        margin-bottom: 1rem;
        transition: 0.2s;
        line-height: 1.2;
    }
    .section-content{
        width: 100%;
        height: 100%;
        padding-left: 12.25rem;
        padding-right: 12.25rem;
        transition: 0.2s;
    }
    .sc-inner{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        padding-top: 2rem;
        transition: 0.2s;
    }
    .sci-left{
        width: 60%;
        transition: 0.2s;
    }
    .sl-body{
        padding-top: 2rem;
    }
    .sl-img{
        margin-bottom: 2rem;
    }
    .sl-img img{
        width: auto;
        width: 100%;
        transition: 0.2s;
    }
    .sl-content{
        text-align: justify;
    }
    .sl-content img{
        max-width: 100%;
    }
    .slc-part{
        margin-bottom: 2rem;
    }
    .slc-part h2{
        font-family: poppins;
        font-size: 1.75rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }
    .slc-part ul{
        list-style-type: none;
        padding-left: 0;
    }
    .slc-part ol{
        padding-left: 1rem;
    }
    .slc-part li{
        font-size: 1rem;
    }
    .sc-share{
        margin-top: 2rem;
    }
    .sc-share h2{
        font-family: poppins;
        font-size: 1rem;
        font-weight: 700;
    }
    .sc-share-inner{
        display: flex;
        flex-wrap: nowrap;
        padding-top: 1rem;
    }
    .scs-part{
        margin-right: 1rem;
    }
    .sci-right{
        width: 40%;
        padding-top: 26rem;
        padding-left: 5rem;
        transition: 0.2s;
    }
    .sr-order{
        position: relative;
        width: 100%;
        background: var(--white);
        margin-bottom: 2rem;
        z-index: 15;
    }
    .sr-order-head{
        position: relative;
        min-height: 27px;
        z-index: 5;
    }
    .soh-panel-button{
        position: absolute;
        top: 0;
        right: 0;
        display: none;
        padding: 10px;
        padding-bottom: 0;
        background: var(--white);
        border-top: 1px solid var(--smoke);
        border-top-left-radius: 10px;
        z-index: 5;
        transition: 0.2s;
    }
    .sr-order-body{
        max-height: 500px;
        transition: max-height 0.2s ease-in-out;
        z-index: 10;
    }
    .so-row{
        display: flex;
        width: 100%;
        padding: 0 0 1rem 0;
    }
    .so-row-param{
        width: 37%;
    }
    .so-row-value{
        width: 63%;
    }
    .srv-select{
        position: relative;
        user-select: none;
    }
    .srv-button{
        display: flex;
        cursor: pointer;
        padding: 0.5rem;
        border: 1px solid var(--softgrey);
        border-radius: 5px;
    }
    .srv-button-text{
        display: flex;
        width: 90%;
    }
    .srv-button-text .sbt-price{
        padding-left: 0.5rem;
    }
    .srv-button-text .sbt-unit{
        padding-left: 1rem;
    }
    .sbt-price{
        width: 50%;
    }
    .sbt-unit{
        width: 50%;
    }
    .srv-button-icon{
        width: 10%;
        text-align: center;
    }
    .srv-button-icon img{
        transition: 0.2s;
    }
    .srv-option{
        position: absolute;
        top: 115%;
        left: 0;
        width: 100%;
        border: 1px solid var(--softgrey);
        border-radius: 5px;
        background: var(--white);
        display: none;
        padding: 0.5rem;
    }
    .srv-option-button{
        display: flex;
        padding: 0.5rem;
        cursor: pointer;
        transition: 0.2s;
    }
    .srv-option-button:hover{
        background: var(--smoke);
    }
    .med-price{
        color: blue;
    }
    .so-qty{
        width: 75%;
        /* margin-top: 1rem; */
    }
    .so-qty h3{
        margin: 0;
        margin-bottom: 0.5rem;
    }
    .so-qty-inner{
        display: flex;
        justify-content: center;
        text-align: center;
    }
    .sq-button{
        background: var(--primary);
        color: var(--white);
        transition: 0.2s;
        border-radius: 5px;
        cursor: pointer;
    }
    .sq-button:hover{
        background: var(--primary-hover);
    }
    .sq-min{
        width: 25%;
    }
    .sq-input{
        width: 50%;
    }
    .sq-input input{
        outline: none;
        width: 100%;
        border: none;
        text-align: center;
    }
    .sq-input input::-webkit-outer-spin-button,
    .sq-input input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    .sq-input input[type=number] {
        -moz-appearance: textfield;
    }
    .sq-plus{
        width: 25%;
    }
    .so-cart{
        display: flex;
        width: 75%;
        margin-top: 2rem;
    }
    .so-cart-add{
        width: 50%;
        display: flex;
        justify-content: right;
    }
    .cart-button{
        background: var(--primary);
        color: var(--white);
        min-width: 100px;
        border: none;
    }
    .so-cart-buy{
        width: 50%;
        display: flex;
        justify-content: left;
    }
    .buy-button{
        background: var(--primary);
        color: var(--white);
        padding: 0.5rem;
        min-width: 100px;
        border: none;
    }
    .sr-part h2{
        font-family: poppins;
        font-weight: 700;
        font-size: 1.25rem;
        color: #bbbbbb;
    }
    .sr-categories{
        width: 100%;
        transition: 0.2s;
        margin-bottom: 2rem;
    }
    .sr-link{
        margin-top: 1rem;
    }
    .sr-link a{
        color: #1B4674;
        text-decoration: none;
    }
    .sr-tag{
        width: 100%;
        transition: 0.2s;
        margin-bottom: 2rem;
    }
    .sr-tag-inner{
        display: flex;
        flex-wrap: wrap;
        padding-top: 1rem;
        width: 100%;
    }
    .sr-tag-inner a{
        font-size: 1rem;
        color: #575757;
        border: 1px solid #575757;
        border-radius: 50px;
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        text-decoration: none;
        margin-top: 1rem;
        margin-right: 1rem;
    }
    @media only screen and (max-width: 1500px){
        .section-content{
            padding-left: 5%;
            padding-right: 5%;
        }
        .section-bg{
            height: 23rem;
        }
        .main-title{
            font-size: 2.6rem;
        }
    }
    @media only screen and (max-width: 1050px){
        .sc-inner{
            padding-top: 0;
        }
        .sci-left{
            width: 100%;
        }
        .sl-img img{
            width: 100%;
        }
        .main-title{
            font-size: 2rem;
        }
        .sci-right{
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding-top: 2rem;
            padding-left: 0;
        }
        .sr-order{
            position: fixed;
            left: 0;
            bottom: 0;
            margin-bottom: 0;
            padding-top: 0;
            padding-bottom: 0;
            background: none;
            z-index: 17;
        }
        .soh-panel-button{
            display: inline;
        }
        .sr-order-body{
            border-top: 1px solid var(--smoke);
            overflow: hidden;
            background: var(--white);
        }
        .sr-order-body-inner{
            padding: 2.5%;
        }
        .sr-check{
            margin-bottom: 1.5rem;
        }
        .sr-categories{
            width: 50%;
            margin-bottom: 0;
        }
        .sr-tag{
            width: 50%;
            margin-bottom: 0;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding-top: 1rem;
        }
        .section-bg{
            height: 19rem;
        }
        .section-content{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .sci-right{
            padding-top: 1rem;
        }
        .sr-check{
            padding: 0;
            margin-bottom: 1rem;
            box-shadow: unset;
            border-radius: 0;
        }
        .sr-categories{
            width: 100%;
        }
        .sr-tag{
            width: 100%;
        }
    }
</style>