<template>
    <div class="content">
        <ProfileComponent/>
    </div>
</template>
<script setup>
    import ProfileComponent from '@/components/account/ProfileComponent.vue';
    window.scrollTo(0, 0);
</script>
<style scoped>
    .about{
        text-align: center;
        padding-top: 5rem;
    }
</style>