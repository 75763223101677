<template>
    <div class="content">
        <TopBackgroundComponent/>
        <CrumbComponent :data="crumbdata"/>
        <OrderDetailComponent/>
    </div>
</template>
<script setup>
    import TopBackgroundComponent from '@/components/TopBackgroundComponent.vue';
    import CrumbComponent from '@/components/CrumbComponent.vue';
    import OrderDetailComponent from '@/components/order/OrderDetailComponent.vue';
    const crumbdata = [
        {
            title: 'Home',
            type: 'link',
            to: '/'
        },
        {
            title: 'Order List',
            type: 'link',
            to: '/order/list'
        },
        {
            title: 'Order Detail',
            type: 'text'
        }
    ];
    window.scrollTo(0, 0);
</script>
<style scoped>
    /*  */
</style>
