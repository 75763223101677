export const directOrderServices = {
    id: 1,
    type: 'chat',
    name: 'Chat Dokter',
    fee: 0,
    data: {
        serviceName: 'Layanan Chat 30 Menit',
        doctorSpecialist: '',
        doctorName: 'Dokter Annisa',
        doctorSlug: 'tuti-widiastuti',
        price: 45000,
        paymentMethod: null
    },
    payTotal: 45000
};
export const cart = {
    id: 1,
    seller: [
        {
            id: 1,
            name: 'Apotek Melinda 1',
            product: [
                {
                    id: 1,
                    name: 'Amoxicillin',
                    price: 45000,
                    quantity: 11,
                    checked: 0
                },
                {
                    id: 2,
                    name: 'Paracetamol',
                    price: 15000,
                    quantity: 1,
                    checked: 0
                }
            ]
        },
        {
            id: 2,
            name: 'Apotek Melinda 2',
            product: [
                {
                    id: 1,
                    name: 'Amoxicillin',
                    price: 45000,
                    quantity: 1,
                    checked: 0
                },
                {
                    id: 2,
                    name: 'Paracetamol',
                    price: 15000,
                    quantity: 1,
                    checked: 0
                }
            ]
        }
    ]
};
export const shipment = [
    {
        id: 1,
        name: 'Instan',
        service: [
            {
                id: 1,
                name: 'Go Ride',
                description: 'Go Ride adalah layanan dari Gojek, maksimal pengiriman pukul 16:00',
                fee: 19000
            },
            {
                id: 2,
                name: 'Grab Bike',
                description: 'Grab Bike adalah layanan dari Grab, maksimal pengiriman pukul 16:00',
                fee: 19000
            }
        ]
    },
    {
        id: 2,
        name: 'Same Day',
        service: [
            {
                id: 3,
                name: 'Go Ride Same Day',
                description: 'Go Ride Same Day memberikan harga yang lebih terjangkau',
                fee: 11000
            },
            {
                id: 4,
                name: 'Grab Bike Same Day',
                description: 'Grab Bike Same Day memberikan harga yang lebih terjangkau',
                fee: 12000
            }
        ]
    },
    {
        id: 3,
        name: 'Reguler',
        service: [
            {
                id: 5,
                name: 'JNE Express',
                description: 'JNE Express telah melayani Masyarakat Indonesia sejak 1990',
                fee: 9000
            },
            {
                id: 6,
                name: 'SiCepat Express',
                description: 'SiCepat Express mengantar paket anda dengan sangat cepat',
                fee: 9000
            },
            {
                id: 7,
                name: 'JNT Express',
                description: 'JNT Express menjamin semua paket diantar hingga ke tangan konsumen',
                fee: 9500
            },
            {
                id: 8,
                name: 'Anteraja',
                description: 'Anteraja, ke mana saja dan hingga kapan saja',
                fee: 10000
            },
            {
                id: 9,
                name: 'SAP Express',
                description: 'SAP Express mengutamakan kepuasan konsumen',
                fee: 10000
            }
        ]
    },
    {
        id: 4,
        name: 'Ekonomi',
        service: [
            {
                id: 10,
                name: 'JNE Ekonomi',
                description: 'JNE Ekonomi memberikan layanan dengan harga yang sangat terjangkau',
                fee: 7000
            },
            {
                id: 11,
                name: 'SiCepat Halu',
                description: 'SiCepat Halu memberikan harga yang sangat murah meriah',
                fee: 7500
            },
            {
                id: 12,
                name: 'JNT Ekonomi',
                description: 'JNT Ekonomi mengerti kebutuhan masyarakat',
                fee: 7500
            },
            {
                id: 13,
                name: 'Anteraja Ekonomi',
                description: 'Anteraja Ekonomi adalah paket hemat dari Anteraja',
                fee: 6500
            },
            {
                id: 14,
                name: 'SAP Ekonomi',
                description: 'SAP Ekonomi kini semakin terjangkau',
                fee: 7000
            }
        ]
    },
    {
        id: 5,
        name: 'Kargo',
        service: [
            {
                id: 15,
                name: 'JNE Kargo',
                description: 'Gunakan JNE Kargo untuk kenyamanan pengiriman anda',
                fee: 45000
            },
            {
                id: 16,
                name: 'SiCepat Kargo',
                description: 'SiCepat Kargo kini melayani pengiriman dengan berat hingga 50 kg',
                fee: 50000
            },
            {
                id: 17,
                name: 'JNT Kargo',
                description: 'JNT Kargo kini semakin hemat dan dapat diandalkan',
                fee: 41000
            },
            {
                id: 18,
                name: 'Anteraja Kargo',
                description: 'Anteraja Kargo saat ini sudah tersedia untuk pengiriman ke seluruh wilayah Indonesia',
                fee: 50000
            },
            {
                id: 19,
                name: 'SAP Kargo',
                description: 'SAP Kargo adalah layanan kargo dari Satria Antaran Prima',
                fee: 49000
            }
        ]
    }
];
export const payment = [
    {
        id: 1,
        name: 'Transfer Bank',
        service: [
            {
                id: 1,
                name: 'Bank BRI',
                checked: 0
            },
            {
                id: 2,
                name: 'Bank Mandiri',
                checked: 0
            }
        ]
    },
    {
        id: 2,
        name: 'E-Wallet',
        service: [
            {
                id: 1,
                name: 'GoPay',
                checked: 0
            },
            {
                id: 2,
                name: 'DANA',
                checked: 0
            },
            {
                id: 3,
                name: 'OVO',
                checked: 0
            },
            {
                id: 4,
                name: 'Shopee Pay',
                checked: 0
            },
            {
                id: 5,
                name: 'LinkAja',
                checked: 0
            }
        ]
    }
];
export const transactionList = [
    {
        id: 1,
        purchases: [
            {
                id: 1,
                facilityName: 'Apotek Melinda 1',
                products: [
                    {
                        id: 1,
                        name: 'Amoxicillin',
                        quantity: 1,
                        price: 45000
                    },
                    {
                        id: 2,
                        name: 'Paracetamol',
                        quantity: 1,
                        price: 15000
                    }
                ],
                shipment: 
                {
                    id: 5,
                    type: 'Reguler',
                    name: 'JNE Express',
                    fee: 11000
                },
                product_count: 2,
                product_quantity: 2,
                total_price: 60000,
                sub_total: 71000
            },
            {
                id: 2,
                facilityName: 'Apotek Melinda 2',
                products: [
                    {
                        id: 1,
                        name: 'Paracetamol',
                        quantity: 1,
                        price: 15000
                    }
                ],
                shipment: 
                {
                    id: 5,
                    type: 'Reguler',
                    name: 'JNE Express',
                    fee: 11000
                },
                product_count: 1,
                product_quantity: 1,
                total_price: 15000,
                sub_total: 26000
            }
        ],
        fee: 5000,
        payment: 
        {
            id: 1,
            name: 'DANA',
            amount: 102000
        }
    },
    {
        id: 2,
        purchases: [
            {
                id: 3,
                facilityName: 'Apotek Cicendo',
                products: [
                    {
                        id: 1,
                        name: 'Amoxicillin',
                        quantity: 1,
                        price: 45000
                    }
                ],
                shipment: 
                {
                    id: 5,
                    type: 'Reguler',
                    name: 'JNE Express',
                    fee: 11000
                },
                product_count: 1,
                product_quantity: 1,
                total_price: 45000,
                sub_total: 56000
            },
            {
                id: 4,
                facilityName: 'Apotek Kimia Farma',
                products: [
                    {
                        id: 1,
                        name: 'Paracetamol',
                        quantity: 1,
                        price: 15000
                    }
                ],
                shipment: 
                {
                    id: 5,
                    type: 'Reguler',
                    name: 'JNE Express',
                    fee: 11000
                },
                product_count: 1,
                product_quantity: 1,
                total_price: 15000,
                sub_total: 26000
            }
        ],
        fee: 5000,
        payment: 
        {
            id: 1,
            name: 'DANA',
            amount: 87000
        }
    },
    {
        id: 3,
        purchases: [
            {
                id: 1,
                facilityName: 'Apotek Melinda 1',
                products: [
                    {
                        id: 1,
                        name: 'Amoxicillin',
                        quantity: 1,
                        price: 45000
                    }
                ],
                shipment: 
                {
                    id: 5,
                    type: 'Reguler',
                    name: 'JNE Express',
                    fee: 11000
                },
                product_count: 1,
                product_quantity: 1,
                total_price: 45000,
                sub_total: 56000
            },
            {
                id: 2,
                facilityName: 'Apotek Melinda 2',
                products: [
                    {
                        id: 1,
                        name: 'Paracetamol',
                        quantity: 1,
                        price: 15000
                    }
                ],
                shipment: 
                {
                    id: 5,
                    type: 'Reguler',
                    name: 'JNE Express',
                    fee: 11000
                },
                product_count: 1,
                product_quantity: 1,
                total_price: 15000,
                sub_total: 26000
            }
        ],
        fee: 5000,
        payment: 
        {
            id: 1,
            name: 'DANA',
            amount: 87000
        }
    },
    {
        id: 4,
        purchases: [
            {
                id: 1,
                facilityName: 'Apotek Melinda 1',
                products: [
                    {
                        id: 1,
                        name: 'Amoxicillin',
                        quantity: 1,
                        price: 45000
                    }
                ],
                shipment: 
                {
                    id: 5,
                    type: 'Reguler',
                    name: 'JNE Express',
                    fee: 11000
                },
                product_count: 1,
                product_quantity: 1,
                total_price: 45000,
                sub_total: 56000
            },
            {
                id: 2,
                facilityName: 'Apotek Melinda 2',
                products: [
                    {
                        id: 1,
                        name: 'Paracetamol',
                        quantity: 1,
                        price: 15000
                    }
                ],
                shipment: 
                {
                    id: 5,
                    type: 'Reguler',
                    name: 'JNE Express',
                    fee: 11000
                },
                product_count: 1,
                product_quantity: 1,
                total_price: 15000,
                sub_total: 26000
            }
        ],
        fee: 5000,
        payment: 
        {
            id: 1,
            name: 'DANA',
            amount: 87000
        }
    },
    {
        id: 5,
        purchases: [
            {
                id: 1,
                facilityName: 'Apotek Melinda 1',
                products: [
                    {
                        id: 1,
                        name: 'Amoxicillin',
                        quantity: 1,
                        price: 45000
                    }
                ],
                shipment: 
                {
                    id: 5,
                    type: 'Reguler',
                    name: 'JNE Express',
                    fee: 11000
                },
                product_count: 1,
                product_quantity: 1,
                total_price: 45000,
                sub_total: 56000
            },
            {
                id: 2,
                facilityName: 'Apotek Melinda 2',
                products: [
                    {
                        id: 1,
                        name: 'Paracetamol',
                        quantity: 1,
                        price: 15000
                    }
                ],
                shipment: 
                {
                    id: 5,
                    type: 'Reguler',
                    name: 'JNE Express',
                    fee: 11000
                },
                product_count: 1,
                product_quantity: 1,
                total_price: 15000,
                sub_total: 26000
            }
        ],
        fee: 5000,
        payment: 
        {
            id: 1,
            name: 'DANA',
            amount: 87000
        }
    }
];
export const doctors = [
    {
        name: 'dr. Dede Budiman, Sp. PD',
        email: 'dede.budiman@melinda.com'
    },
    {
        name: 'dr. Dede Budiman, Sp. PD',
        email: 'dede.budiman@melinda.com'
    },
    {
        name: 'dr. Dede Budiman, Sp. PD',
        email: 'dede.budiman@melinda.com'
    },
    {
        name: 'dr. Dede Budiman, Sp. PD',
        email: 'dede.budiman@melinda.com'
    },
    {
        name: 'dr. Dede Budiman, Sp. PD',
        email: 'dede.budiman@melinda.com'
    }
];