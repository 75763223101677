import { useNotificationStore } from "@/stores/notification";
export function showNotification(title, message, milisecond, bg){
    const date = new Date();
    const time = date.getTime();
    const nt = document.getElementById('notification');
    const tt = document.getElementById('notification-title');
    const mg = document.getElementById('notification-message');
    var background;
    nt.style.display = 'none';
    if(bg){
        background = bg;
    }else{
        background = 'rgb(76, 155, 230)';
    }
    nt.style.background = background;
    nt.setAttribute('time', time);
    setTimeout(function(){
        tt.innerHTML = title;
        mg.innerHTML = message;
        nt.style.display = 'block';
        setTimeout(function(){
            if(nt.getAttribute('time') == time){
                nt.style.display = 'none';
            }
        }, milisecond);
    }, 0);
}
export function showNotificationConfirmation(){
    const date = new Date();
    const time = date.getTime();
    const nt = document.getElementById('notification-confirmation');
    // const tt = document.getElementById('notification-confirmation-title');
    // const mg = document.getElementById('notification-confirmation-message');
    // var background;
    nt.classList.remove('notification-active');
    // nt.style.display = 'none';
    // if(params.background){
    //     background = params.background;
    // }else{
    //     background = 'var(--white)';
    // }
    // nt.style.background = background;
    nt.setAttribute('time', time);
    setTimeout(function(){
        // tt.innerHTML = params.title;
        // mg.innerHTML = params.message;
        nt.classList.add('notification-active');
        // nt.style.display = 'block';
        // setTimeout(function(){
        //     if(nt.getAttribute('time') == time){
        //         nt.style.display = 'none';
        //     }
        // }, milisecond);
    }, 0);
}
export function hideNotificationConfirmation(){
    let el = document.getElementById('notification-confirmation');
    el.classList.remove('notification-active');
    useNotificationStore().notificationConfirmationData = null;
}