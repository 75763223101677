import { showNotification, getCookie } from "@/functions/general";
import { useTransactionStore } from "./transaction.js";
import router from "@/router/index.js";
// import { useAccountStore } from "./account.js";
// import { computed } from "@vue/runtime-core";
// import { sessionExpiration } from "@/equipments/settings.js";
// import { clearInput } from "@/functions/account.js";
import { defineStore } from "pinia";
import axiosClient from "../axios.js";

export const useChatStore = defineStore("chat", {
    state: () => ({
        // ==================== states used on chat system and its relation
        chatDoctor: [],
        thisDoctor: null,
        chatCs: null
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on chat system and its relation
        async setChatDoctor(slug, specialist, payment){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            useTransactionStore().setTransactionProcess(true);
            try{
                // await axiosClient.post(`chat/room/make`, {doctor: slug, specialist: specialist});
                const response = await axiosClient.post(`chat/room/make`, {doctor: slug, specialist: specialist, payment_method: payment});
                if(response.data.success){
                    this.syncChats();
                    showNotification('', 'Transaksi Berhasil, status : menunggu verifikasi sistem', 5000);
                    window.history.go(-1);
                    setTimeout(function(){
                        router.push('/order/list');
                    }, 100);
                }
                useTransactionStore().setTransactionProcess(false);
                console.log(response);
                // const chatid = response.data.data.room.name;
                // if(this.chatDoctor.length > 0){
                //     var so = false;
                //     for(var i = 0; i < this.chatDoctor.length; i++){
                //         if(this.chatDoctor[i].room.name == chatid){
                //             so = true;
                //         }
                //     }
                //     if(!so){
                //         this.chatDoctor.push(response.data.data);
                //         // console.log('1');
                //     }
                //     // console.log('So : ' + so);
                // }else{
                //     this.chatDoctor.push(response.data.data);
                //     // console.log('0');
                // }
                // console.log(this.chatDoctor);
            }catch(error){
                useTransactionStore().setTransactionProcess(false);
                console.log(error);
            }
        },
        setThisDoctor(slug){
            // console.log(this.chatDoctor);
            this.thisDoctor = null;
            for(var i = 0; i < this.chatDoctor.length; i++){
                if(this.chatDoctor[i].doctor.slug == slug && this.chatDoctor[i].active){
                    this.thisDoctor = this.chatDoctor[i].room.name;
                    console.log(this.thisDoctor);
                }
            }
        },
        async sendMessage(chat, message, sender){
            // const loggedIn = computed(() => useAccountStore().loggedIn);
            window.sessionStorage.setItem('authToken', getCookie('session'));
            for(var i = 0; i < this.chatDoctor.length; i++){
                if(this.chatDoctor[i].room.name == chat){
                    this.chatDoctor[i].room.messages.push({id: 0, message: message, sender: {username: sender}, sent: {time: 0}});
                    // const setchat = this.chatDoctor[i].room = {name: chat, messages: response.data.data.chats};
                    // if(setchat){
                        const chatscreen = document.getElementById('chat-screen-' + chat);
                        setTimeout(function(){
                            chatscreen.scrollTop = chatscreen.scrollHeight;
                        }, 0);
                    // }
                    // console.log(this.chatDoctor[i]);
                }
            }
            try{
                // const response = await axiosClient.post(`chat/message/post`, {room: chat, message: message});
                await axiosClient.post(`chat/message/post`, {room: chat, message: message});
                this.syncMessages(chat);
                // console.log(response);
                // console.log(this.chatDoctor);
            }catch(error){
                console.log(error);
            }
        },
        async syncChats(){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const response = await axiosClient.get(`chat/room/user`);
                this.chatDoctor = response.data.data;
                // console.log(response);
                // console.log(this.chatDoctor);
                for(var i = 0; i < this.chatDoctor.length; i++){
                    this.syncMessages(this.chatDoctor[i].room.name);
                }
            }catch(error){
                console.log(error);
            }
        },
        async syncMessages(chat){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const response = await axiosClient.get(`chat/room/user/` + chat);
                // console.log(response);
                for(var i = 0; i < this.chatDoctor.length; i++){
                    if(this.chatDoctor[i].room.name == chat){
                        this.chatDoctor[i].room = {name: chat, messages: response.data.data.chats}
                        const chatscreen = document.getElementById('chat-screen-' + chat);
                        setTimeout(function(){
                            if(chatscreen){
                                chatscreen.scrollTop = chatscreen.scrollHeight;
                            }
                        }, 0);
                    }
                }
            }catch(error){
                console.log(error);
            }
        },
        async synchronizing(){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                for(var i = 0; i < this.chatDoctor.length; i++){
                    // if(this.chatDoctor[i].room.name == chat){
                        // console.log(this.chatDoctor);
                        const response = await axiosClient.get(`chat/room/user/` + this.chatDoctor[i].room.name);
                        this.chatDoctor[i].room = {name: this.chatDoctor[i].room.name, messages: response.data.data.chats}
                        const chatscreen = document.getElementById('chat-screen-' + this.chatDoctor[i].room.name);
                        setTimeout(function(){
                            if(chatscreen){
                                chatscreen.scrollTop = chatscreen.scrollHeight;
                            }
                        }, 0);
                    // }
                }
            }catch(error){
                console.log(error);
            }
        }
    }
});