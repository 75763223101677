import { useDoctorStore } from "@/stores/doctor";
import { useTransactionStore } from "@/stores/transaction";
import router from "@/router";
import { showNotification, 
    // selectToggle
 } from "./general";
const date = new Date();
export function selectPracticeLocation(practice){
    document.getElementById('srv-input-location').value = practice.facility;
    useDoctorStore().setDoctorSchedule(date.getMonth(), date.getFullYear(), practice.schedules);
    useDoctorStore().selectPracticeLocation(practice);
    showSop('sop-calendar');
    document.getElementById('srv-button-schedule').children[0].innerHTML = '<div class="sbt-price trigger" style="width: 100%;"><span class="med-price trigger">Pilih Jadwal</span></div><div class="sbt-unit trigger" style="width: 0;"><span class="trigger"></span></div>';
}
export function setSchedules(day, date, title){
    useDoctorStore().setReservationSchedule(day, date, title);
}
export function scheduleToggle(bticon, option){
    const ic = document.getElementById(bticon);
    const op = document.getElementById(option);
    if(op.style.display == 'block'){
        ic.children[0].style.transform = 'rotate(0)';
        op.style.display = 'none';
    }else{
        ic.children[0].style.transform = 'rotate(180deg)';
        op.style.display = 'block';
    }
}
export function showSop(id){
    const sp = document.getElementsByClassName('srv-option-part');
    for(var i = 0; i < sp.length; i++){
        sp[i].classList.remove('sop-active');
    }
    document.getElementById(id).classList.add('sop-active');
}
export function selectSchedule(id, service, selected){
    const bt = document.getElementById(id);
    const ic = document.getElementById('srv-icon-schedule');
    useDoctorStore().selectSchedule({doctor_service_id: selected.doctor_service_id, doctor_schedule_id: selected.doctor_schedule_id, location: document.getElementById('srv-input-location').value, date: selected.date, time: selected.day + ', ' + selected.hour});
    useDoctorStore().selectPracticeTime(selected);
    bt.parentElement.parentElement.parentElement.parentElement.parentElement.style.display = 'none';
    ic.children[0].style.transform = 'rotate(0)';
    document.getElementById('srv-input-service').value = service;
    showSop('sop-calendar');
}
export function confirmOrder(detail, specialist, type, id){
    if(type == 'reservation'){
        if(document.getElementById('srv-input-schedule').value){
            detail.specialist = specialist;
            useTransactionStore().setDirectOrder('service', type, {detail: detail}, id);
            useTransactionStore().setDirectOrderSchedule({reservation: useDoctorStore().selectedSchedule, payment: null});
            router.push('/order/service/confirmation');
        }else{
            showNotification('', 'Pilih Jadwal dahulu', 2000, 'orange');
        }
    }else{
        detail.specialist = specialist;
        useTransactionStore().setDirectOrder('service', type, {detail: detail}, id);
        useTransactionStore().setDirectOrderSchedule({payment: null});
        router.push('/order/service/confirmation');
    }
}
export function validateSchedule(reservation){
    var date = new Date();
    const scdate = reservation.head.date_format;
    const today = date.toISOString().split('T')[0];
    const now = date.getTime();
    var vasche;
    if(today == reservation.head.date_format){
        vasche = [];
        for(var i = 0; i < reservation.body.schedules.length; i++){
            const tdate = new Date(scdate + 'T' + reservation.body.schedules[i].end_hour);
            if(tdate.getTime() > now){
                vasche.push({id: reservation.body.schedules[i].id, starts: reservation.body.schedules[i].start_hour, ends: reservation.body.schedules[i].end_hour});
            }
        }
        // vasche.reverse();
    }else{
        vasche = [];
        for(i = 0; i < reservation.body.schedules.length; i++){
            vasche.push({id: reservation.body.schedules[i].id, starts: reservation.body.schedules[i].start_hour, ends: reservation.body.schedules[i].end_hour});
            // const tdate = new Date(scdate + 'T' + reservation.body.schedules[i].end_hour);
            // if(tdate.getTime() > now){
            // }
        }
        // vasche = reservation.body.schedules;
        // vasche.reverse();
    }
    vasche.reverse();
    // console.log(vasche);
    return vasche;
}
export function organizeSchedule(schedule){
    // console.log(schedule);
    const dayids= [];
    const days = [];
    schedule.forEach((element) => {
        if(element.day_string.toLowerCase() == 'senin'){
            element.day_id = 1;
        }else if(element.day_string.toLowerCase() == 'selasa'){
            element.day_id = 2;
        }else if(element.day_string.toLowerCase() == 'rabu'){
            element.day_id = 3;
        }else if(element.day_string.toLowerCase() == 'kamis'){
            element.day_id = 4;
        }else if(element.day_string.toLowerCase() == 'jumat'){
            element.day_id = 5;
        }else if(element.day_string.toLowerCase() == 'sabtu'){
            element.day_id = 6;
        }else if(element.day_string.toLowerCase() == 'minggu'){
            element.day_id = 7;
        }
        if(!dayids.includes(element.day_id)){
            dayids.push(element.day_id);
        }
    });
    dayids.forEach((id) => {
        days.push({day_id: id, schedules: []});
    });
    schedule.forEach((element) => {
        // console.log(element);
        days.forEach((day) => {
            if(element.day_id == day.day_id){
                day.name = element.day_string;
                day.schedules.push({id: element.id, starts: element.start_hour, ends: element.end_hour});
            }
        });
    });
    days.forEach((day) => {
        day.schedules.reverse();
    });
    // console.log(days);
    return days;
}
let lastInputSelectSpecialistValue = '';

const debouncedInputSelectSpecialist = debounce((e) => {
    lastInputSelectSpecialistValue = e.target.value;
    useDoctorStore().searchbarSelectSpecialist.id = null;
    useDoctorStore().inputSelectSpecialist(lastInputSelectSpecialistValue);
}, 1000);
export function inputSelectSpecialist(e){
    debouncedInputSelectSpecialist(e);
    if(e.srcElement.value){
        e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'flex';
    }else{
        useDoctorStore().searchbarSelectSpecialist.input.value = '';
        e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'none';
    }
}
let lastInputSpecialistValue = '';

const debouncedInputSpecialist = debounce((e) => {
    lastInputSpecialistValue = e.target.value;
    useDoctorStore().inputSpecialist(lastInputSpecialistValue);
}, 1000);
export function inputSpecialist(e){
    debouncedInputSpecialist(e);
    // if(e.srcElement.value){
    //     e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'flex';
    // }else{
    //     e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'none';
    // }
}
export function debounce(func, delay, immediate = false) {
    let timer;
    return function (...args) {
        const executeImmediately = immediate && !timer;
        clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            if (!immediate) {
                func(...args);
            }
        }, delay);
        if (executeImmediately) {
            func(...args);
        }
    };
}
export function inputDoctorList(e){
    useDoctorStore().inputDoctorList(e.srcElement.value);
}
export function selectSpecialist(specialist, inputid){
    // console.log(specialist);
    if(document.getElementById(inputid)){
        const input = document.getElementById(inputid);
        input.value = null;
        input.parentElement.nextElementSibling.children[0].style.display = 'none';
    }
    useDoctorStore().searchbarSelectSpecialist.input.value = '';
    useDoctorStore().searchbarSelectSpecialist.id = specialist.id;
    useDoctorStore().selectSpecialist(specialist);
}
export function clearInputSpecialist(){
    const input = document.getElementById('sii-input-specialist');
    input.value = null;
    useDoctorStore().searchbarSpecialist.input = {value: null};
    if(useDoctorStore().searchbarSelectSpecialist.id == null && useDoctorStore().searchbarSelectSpecialist.input.value == ''){
        useDoctorStore().inputSpecialist('');
    }else{
        useDoctorStore().searchDoctorAndSpecialistFromServer();
    }
    input.parentElement.nextElementSibling.children[0].style.display = 'none';
    // selectToggle('select-specialist');
}
export function clearInputSelectSpecialist(){
    const input = document.getElementById('input-select-specialist');
    input.value = '';
    useDoctorStore().searchbarSelectSpecialist.input.value = '';
    useDoctorStore().searchbarSelectSpecialist.id = null;
    useDoctorStore().inputSelectSpecialist('');
    useDoctorStore().searchDoctorAndSpecialistFromServer();
    input.parentElement.nextElementSibling.children[0].style.display = 'none';
}
export function setAutoSelectSpecialistData(data){
    useDoctorStore().autoSelectSpecialistData = data;
    // console.log(data);
}
export function autoSelectSpecialist(){
    const data = useDoctorStore().autoSelectSpecialistData;
    if(data != null){
        useDoctorStore().searchbarSpecialist.input.value = null;
        selectSpecialist(data);
        useDoctorStore().autoSelectSpecialistData = null;
    }
}
export function resetFilter(){
    useDoctorStore().resetFilter();
}
let lastInputSelectFacilityValue = '';

const debouncedInputSelectFacility = debounce((e) => {
    lastInputSelectFacilityValue = e.target.value;
    useDoctorStore().searchbarSelectFacility.id = null;
    useDoctorStore().inputSelectFacility(lastInputSelectFacilityValue);
}, 1000);
export function inputSelectFacility(e){
    debouncedInputSelectFacility(e);
    if(e.srcElement.value){
        e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'flex';
    }else{
        useDoctorStore().searchbarSelectFacility.input.value = '';
        e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'none';
    }
}
export function clearInputSelectFacility(){
    const input = document.getElementById('input-select-facility');
    input.value = '';
    useDoctorStore().searchbarSelectFacility.input.value = '';
    useDoctorStore().searchbarSelectFacility.id = null;
    useDoctorStore().inputSelectFacility('');
    useDoctorStore().searchDoctorAndSpecialistFromServer();
    input.parentElement.nextElementSibling.children[0].style.display = 'none';
}
export function selectFacility(facility, inputid){
    // console.log(facility);
    if(document.getElementById(inputid)){
        const input = document.getElementById(inputid);
        input.value = null;
        input.parentElement.nextElementSibling.children[0].style.display = 'none';
    }
    useDoctorStore().searchbarSelectFacility.input.value = '';
    useDoctorStore().searchbarSelectFacility.id = facility.id;
    useDoctorStore().selectFacility(facility);
}
export function nextPage(){
    useDoctorStore().nextPage();
    // console.log('next funtzz');
}
export function clearLine(id){
    // console.log('clear line');
    if(document.getElementById(id)){
        const el = document.getElementById(id);
        // console.log(el.children.length);
        el.children[el.children.length - 1].children[0].classList.add('accordion-li');
    }
}








