<template>
    <div class="content">
        <NotFoundComponent />
    </div>
</template>

<script setup>
    import NotFoundComponent from '@/components/NotFoundComponent.vue'
    document.title = 'Halaman Tidak Ditemukan - Melinda Care';
    window.scrollTo(0, 0);
</script>