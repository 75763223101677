<template>
    <section>
        <div class="section-inner">
            <br/>
            <br/>
            <div class="scanner-menu">
                <div class="scanner-menu-button" v-if="eventVerificationType == 'manual'">
                    <!-- <span>Scanner</span> -->
                    <img src="../../assets/images/icons/qrcode.png" alt="melinda hospital scanner" @click="setEventVerificationType('scan');"/>
                </div>
                <div class="scanner-menu-button" v-if="eventVerificationType == 'scan'">
                    <!-- <span>Verifikasi Manual</span> -->
                    <img src="../../assets/images/icons/form.png" alt="melinda hospital scanner" @click="setEventVerificationType('manual');"/>
                </div>
            </div>
            <br/>
            <br/>
            <br/>
            <div class="verification-tab">
                <div class="vt-scanner" v-if="eventVerificationType == 'scan'">
                    <div class="scanner-button">
                        <button id="start-camera" @click="scanQRCode">Scan QR Code</button>
                    </div>
                    <div class="scanner">
                        <div class="scanner-inner" id="scanner-video">
                            <!-- <div class="scanner-button">
                                <button @click="scanQRCode()" v-if="!scanner.status">Scan QR Code</button>
                            </div> -->
                            <!-- <div class="scanner-instruction">
                                <p>Scan QR Code!</p>
                            </div> -->
                            <div class="scanner-video">
                                <div class="scanner-video-line" id="svl-absen"></div>
                                <video class="video" id="video-element"></video>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="vt-manual" v-if="eventVerificationType == 'manual'">
                    <div class="vt-manual-input">
                        <input type="text" placeholder="masukkan teks di bawah QR Code"/>
                    </div>
                    <div class="vt-manual-button">
                        <button>Kirim</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    // import { scanner } from '@/data/absen';
    // import { scanQRCode } from '@/functions/realtimescanner';
    import { computed } from '@vue/reactivity';
    import { useEventScanner } from '@/stores/eventscanner';
    import { onMounted } from 'vue';
    // import { router } from '@/router';
    import QrScanner from 'qr-scanner';
    import { useRoute } from 'vue-router';
    import { setEventVerificationType } from '@/functions/eventscanner';
    const eventVerificationType = computed(() => useEventScanner().eventVerificationType);
    // const position = computed(() => useEventScanner().position);
    useEventScanner().setEventVerificationData(useRoute().params.slug);
    onMounted(() => {
        // scanQRCode(selfieResult.value);
        // if(selfieResult.value){
        //     console.log(selfieResult.value);
        // }else{
        //     router.push('/absen');
        // }
    });
    // window.onload = function(){
    //     scanFace();
    // }
    // function scanQr(){
    //     // const video = document.getElementById('video-element');
    //     // console.log(video);
    //     scanQRCode();
    // }
    async function scanQRCode(){
        let stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
        var qc = null;
        // Get the video element
        const videoElement = document.getElementById("video-element");
        // Set the video element's source to the camera stream
        videoElement.srcObject = stream;
        videoElement.load();
        const qrScanner = new QrScanner(
            videoElement,
            result => getQC(result.data),
            {
                preferredCamera: 'environment',
                highlightScanRegion: true,
                highlightCodeOutline: true
                /* your options or returnDetailedScanResult: true if you're not specifying any other options */ 
            },
        );
        qrScanner.start();
        QrScanner.listCameras(true).then(function(cameras){
            const camselected = (cameras.length - 1);
            qrScanner.setCamera(cameras[camselected].id);
            // console.log(cameras);
        });
        // console.log(qrScanner);
        async function getQC(qrcode){
            if(!qc){
                qc = await qrcode;
                sendQrcode(qc);
            }
        }
        async function sendQrcode(qrcode){
            stream.getTracks().forEach(function(track) {
                track.stop();
            });
            // scvideo.classList.add('scelm-hidden');
            // cmbutton.classList.remove('scelm-hidden');
            // console.log(JSON.parse(qrcode));
            qrScanner.stop();
            qrScanner.destroy();
            // const qc = qrcode;
            // console.log(selfie);
            useEventScanner().sendQRCode(qrcode);
            // const absenResult = await useEventScanner().sendQRCode(qrcode);
            // if(absenResult == 1){
            //     // const date = new Date();
            //     // useEventScanner().selfieResult = null;
            //     // usePresensiStore().justNow = date.toISOString().split('T')[0];
            //     // router.push('/presensi');
            // }
        }
        // Check if the browser supports WebRTC
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia){
            console.log("WebRTC is not supported");
            return;
        }
    }
</script>

<style scoped>
    section{
        padding: 2.5rem 10% 2.5rem 10%;
    }
    .scanner{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .scanner-inner{
        width: 100%;
        max-width: 370px;
    }
    .mirror-element{
        transform: rotateY(180deg);
        -webkit-transform:rotateY(180deg); /* Safari and Chrome */
        -moz-transform:rotateY(180deg); /* Firefox */
    }
    .scanner-inner p{
        margin: 0 0 0.5rem;
        text-align: center;
    }
    .scanner-video{
        position: relative;
        width: 100%;
        height: 370px;
        max-height: 370px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 1px solid green; */
    }
    .scanner-video-line{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: lime;
        box-shadow: 0 4px 8px 0 rgba(0, 255, 13, 0.733), 0 6px 20px 0 rgba(0, 255, 34, 0.692);
        display: none;
        /* transition: 0.2s; */
    }
    .svl-active{
        display: block;
    }
    .scanner-video video{
        width: 100%;
        /* max-height: 370px;
        overflow: hidden; */
    }
    .scanner-menu{
        /* border: 1px solid green; */
        display: flex;
        justify-content: left;
    }
    .scanner-menu img{
        width: 50px;
    }
    .scanner-button{
        display: flex;
        justify-content: center;
        padding: 1rem 0 1rem 0;
        user-select: none;
    }
    .scanner-button button{
        background: var(--primary);
        color: var(--white);
        border: none;
        padding: 0.5rem 1rem 0.5rem 1rem;
        transition: 0.2s;
    }
    .scanner-button button:hover{
        background: var(--primary-hover);
    }
    .selfie-result-buttons{
        display: flex;
        justify-content: center;
    }
    .srb-part{
        width: 50%;
    }
    .scanner-instruction{
        text-align: center;
    }
    /* .scanner-instruction p{
        margin: 0;
    } */
    .scelm-hidden{
        display: none;
    }
    .vt-manual{
        min-height: 500px;
    }
    .vt-manual-input{
        width: 100%;
        padding: 0 0 1rem 0;
    }
    .vt-manual-input input{
        width: 100%;
        max-width: 500px;
        height: 35px;
        padding: 1%;
        outline: none;
        border: 1px solid var(--softgrey);
    }
    .vt-manual-button button{
        width: 100px;
        background: var(--primary);
        color: var(--white);
        border: none;
        padding: 0.5rem 1rem 0.5rem 1rem;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 2.5rem 5% 2.5rem 5%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 2.5% 2.5rem 2.5%;
        }
    }
</style>
