import { defineStore } from "pinia";
import axiosClient from "../axios.js";
import { roughSizeOfObject } from "@/functions/general.js";

export const useMedicineStore = defineStore("medicine", {
    state: () => ({
        // ==================== states used on medicine page and its relation
        allMedicines: null,
        filteredMedicines: null,
        searchbarSelectCategory: {input: {value: ''}},
        searchbarCategories: {select: {title: 'Semua', value: 'all'}, input: {value: null}},
        categories: null,
        filteredCategories: null,
        medicineList: null,
        medicineDetail: null,
        // medicineDetailStock: 0,
        selectedStock: null
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on medicine page and its relation
        async getAllMedicines(){
            try{
                if(!this.allMedicines){
                    this.filteredMedicines = null;
                    const response = await axiosClient.get(`medicine/all`);
                    this.allMedicines = response.data.data;
                    console.log(this.allMedicines);
                    console.log('size : ' + roughSizeOfObject(this.allMedicines) + ' bytes');
                }
            }catch(error){
                console.log(error);
            }
        },
        async setCategories(){
            try{
                const response = await axiosClient.get(`medicine/category`);
                this.categories = response.data.data;
                this.filteredCategories = response.data.data;
                console.log(this.filteredCategories);
            }catch(error){
                console.log(error);
            }
        },
        async setMedicineList(slug){
            try{
                this.medicineList = null;
                const response = await axiosClient.get(`medicine/category/` + slug);
                this.medicineList = response.data.data;
                console.log(this.medicineList);
            }catch(error){
                console.log(error);
            }
        },
        async setDetail(slug){
            try{
                this.medicineDetail = null;
                // this.medicineDetailStock = 0;
                this.selectedStock = null;
                const response = await axiosClient.get(`medicine/detail/` + slug);
                this.medicineDetail = response.data.data;
                // this.medicineDetailStock = response.data.data.stock[0].total;
                this.selectedStock = response.data.data.stock[0];
                console.log(response.data.data);
            }catch(error){
                console.log(error);
            }
        },
        inputSelectCategory(value){
            if(this.categories && this.allMedicines){
                this.searchbarSelectCategory.input.value = value;
                if(value.length){
                    var newsd = [];
                    newsd = this.categories;
                    var result = [];
                    var inval = new RegExp(value, 'gi');
                    for(var i = 0; i < newsd.length; i++){
                        if(newsd[i].name.match(inval)){
                            result.push(newsd[i]);
                        }
                    }
                    this.filteredCategories = result;
                }else{
                    this.filteredCategories = this.categories;
                }
                if(this.allMedicines && value.length > 0){
                    var newsdx = [];
                    newsdx = this.allMedicines;
                    if(this.filteredCategories){
                        var resultx = [];
                        for(i = 0; i < this.filteredCategories.length; i++){
                            for(var j = 0; j < newsdx.length; j++){
                                if(newsdx[j].category.slug == this.filteredCategories[i].slug){
                                    resultx.push(newsdx[j]);
                                }
                                // for(var k = 0; k < newsdx[j].practice.length; k++){
                                //     if(newsdx[j].practice[k].specialization.slug == this.filteredCategories[i].slug){
                                //         resultx.push(newsdx[j]);
                                //     }
                                // }
                            }
                        }
                        let uniqueresult = [...new Set(resultx.map(item => item))];
                        // console.log(uniqueresult);
                        // =============================
                        var resulty = [];
                        if(this.searchbarCategories.input.value){
                            var invalspec = new RegExp(this.searchbarCategories.input.value, 'gi');
                            for(i = 0; i < uniqueresult.length; i++){
                                if(uniqueresult[i].name.match(invalspec)){
                                    resulty.push(uniqueresult[i]);
                                }
                            }
                        }
                        // =============================
                        if(uniqueresult.length){
                            if(this.searchbarCategories.input.value){
                                this.filteredMedicines = resulty;
                            }else{
                                this.filteredMedicines = uniqueresult;
                                // console.log('result x');
                            }
                        }else{
                            this.filteredMedicines = [];
                        }
                    }else{
                        this.filteredMedicines = [];
                    }
                }else{
                    if(this.searchbarCategories.select.value != 'all'){
                        this.searchMedicineAndCategory();
                    }else{
                        this.filteredMedicines = null;
                    }
                }
            }
        },
        inputCategories(value){
            this.searchbarCategories.input.value = value;
            if(this.searchbarSelectCategory.input.value == ''){
                this.searchMedicineAndCategory();
            }else{
                this.beforeSelectedCategory();
            }
        },
        selectCategory(category){
            this.searchbarCategories.select = {title: category.name, value: category.slug};
            this.searchMedicineAndCategory();
            this.filteredCategories = this.categories;
        },
        beforeSelectedCategory(){
            // console.log('here......');
            var newsdx = [];
            newsdx = this.allMedicines;
            if(this.filteredCategories){
                var resultx = [];
                for(var i = 0; i < this.filteredCategories.length; i++){
                    for(var j = 0; j < newsdx.length; j++){
                        if(newsdx[j].category.slug == this.filteredCategories[i].slug){
                            resultx.push(newsdx[j]);
                        }
                        // for(var k = 0; k < newsdx[j].practice.length; k++){
                        //     if(newsdx[j].practice[k].specialization.slug == this.filteredCategories[i].slug){
                        //         resultx.push(newsdx[j]);
                        //     }
                        // }
                    }
                }
                if(resultx.length){
                    var resulty = [];
                    var inval = new RegExp(this.searchbarCategories.input.value, 'gi');
                    for(i = 0; i < resultx.length; i++){
                        if(resultx[i].name.match(inval)){
                            resulty.push(resultx[i]);
                        }
                    }
                    this.filteredMedicines = resulty;
                }else{
                    this.filteredMedicines = [];
                }
            }else{
                this.filteredMedicines = null;
            }
        },
        searchMedicineAndCategory(){
            if(this.allMedicines){
                var newsd = this.allMedicines;
                var result = [];
                if(this.searchbarCategories.select.value != 'all'){ // if selected specialist
                    for(var j = 0; j < newsd.length; j++){
                        if(newsd[j].category.slug == this.searchbarCategories.select.value){
                            result.push(newsd[j]);
                        }
                        // for(var k = 0; k < newsd[j].practice.length; k++){
                        //     if(newsd[j].practice[k].specialization.slug == this.searchbarCategories.select.value){
                        //         result.push(newsd[j]);
                        //     }
                        // }
                    }
                }else{
                    if(this.searchbarCategories.input.value){
                        result = this.allMedicines;
                    }
                }
                var resultx = [];
                if(this.searchbarCategories.input.value){
                    var inval = new RegExp(this.searchbarCategories.input.value, 'gi');
                    for(var i = 0; i < result.length; i++){
                        if(result[i].name.match(inval)){
                            resultx.push(result[i]);
                        }
                    }
                }else{
                    resultx = result;
                }
                if(resultx.length){
                    this.filteredMedicines = resultx;
                }else{
                    this.filteredMedicines = null;
                }
                // console.log(this.filteredMedicines);
            }
        }
    }
});