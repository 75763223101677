import { useCoeStore } from "@/stores/coe";
import { useTransactionStore } from "@/stores/transaction";
import { showNotification } from "./general";
// import { useDoctorStore } from "@/stores/doctor";
import router from "@/router";
export function scheduleToggle(bticon, option){
    const ic = document.getElementById(bticon);
    const op = document.getElementById(option);
    if(op.style.display == 'block'){
        ic.children[0].style.transform = 'rotate(0)';
        op.style.display = 'none';
    }else{
        ic.children[0].style.transform = 'rotate(180deg)';
        op.style.display = 'block';
    }
}
export function showSop(id){
    const sp = document.getElementsByClassName('srv-option-part');
    for(var i = 0; i < sp.length; i++){
        sp[i].classList.remove('sop-active');
    }
    document.getElementById(id).classList.add('sop-active');
}
export function setSchedules(day, date, title){
    useCoeStore().setServiceSchedules(day, date, title);
}
export function selectSchedule(id, service, schedule){
    const bt = document.getElementById(id);
    const ic = document.getElementById('srv-icon-service');
    useCoeStore().selectSchedule(schedule);
    bt.parentElement.parentElement.parentElement.parentElement.style.display = 'none';
    bt.parentElement.parentElement.parentElement.parentElement.previousElementSibling.children[0].innerHTML = bt.innerHTML;
    ic.children[0].style.transform = 'rotate(0)';
    document.getElementById('srv-input-service').value = service;
}
export function orderService(detail){
    const scinput = document.getElementById('srv-input-schedule');
    if(scinput.value){
        const serviceSchedules = useCoeStore().serviceSchedules;
        const schedule = {id: scinput.value, date: serviceSchedules.head.date_format, date_text: serviceSchedules.head.date_text, payment: null};
        useTransactionStore().setDirectOrder('service', 'health_facility', {id: document.getElementById('srv-input-service').value, schedule: schedule, detail: detail});
        useTransactionStore().setDirectOrderSchedule(schedule);
        // router.push('/direct-order/service/confirmation');
        router.push('/order/service/confirmation');
    }else{
        showNotification('', 'Pilih Jadwal dahulu', 2000, 'orange');
    }
}
export function paymentCheck(method, service){ // service = service.id
    const payment = useTransactionStore().payment;
    for(var i = 0; i < payment.length; i++){
        for(var j = 0; j < payment[i].service.length; j++){
            payment[i].service[j].checked = 0;
        }
    }
    for(i = 0; i < payment.length; i++){
        if(payment[i].id == method){
            for(j = 0; j < payment[i].service.length; j++){
                if(payment[i].service[j].id == service){
                    payment[i].service[j].checked = 1;
                }
            }
        }
    }
    useTransactionStore().directOrderSchedule.payment = service;
}
export function paymentUncheck(){
    const payment = useTransactionStore().payment;
    for(var i = 0; i < payment.length; i++){
        for(var j = 0; j < payment[i].service.length; j++){
            payment[i].service[j].checked = 0;
        }
    }
    useTransactionStore().directOrderSchedule.payment = null;
}
export function checkOut(order){
    // if(order.name == 'reservation'){
    //     useTransactionStore().directOrderService = useDoctorStore().selectSchedule;
    // }
    useTransactionStore().directCheckout(order);
}
let lastInputSelectCategoryValue = '';
const debouncedInputSelectCategory = debounce((e) => {
    lastInputSelectCategoryValue = e.target.value;
    useCoeStore().inputSelectCategory(lastInputSelectCategoryValue);
}, 1000);
export function inputSelectCategory(e){
    debouncedInputSelectCategory(e);
    // useCoeStore().inputSelectCategory(e.srcElement.value);
    if(e.srcElement.value){
        e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'flex';
    }else{
        useCoeStore().searchbarSelectCategory.input.value = '';
        e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'none';
    }
}
let lastInputSpecialistValue = '';
const debouncedInputSpecialist = debounce((e) => {
    lastInputSpecialistValue = e.target.value;
    useCoeStore().inputSpecialist(lastInputSpecialistValue);
}, 1000);
export function inputSpecialist(e){
    debouncedInputSpecialist(e);
    // if(e.srcElement.value){
    //     e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'flex';
    // }else{
    //     e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'none';
    // }
}
export function debounce(func, delay, immediate = false) {
    let timer;
    return function (...args) {
        const executeImmediately = immediate && !timer;
        clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            if (!immediate) {
                func(...args);
            }
        }, delay);
        if (executeImmediately) {
            func(...args);
        }
    };
}
export function selectCategory(specialist, inputid){
    // console.log(specialist);
    // const input = document.getElementById(inputid);
    // input.value = null;
    // useDoctorStore().searchbarSelectSpecialist.input.value = '';
    // useDoctorStore().searchbarSelectSpecialist.id = specialist.id;
    // input.parentElement.nextElementSibling.children[0].style.display = 'none';
    // useDoctorStore().selectSpecialist(specialist);
    // =====
    console.log(specialist);
    if(document.getElementById(inputid)){
        const input = document.getElementById(inputid);
        input.value = null;
        input.parentElement.nextElementSibling.children[0].style.display = 'none';
    }
    // useCoeStore().selectCategory(specialist);
    useCoeStore().searchbarSelectCategory.input.value = '';
    useCoeStore().searchbarSelectCategory.id = specialist.id;
    useCoeStore().selectCategory(specialist);
}
export function clearInputCategory(){
    // const input = document.getElementById('sii-input-specialist');
    // input.value = null;
    // useDoctorStore().searchbarSpecialist.input = {value: null};
    // if(useDoctorStore().searchbarSelectSpecialist.id == null && useDoctorStore().searchbarSelectSpecialist.input.value == ''){
    //     useDoctorStore().inputSpecialist('');
    // }else{
    //     useDoctorStore().searchDoctorAndSpecialistFromServer();
    // }
    // input.parentElement.nextElementSibling.children[0].style.display = 'none';
    // =====
    const input = document.getElementById('sii-input-specialist');
    input.value = null;
    useCoeStore().searchbarCategory.input = {value: null};
    if(useCoeStore().searchbarSelectCategory.id == null && useCoeStore().searchbarSelectCategory.input.value == ''){
        useCoeStore().inputSpecialist('');
    }else{
        useCoeStore().searchServiceAndCategoryFromServer();
    }
    input.parentElement.nextElementSibling.children[0].style.display = 'none';
}
export function clearInputSelectCategory(){
    // const input = document.getElementById('input-select-specialist');
    // input.value = '';
    // useDoctorStore().searchbarSelectSpecialist.input.value = '';
    // useDoctorStore().searchbarSelectSpecialist.id = null;
    // useDoctorStore().inputSelectSpecialist('');
    // useDoctorStore().searchDoctorAndSpecialistFromServer();
    // input.parentElement.nextElementSibling.children[0].style.display = 'none';
    // =====
    const input = document.getElementById('input-select-svcategory');
    input.value = '';
    useCoeStore().searchbarSelectCategory.input.value = '';
    useCoeStore().searchbarSelectCategory.id = null;
    useCoeStore().inputSelectCategory('');
    useCoeStore().searchServiceAndCategoryFromServer();
    input.parentElement.nextElementSibling.children[0].style.display = 'none';
}
export function setAutoSelectCategoryData(data){
    useCoeStore().autoSelectSpecialistData = data;
    console.log(data);
}
export function autoSelectCategory(){
    const data = useCoeStore().autoSelectSpecialistData;
    if(data != null){
        selectCategory(data);
        useCoeStore().autoSelectSpecialistData = null;
    }
}
export function resetFilter(){
    useCoeStore().resetFilter();
}