import { defineStore } from "pinia";
import { shipment, transactionList } from "@/equipments/dummies";
import { cartCookieVersion } from "@/equipments/settings";
import { showNotification, getCookie, setCookie } from "@/functions/general.js";
import { updateCart } from "@/functions/cart.js";
import { useChatStore } from "./chat.js";
import axiosClient from "../axios.js";
import router from "@/router/index.js";
import { useDoctorStore } from "./doctor.js";

export const useTransactionStore = defineStore("transaction", {
    state: () => ({
        // ==================== states used on transaction system and its relation
        cart: null,
        cartChecked: 0,
        confirmCart: null,
        cookieCart: null,
        cartProduct: 0,
        cartNum: 0,
        shipment: shipment,
        payment:null,
        paymentSelected: null,
        shipmentOption: null,
        shipmentValidation: null,
        transactionList: transactionList,
        directOrder: null,
        directOrderSchedule: null,
        orderList: null,
        orderDetail: null,
        directOrderService: null,
        transactionProcess: null
    }),
    getters: {
        // =====
    },
    actions: {
        // ==================== actions used on transaction system and its relation
        setCart(data){
            // ============================== ordering structure
            data.body.seller.reverse((a, b) => a.up - b.up);
            // data.body.seller.sort((a, b) => {
            //     const nameA = a.name.toUpperCase();
            //     const nameB = b.name.toUpperCase();
            //     if(nameA < nameB){
            //         return -1;
            //     }
            //     if(nameA > nameB){
            //         return 1;
            //     }
            //     return 0;
            // });
            const version = cartCookieVersion; // current cart cookie version
            if(data.header.version == version){
                this.cookieCart = data;
                var pl = 0;
                var qt = 0;
                for(var i = 0; i < data.body.seller.length; i++){
                    const pr = data.body.seller[i].product;
                    for(var j = 0; j < pr.length; j++){
                        qt = parseInt(qt) + parseInt(pr[j].qt);
                    }
                    pl = pl + data.body.seller[i].product.length;
                }
                this.cartProduct = pl;
                this.cartNum = qt;
                this.cart = data.body;
            }
            // calculating checked item
            var checked = 0;
            var total_price = 0;
            for(i = 0; i < this.cart.seller.length; i++){
                for(j = 0; j < this.cart.seller[i].product.length; j++){
                    if(this.cart.seller[i].product[j].checked == 1){
                        checked = checked + 1;
                        total_price = total_price + (this.cart.seller[i].product[j].price * this.cart.seller[i].product[j].qt);
                    }
                }
            }
            this.cartChecked = checked;
            this.cart.total_price = total_price;
            // console.log(this.cart);
            // console.log(data.body.seller.sort((a, b) => {
            //     const nameA = a.name.toUpperCase();
            //     const nameB = b.name.toUpperCase();
            //     if(nameA < nameB){
            //         return -1;
            //     }
            //     if(nameA > nameB){
            //         return 1;
            //     }
            //     return 0;
            // }));
        },
        createShipmentOption(count){
            const data = [];
            for(var i = 0; i < count; i++){
                data.push({index:i});
            }
            this.shipmentOption = data;
        },
        changeShipmentOption(index, id){
            for(var i = 0; i < this.confirmCart.seller.length; i++){
                if(i == index){
                    for(var j = 0; j < this.confirmCart.seller[i].shipment.length; j++){
                        if(this.confirmCart.seller[i].shipment[j].id == id){
                            this.shipmentOption[index] = this.confirmCart.seller[i].shipment[j].service;
                            // console.log(this.confirmCart.seller[i].shipment[j]);
                        }
                    }
                }
            }
            // console.log(this.confirmCart.seller);
            // console.log(this.shipmentOption);
            // for(var i = 0; i < shipment.length; i++){
            //     if(shipment[i].id == id){
            //         this.shipmentOption[index] = shipment[i];
            //     }
            // }
        },
        setDirectOrder(type, name, params, id){
            // console.log(params);
            if(type == 'service'){
                if(name == 'health_facility'){
                    for(var i = 0; i < params.detail.schedules.length; i++){
                        if(params.detail.schedules[i].id == params.schedule.id){
                            params.schedule_selected = params.detail.schedules[i];
                        }
                    }
                }
                this.directOrder = {service: params, name: name, id};
                console.log(this.directOrder);
            }else{
                this.getDirectOrder(params);
                // this.confirmCart = null;
                // var seller = [];
                // seller.push({
                //     id: params.detail.healthfacility.id, 
                //     name: params.detail.healthfacility.name, 
                //     product: [
                //         {
                //             id: params.id, 
                //             img: params.detail.images[0].image, 
                //             name: params.detail.name, 
                //             price: 0, 
                //             qt: params.qt, 
                //             unit: params.unit
                //         }
                //     ], 
                //     shipment: null, 
                //     shipment_selected: 0, 
                //     payment: 0
                // });
                // this.confirmCart = {seller: seller};
            }
            this.setPayment();
            // console.log(this.directOrder);
        },
        setDirectOrderSchedule(schedule){
            this.directOrderSchedule = schedule;
        },
        // setDirectOrderServices(id, specialist){ // -----x
        //     directOrderServices.data.doctorSlug = id;
        //     directOrderServices.data.doctorSpecialist = specialist;
        //     this.directOrderServices = directOrderServices;
        //     console.log(this.directOrderServices);
        // },
        clearCart(){
            this.cart = null;
            this.cookieCart = null;
            this.cartProduct = 0;
            this.cartNum = 0;
            setCookie('cart', {header:{version:0}}, 0);
            showNotification('', 'Cart dihapus', 2000, null);
            // this.deleteAllCart();
        },
        deleteAllCart(){
            const thiscart = this.cart;
            for(var i = 0; i < thiscart.seller.length; i++){
                this.deleteCartById(thiscart.seller[i].id);
            }
            if(i == thiscart.seller.length){
                this.clearCart();
            }
        },
        deleteCartSeller(id){
            for(var i = 0; i < this.cart.seller.length; i++){
                if(this.cart.seller[i].id == id){
                    this.cart.seller.splice(i, 1);
                }
            }
            if(this.cart.seller.length == 0){
                this.clearCart();
            }else{
                updateCart(this.cart);
            }
            this.deleteCartById(id);
        },
        deleteCartItem(id){
            const thiscart = this.cart;
            for(var i = 0; i < thiscart.seller.length; i++){
                for(var j = 0; j < thiscart.seller[i].product.length; j++){
                    if(thiscart.seller[i].product[j].id == id){
                        this.cart.seller[i].product.splice(j, 1);
                    }
                }
                if(thiscart.seller[i].product.length == 0){
                    this.deleteCartSeller(thiscart.seller[i].id);
                }
            }
            if(this.cart){
                updateCart(this.cart);
            }
            this.deleteCartById(id);
        },
        checkPaymentSelected(){
            var ps = 0;
            for(var i = 0; i < this.payment.length; i++){
                for(var j = 0; j < this.payment[i].service.length; j++){
                    if(this.payment[i].service[j].checked == 1){
                        ps = ps + 1;
                    }
                }
            }
            if(ps > 0){
                this.paymentSelected = 1;
            }else{
                this.paymentSelected = null;
            }
            // console.log(this.payment);
        },
        setTransactionProcess(value){
            this.transactionProcess = value;
            // console.log(this.transactionProcess);
        },
        async getDirectOrder(params){
            this.confirmCart = null;
            this.shipmentValidation = null;
            // console.log(params);
            try{
                const shipment = await axiosClient.get(`shipment?health_facility=` + params.detail.healthfacility.id);
                this.confirmCart = null;
                var stprice;
                for(var i = 0; i < params.detail.stock.length; i++){
                    if(params.detail.stock[i].id == params.id){
                        stprice = params.detail.stock[i].selling_price;
                    }
                }
                var seller = [];
                seller.push({
                    id: params.detail.healthfacility.id, 
                    name: params.detail.healthfacility.name, 
                    product: [
                        {
                            id: params.id, 
                            img: params.detail.images[0].image, 
                            name: params.detail.name, 
                            price: stprice, 
                            qt: params.qt, 
                            unit: params.unit
                        }
                    ], 
                    shipment: shipment.data.data, 
                    shipment_selected: null, 
                    payment: 0
                });
                this.confirmCart = {type: 'direct', seller: seller, payment: {product_total: (stprice * params.qt), shipment_fee: 0, service_fee: 0, total: (stprice * params.qt), payment_selected: null}};
                console.log(this.confirmCart);
                // console.log(shipment);
            }catch(error){
                if(error.response?.data?.message){
                    showNotification('', error.response?.data?.message, 2000, 'var(--primary)');
                }
                console.log(error);
            }
        },
        async selectShipment(type, params){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                if(type == 'direct'){
                    for(var i = 0; i < this.confirmCart.seller.length; i++){
                        if(this.confirmCart.seller[i].id == params.seller){
                            this.confirmCart.seller[i].shipment_selected = params.shipment;
                        }
                    }
                    this.confirmCart.payment.shipment_fee = params.shipment.price;
                    this.confirmCart.payment.total = this.confirmCart.payment.product_total + params.shipment.price;
                    this.shipmentValidation = 1;
                    // console.log(this.confirmCart);
                }else{
                    await axiosClient.post(`shipment/select`, {health_facility_id: params.seller, shipment_id: params.shipment.id});
                    this.getConfirmCart();
                }
            }catch(error){
                if(error.response?.data?.message){
                    showNotification('', error.response?.data?.message, 2000, 'var(--primary)');
                }
                console.log(error);
            }
        },
        async setPayment(){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            this.transactionProcess = null;
            try{
                const response = await axiosClient.get(`payment/method`);
                var payments = [];
                for(var i = 0; i < response.data.length; i++){
                    var payment_services = [];
                    for(var j = 0; j < response.data[i].methods.length; j++){
                        payment_services[j] = response.data[i].methods[j];
                        payment_services[j].checked = 0;
                    }
                    payments[i] = {id: response.data[i].id, name: response.data[i].name, service: payment_services};
                    // payments.push({service: payment_services});
                }
                // console.log(response.data);
                this.payment = payments;
                this.paymentSelected = null;
                // console.log(this.payment);
            }catch(error){
                if(error.response?.data?.message){
                    showNotification('', error.response?.data?.message, 2000, 'var(--primary)');
                }
                console.log(error);
            }
        },
        async selectPayment(payment){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const response = await axiosClient.post(`payment/select`, {payment_id: payment});
                if(response.data.success){
                    // this.getConfirmCart();
                    this.checkPaymentSelected();
                }else{
                    showNotification('', 'Gagal memilih metode pembayaran', 2000, 'red');
                }
                // console.log(response);
            }catch(error){
                if(error.response?.data?.message){
                    showNotification('', error.response?.data?.message, 2000, 'var(--primary)');
                }
                console.log(error);
            }
        },
        async getCart(){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                // const response = await axiosClient.get(`cart`);
                // if(response.data.data.length > 0){
                //     var seller = [];
                //     var ss = 0;
                //     for(var i = 0; i < response.data.data.length; i++){
                //         var product = [];
                //         for(var j = 0; j < response.data.data[i].product.length; j++){
                //             const pr = response.data.data[i].product[j];
                //             product.push({id:pr.stock.id, img:pr.images[0].image, name:pr.medicine_name, price:pr.stock.selling_price, qt:pr.count, unit:pr.unit_name});
                //         }
                //         if(response.data.data[i].shipment_selected != null){
                //             ss = ss + 1;
                //         }
                //         seller.push({id:response.data.data[i].id, name:response.data.data[i].name, product:product, shipment:response.data.data[i].shipments, shipment_selected: response.data.data[i].shipment_selected, payment:0});
                //     }
                //     if(ss == seller.length){
                //         this.shipmentValidation = 1;
                //     }else{
                //         this.shipmentValidation = null;
                //     }
                //     this.cart = {seller:seller};
                //     updateCart({seller:seller});
                // }
                // =================================
                // await axiosClient.get(`cart`);
                const response = await axiosClient.get(`cart`);
                // console.log(response.data);
                // console.log(this.cart);
                var seller = [];
                for(var i = 0; i < response.data.data.length; i++){
                    var product = [];
                    for(var j = 0; j < response.data.data[i].product.length; j++){
                        const pr = response.data.data[i].product[j];
                        product.push({id:pr.stock.id, img:pr.images[0].image, name:pr.medicine_name, price:pr.stock.selling_price, qt:pr.count, unit:pr.unit_name});
                    }
                    seller.push({id:response.data.data[i].id, name:response.data.data[i].name, product:product});
                }
                // this.cart.seller = seller;
                // this.confirmCart = {seller:seller};
                // console.log(this.confirmCart);
            }catch(error){
                if(error.response?.data?.message){
                    showNotification('', error.response?.data?.message, 2000, 'var(--primary)');
                }
                console.log(error);
            }
        },
        async getConfirmCart(){
            this.confirmCart = null;
            this.shipmentValidation = null;
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const response = await axiosClient.get(`cart/selected`);
                if(response.data.data.length > 0){
                    var seller = [];
                    var ss = 0;
                    for(var i = 0; i < response.data.data.length; i++){
                        var product = [];
                        for(var j = 0; j < response.data.data[i].product.length; j++){
                            const pr = response.data.data[i].product[j];
                            product.push({id:pr.stock.id, img:pr.images[0].image, name:pr.medicine_name, price:pr.stock.selling_price, qt:pr.count, unit:pr.unit_name});
                        }
                        if(response.data.data[i].shipment_selected != null){
                            ss = ss + 1;
                        }
                        seller.push({id:response.data.data[i].id, name:response.data.data[i].name, product:product, shipment:response.data.data[i].shipments, shipment_selected: response.data.data[i].shipment_selected, payment:0});
                    }
                    if(ss == seller.length){
                        this.shipmentValidation = 1;
                    }else{
                        this.shipmentValidation = null;
                    }
                    this.confirmCart = {type: 'cart', seller:seller, payment: response.data.payment};
                }
                // this.setPayment();
                // var ss = 0;
                // for(i = 0; i < seller.length; i++){
                //     if(seller[i].shipment_selected != null){
                //         ss = ss + 1;
                //     }
                // }
                // if(ss == seller.length){
                //     this.shipmentValidation = 1;
                // }else{
                //     this.shipmentValidation = null;
                // }
                // console.log('ss : ' + ss + ', lg : ' + seller.length);
                // this.cart.seller = seller;
                // this.createShipmentOption(this.confirmCart.seller.length);
                // this.createShipmentOption(this.confirmCart.seller.length);
                // console.log(this.confirmCart);
                // console.log(response);
                // console.log(this.confirmCart.seller.length);
            }catch(error){
                if(error.response?.data?.message){
                    showNotification('', error.response?.data?.message, 2000, 'var(--primary)');
                }
                console.log(error);
            }
        },
        async syncCart(seller, product){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                for(var i = 0; i < this.cart.seller.length; i++){
                    if(this.cart.seller[i].id == seller){
                        for(var j = 0; j < this.cart.seller[i].product.length; j++){
                            if(this.cart.seller[i].product[j].id == product){
                                await axiosClient.post(`cart`, {medicine_stock_id:product,count:this.cart.seller[i].product[j].qt});
                                // const response = await axiosClient.post(`cart`, {medicine_stock_id:product,count:this.cart.seller[i].product[j].qt});
                                // console.log(response);
                            }
                        }
                    }
                }
                this.getCart();
            }catch(error){
                if(error.response?.data?.message){
                    showNotification('', error.response?.data?.message, 2000, 'var(--primary)');
                }
                console.log(error);
            }
        },
        async itemCheck(id, params){
            if(getCookie('session')){
                window.sessionStorage.setItem('authToken', getCookie('session'));
                try{
                    await axiosClient.put(`cart/selected/` + id, params);
                    // const response = await axiosClient.put(`cart/selected/` + id, params);
                    // console.log(response);
                    // return response.data.checked;
                    this.getCart();
                }catch(error){
                    if(error.response?.data?.message){
                        showNotification('', error.response?.data?.message, 2000, 'var(--primary)');
                    }
                    console.log(error);
                }
            }
        },
        async deleteCartById(id){
            try{
                await axiosClient.delete(`cart/` + id);
                // const response = await axiosClient.delete(`cart/` + id);
                // console.log(response);
            }catch(error){
                if(error.response?.data?.message){
                    showNotification('', error.response?.data?.message, 2000, 'var(--primary)');
                }
                console.log(error);
            }
        },
        async checkOut(type){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            this.transactionProcess = true;
            try{
                if(type == 'direct'){
                    // console.log(this.confirmCart);
                    const seller = this.confirmCart.seller[0];
                    var ps = null;
                    for(var i = 0; i < this.payment.length; i++){
                        for(var j = 0; j < this.payment[i].service.length; j++){
                            if(this.payment[i].service[j].checked){
                                ps = this.payment[i].service[j].id;
                            }
                        }
                    }
                    const params = 
                    {
                        medicine_stock_id: seller.product[0].id, 
                        count: seller.product[0].qt, 
                        shipment_id: seller.shipment_selected.id, 
                        payment_method: ps
                    }
                    // console.log(params);
                    const response = await axiosClient.post(`sale/medicine/checkout`, params);
                    if(response.data.success){
                        showNotification('', 'Order diproses', 2000, null);
                        window.history.go(-1);
                        setTimeout(function(){
                            router.push('/order/detail/' + response.data.transaction);
                        }, 100);
                        this.transactionProcess = false;
                        this.confirmCart = null;
                    }else{
                        this.transactionProcess = false;
                        showNotification('', response.data.message, 2000, 'red');
                    }
                    // console.log(response);
                }
                if(type == 'cart'){
                    const response = await axiosClient.post(`sale/medicine/checkout`);
                    if(response.data.success){
                        this.setRemainCart(response.data.transaction);
                        // this.transactionProcess = false;
                    }else{
                        this.transactionProcess = false;
                        showNotification('', response.data.message, 2000, 'red');
                    }
                    console.log(response);
                }
            }catch(error){
                if(error.response?.data?.message){
                    showNotification('', error.response?.data?.message, 2000, 'var(--primary)');
                }
                this.transactionProcess = false;
                console.log(error);
            }
        },
        async setRemainCart(tx){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const response = await axiosClient.get(`cart`);
                if(response.data.data.length > 0){
                    var seller = [];
                    var ss = 0;
                    for(var i = 0; i < response.data.data.length; i++){
                        var product = [];
                        for(var j = 0; j < response.data.data[i].product.length; j++){
                            const pr = response.data.data[i].product[j];
                            product.push({id:pr.stock.id, img:pr.images[0].image, name:pr.medicine_name, price:pr.stock.selling_price, qt:pr.count, unit:pr.unit_name});
                        }
                        if(response.data.data[i].shipment_selected != null){
                            ss = ss + 1;
                        }
                        seller.push({id:response.data.data[i].id, name:response.data.data[i].name, product:product, shipment:response.data.data[i].shipments, shipment_selected: response.data.data[i].shipment_selected, payment:0});
                    }
                    if(ss == seller.length){
                        this.shipmentValidation = 1;
                    }else{
                        this.shipmentValidation = null;
                    }
                    // this.cart = {seller:seller};
                    updateCart({seller:seller});
                }else{
                    this.cart = null;
                    this.cookieCart = null;
                    this.cartProduct = 0;
                    this.cartNum = 0;
                    setCookie('cart', {header:{version: 0}}, 0);
                }
                // console.log(response);
                showNotification('', 'Order diproses', 2000, null);
                window.history.go(-1);
                setTimeout(function(){
                    router.push('/order/detail/' + tx);
                }, 100);
                this.confirmCart = null;
                this.transactionProcess = false;
            }catch(error){
                if(error.response?.data?.message){
                    showNotification('', error.response?.data?.message, 2000, 'var(--primary)');
                }
                console.log(error);
            }
        },
        async directCheckout(order){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            this.transactionProcess = true;
            const params = this.directOrderSchedule;
            // console.log(params);
            try{
                if(order.name == 'health_facility'){
                    const response = await axiosClient.post(`product/service/checkout`, 
                    {
                        schedule_id: params.id,
                        reservation_date: params.date,
                        payment_method: params.payment
                    });
                    if(response.data.success){
                        showNotification('', 'Order diproses', 2000, null);
                        window.history.go(-1);
                        setTimeout(function(){
                            router.push('/order/detail/' + response.data.transaction);
                        }, 100);
                    }else{
                        showNotification('', response.data.message, 2000, 'red');
                    }
                    console.log(response);
                    this.transactionProcess = false;
                    console.log(this.directOrderSchedule);
                }
                if(order.name == 'chat_doctor'){
                    console.log(order.service.slug);
                    useChatStore().setChatDoctor(order.service.detail.slug, order.service.detail.specialist, params.payment);
                }
                if(order.name == 'reservation'){
                    const response = await axiosClient.post(`doctor/reservation/make`, 
                    {
                        doctor_service_id: params.reservation.doctor_service_id,
                        doctor_schedule_id: params.reservation.doctor_schedule_id,
                        reservation_date: params.reservation.date
                    });
                    if(response.data.success){
                        showNotification('', 'Order diproses', 2000, null);
                        window.history.go(-1);
                        setTimeout(function(){
                            router.push('/order/detail/' + response.data.transaction);
                        }, 100);
                    }else{
                        showNotification('', response.data.message, 2000, 'red');
                    }
                    console.log(useDoctorStore().selectedSchedule);
                    console.log(this.directOrderSchedule);
                    console.log(response);
                }
            }catch(error){
                if(error.response?.data?.message){
                    showNotification('', error.response?.data?.message, 2000, 'var(--primary)');
                }
                this.transactionProcess = false;
                console.log(error);
            }
        },
        async setOrderList(){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            this.orderList = null;
            try{
                const response = await axiosClient.get(`transaction/mine`);
                this.orderList = response.data.data;
                console.log(this.orderList);
                // console.log(navigator);
            }catch(error){
                if(error.response?.data?.message){
                    showNotification('', error.response?.data?.message, 2000, 'var(--primary)');
                }
                console.log(error);
            }
        },
        async setOrderDetail(slug){
            // console.log(slug);
            window.sessionStorage.setItem('authToken', getCookie('session'));
            this.orderDetail = null;
            try{
                const response = await axiosClient.get(`transaction/mine/` + slug);
                this.orderDetail = response.data;
                console.log(this.orderDetail);
            }catch(error){
                if(error.response?.data?.message){
                    showNotification('', error.response?.data?.message, 2000, 'var(--primary)');
                }
                console.log(error);
            }
        }
    }
});