import { defineStore } from "pinia";
// import axiosClient from "../axios.js";
import axiosOne from "../axios_one.js";
import { clearInput, clearCheckBox } from "@/functions/general.js";
import { showEventnotif } from "@/functions/event.js";

export const useEventStore = defineStore("event", {
    state: () => ({
        // ==================== states used on event page and its relation
        // eventFormStatus: true,
        events: null,
        eventDetail: null,
        eventForm: null,
        webinar: null,
        eventLoader: null,
        participants: null,
        displayedParticipants: null,
        searchbarAnnouncement: {input: {value: ''}},
        eventVerification: null,
        userAgent: navigator.userAgent
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on event page and its relation
        async setEvents(){
            try{
                const response = await axiosOne.get(`event/list`);
                this.events = response.data.data;
                // console.log(response);
            }catch(error){
                console.log(error.response);
            }
        },
        async setEventDetail(slug){
            try{
                const response = await axiosOne.get(`event/list/` + slug);
                this.eventDetail = response.data.data;
            }catch(error){
                console.log(error.response);
            }
        },
        async setEventForm(slug){
            try{
                const response = await axiosOne.get(`event/register/` + slug);
                this.eventForm = response.data;
                // console.log(this.eventForm);
                // const date = new Date();
                // const dateClose = new Date(response.data.register_close);
                // if(date.getTime() < dateClose.getTime()){
                //     this.eventFormStatus = true;
                // }else{
                //     this.eventFormStatus = false;
                // }
                // console.log(this.eventFormStatus);
                // console.log('Now : ' + date.getTime() + ' - Close : ' + dateClose.getTime());
                // console.log(this.eventForm);
            }catch(error){
                console.log(error.response);
            }
        },
        async setWebinar(slug, params){
            this.setEventLoader(1);
            try{
                const contacts = [
                    {
                        name: 'Erna',
                        phone: '+6281282013039',
                        text: 'Halo, saya sudah daftar Mom and Baby Class'
                    },
                    {
                        name: 'Indah',
                        phone: '+6285100591928',
                        text: 'Halo, saya sudah daftar Mom and Baby Class'
                    }
                ];
                const response = await axiosOne.post(`event/post/` + slug, params);
                this.webinar = response.data;
                this.webinar.contacts = contacts;
                showEventnotif('', response.data.message + '<br/>Silakan mengonfirmasi pendaftaran anda ke Contact Person kami di bawah ini<br/>', 60000);
                clearInput('input');
                clearCheckBox('checkbox');
                console.log(this.webinar);
                this.setEventLoader(null);
            }catch(error){
                this.setEventLoader(null);
                console.log(error.response);
                if(error.response.data?.errors?.phone){
                    error.response.data.errors.phone.forEach(element => {
                        showEventnotif('', 'Gagal<br/>'+ element +'<br/>', 5000);
                    });
                }
            }
        },
        setEventLoader(value){
            this.eventLoader = value;
        },
        async setParticipants(slug){
            var pcrows = [];
            const response = await axiosOne.get(`ev-attendance/participants/` + slug);
            pcrows = response.data.contents;
            pcrows.sort((a, b) => {
                if (a[0].value.toLowerCase() < b[0].value.toLowerCase()) return -1;
                if (a[0].value.toLowerCase() > b[0].value.toLowerCase()) return 1;
                return 0;
            });
            // pcrows.sort((a, b) => b[0].value - a[0].value);
            // for(var i = 0; i < pcrows.length; i++){
            //     pcrows[i].sort((a, b) => b[0].value - a[0].value);
            //     console.log(pcrows[i][0].value);
            // }
            this.participants = {
                detail: {
                    id: response.data.id,
                    title: response.data.name,
                    image_url: response.data.image
                }, 
                columns: response.data.fields, 
                rows: pcrows
            };
            this.displayedParticipants = {
                detail: {
                    id: response.data.id,
                    title: response.data.name,
                    image_url: response.data.image
                }, 
                columns: response.data.fields, 
                rows: pcrows
            };
            console.log(response);
            // try{
            // }catch(error){
            //     console.log(error.response);
            // }
        },
        inputSea(value){
            this.searchbarAnnouncement.input.value = value;
            this.seaParticipant();
        },
        seaParticipant(){
            if(this.searchbarAnnouncement.input.value != ''){
                var i;
                var result = [];
                var inval = new RegExp(this.searchbarAnnouncement.input.value, 'gi');
                for(i= 0; i < this.participants.rows.length; i++){
                    // var fp = [];
                    if(this.participants.rows[i][0].value.match(inval)){
                        result.push(this.participants.rows[i]);
                    }
                    // console.log(this.participants.rows[i]);
                    // i++;
                    // var thisdocs = [];
                    // for(var j = 0; j < newsd[i].doctors.length; j++){
                    //     if(newsd[i].doctors[j].name.match(inval)){
                    //         thisdocs.push(newsd[i].doctors[j]);
                    //     }
                    // }
                    // if(thisdocs.length > 0){
                    //     result.push({name: newsd[i].name, slug: newsd[i].slug, doctors: thisdocs});
                    // }
                }
                this.displayedParticipants.rows = result;
                // console.log(inval);
                // this.filteredSpecialist = this.allDoctors;
            }else{
                this.displayedParticipants.rows = this.participants.rows;
                // this.filteredSpecialist = this.allDoctors;
            }
            console.log(this.searchbarAnnouncement);
        },
        setEventVerification(slug){
            this.eventVerification = {
                qrCode: slug
            };
        }
    }
});