<template>
    <section>
        <div class="tools">Tools</div>
    </section>
</template>

<script setup>
    // import { computed } from "@vue/runtime-core";
    import { useToolStore } from '@/stores/tool';
    useToolStore().setToolData();
</script>

<style scoped>
    section{
        padding: 100px 10% 0 10%;
    }
</style>