import { getCookie, showNotification } from "@/functions/general";
import { useChatStore } from "./chat.js";
import { defineStore } from "pinia";
import axiosClient from "../axios.js";

export const useAccountDoctorStore = defineStore("accountDoctor", {
    state: () => ({
        // ==================== states used on doctor account and its relation
        activeChats: null,
        paidChats: null,
        doneChats: null,
        reservations: null
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on doctor account and its relation
        setupChat(){
            this.setChats('active');
            this.setChats('pending');
            this.setChats('done');
        },
        async setChats(status){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const response = await axiosClient.get(`chat/service/doctor?status=` + status);
                // const response = await axiosClient.get(`chat/service/doctor`);
                if(status == 'active'){
                    this.activeChats = response.data.data;
                }else if(status == 'pending'){
                    this.paidChats = response.data.data;
                }else if(status == 'done'){
                    this.doneChats = response.data.data;
                }
                // else if(status == ''){
                //     // 
                // }
                // console.log(response);
                // console.log(this.doneChats);
            }catch(error){
                console.log(error);
            }
        },
        async startChat(tx){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const response = await axiosClient.post(`chat/room/activate`, {transaction: tx});
                if(response.data.success){
                    showNotification('', 'Berhasil mengaktifkan chat', 2000, null);
                    this.setupChat();
                    useChatStore().syncChats();
                }
                console.log(response);
            }catch(error){
                console.log(error);
            }
        },
        async endChat(tx){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const response = await axiosClient.post(`chat/room/deactivate`, {transaction: tx});
                if(response.data.success){
                    showNotification('', 'Berhasil mengakhiri chat', 2000, null);
                    this.setupChat();
                    useChatStore().syncChats();
                }
                console.log(response);
            }catch(error){
                console.log(error);
            }
        },
        async setReservation(){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const response = await axiosClient.get(`doctor/reservation/list`);
                this.reservations = response.data.data;
                console.log(this.reservations);
            }catch(error){
                console.log(error);
            }
        }
    }
});