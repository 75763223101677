import { defineStore } from "pinia";
import axiosClient from "../axios.js";
import { createCalendar, availableCalendar, getDay, getMonthNum } from "@/functions/calendar.js";
// import { roughSizeOfObject } from "@/functions/general.js";

export const useServiceStore = defineStore("service", {
    state: () => ({
        // ==================== states used on layanan page and its relation
        allServices: null,
        filteredServices: null,
        searchbarSelectCategory: {input: {value: ''}},
        searchbarCategory: {select: {title: 'Semua', value: 'all'}, input: {value: null}},
        // =======================
        categories: null,
        filteredCategories: null,
        categoriesShow: null,
        serviceListByCategory: null,
        serviceListByCategoryAndHealthFacility: null,
        serviceDetail: null,
        rawSchedules: null,
        serviceCalendar: null,
        serviceSchedules: null,
        selectedSchedule: null
    }),
    getters: {
        // =====
    },
    actions: {
        // ==================== actions used on layanan page and its relation
        async getAllServices(){
            // this.resetFilter();
            try{
                if(!this.allServices){
                    this.filteredServices = null;
                    const response = await axiosClient.get(`product/service/all`);
                    this.allServices = response.data.data;
                    // console.log(this.allServices);
                    // console.log('size : ' + roughSizeOfObject(this.allServices) + ' bytes');
                }
            }catch(error){
                console.log(error);
            }
        },
        async setCategories(){
            const response = await axiosClient.get(`service/category`);
            this.categories = response.data.data;
            this.filteredCategories = response.data.data;
            this.categoriesShow = response.data.data;
            this.getAllServices();
            console.log(this.categories);
        },
        async setServiceListByCategory(slug){
            const response = await axiosClient.get(`product/service/` + slug);
            this.serviceListByCategory = response.data;
            // console.log(response);
        },
        async setServiceLbcahf(category, hf){
            const response = await axiosClient.get(`product/service/category/` + category + `/` + hf);
            this.serviceListByCategoryAndHealthFacility = response.data;
            // console.log(response);
        },
        async setServiceDetail(slug, month, year){
            const response = await axiosClient.get(`product/service/detail/` + slug);
            this.serviceDetail = response.data;
            this.rawSchedules = response.data.schedules;
            this.selectedSchedule = null;
            if(response.data.schedules){
                this.setServiceCalendar(month, year, response.data.schedules);
            }
            // this.serviceCalendar = availableCalendar(createCalendar(month, year), response.data.schedules);
            // console.log(this.serviceDetail);
        },
        setServiceCalendar(month, year){
            this.serviceCalendar = availableCalendar(createCalendar(month, year), this.rawSchedules);
            // console.log(this.rawSchedules);
            // console.log(this.serviceCalendar);
            // const calendar = createCalendar(month, year);
            // availableCalendar(calendar);
            // this.serviceCalendar = calendar;
        },
        setServiceSchedules(day, date, title){
            const dayname = getDay(day);
            if(day == 0){
                day = 7;
            }
            this.serviceSchedules = {head: {day: dayname, date: date, date_text: dayname + ', ' + date + ' ' + title, date_format: getMonthNum(date + ' ' + title), title: date + ' ' + title}, body: {schedules: []}};
            for(var i = 0; i < this.serviceDetail.schedules.length; i++){
                if(this.serviceDetail.schedules[i].day_id == day){
                    this.serviceSchedules.body.schedules.push(this.serviceDetail.schedules[i]);
                }
            }
        },
        selectSchedule(id){
            this.selectedSchedule = id;
        },
        inputSelectCategory(value){
            if(this.categories && this.allServices){
                this.searchbarSelectCategory.input.value = value;
                if(value.length){
                    var newsd = [];
                    newsd = this.categories;
                    var result = [];
                    var inval = new RegExp(value, 'gi');
                    for(var i = 0; i < newsd.length; i++){
                        if(newsd[i].name.match(inval)){
                            result.push(newsd[i]);
                        }
                    }
                    this.filteredCategories = result;
                }else{
                    this.filteredCategories = this.categories;
                }
                // console.log(this.filteredCategories);
                if(this.allServices && value.length > 0){
                    var newsdx = [];
                    newsdx = this.allServices;
                    console.log(newsdx);
                    if(this.filteredCategories){
                        var resultx = [];
                        for(i = 0; i < this.filteredCategories.length; i++){
                            for(var j = 0; j < newsdx.length; j++){
                                // if(newsdx[j].category.slug == this.filteredCategories[i].slug){
                                //     resultx.push(newsdx[j]);
                                // }
                                for(var k = 0; k < newsdx[j].categories.length; k++){
                                    if(newsdx[j].categories[k].slug == this.filteredCategories[i].slug){
                                        resultx.push(newsdx[j]);
                                    }
                                }
                            }
                        }
                        // console.log(resultx[0]);
                        let uniqueresult = [...new Set(resultx.map(item => item))];
                        // console.log(uniqueresult);
                        // =============================
                        var resulty = [];
                        if(this.searchbarCategory.input.value){
                            var invalspec = new RegExp(this.searchbarCategory.input.value, 'gi');
                            for(i = 0; i < uniqueresult.length; i++){
                                if(uniqueresult[i].name.match(invalspec)){
                                    resulty.push(uniqueresult[i]);
                                }
                            }
                        }
                        // =============================
                        if(uniqueresult.length){
                            if(this.searchbarCategory.input.value){
                                this.filteredServices = resulty;
                            }else{
                                this.filteredServices = uniqueresult;
                                // console.log('result x');
                            }
                        }else{
                            this.filteredServices = [];
                        }
                    }else{
                        this.filteredServices = [];
                    }
                }else{
                    if(this.searchbarCategory.select.value != 'all'){
                        this.searchServiceAndCategory();
                    }else{
                        this.filteredServices = null;
                    }
                }
                // console.log(this.searchbarSelectCategory);
            }
        },
        selectCategory(category){
            this.searchbarCategory.select = {title: category.name, value: category.slug};
            this.searchServiceAndCategory();
            this.filteredCategories = this.categories;
        },
        inputSpecialist(value){
            this.searchbarCategory.input.value = value;
            if(this.searchbarSelectCategory.input.value == ''){
                this.searchServiceAndCategory();
            }else{
                this.beforeSelectedSpecialist();
            }
        },
        beforeSelectedSpecialist(){
            var newsdx = [];
            newsdx = this.allServices;
            if(this.filteredCategories){
                var resultx = [];
                for(var i = 0; i < this.filteredCategories.length; i++){
                    for(var j = 0; j < newsdx.length; j++){
                        for(var k = 0; k < newsdx[j].practice.length; k++){
                            if(newsdx[j].practice[k].specialization.slug == this.filteredCategories[i].slug){
                                resultx.push(newsdx[j]);
                            }
                        }
                    }
                }
                if(resultx.length){
                    var resulty = [];
                    var inval = new RegExp(this.searchbarCategory.input.value, 'gi');
                    for(i = 0; i < resultx.length; i++){
                        if(resultx[i].name.match(inval)){
                            resulty.push(resultx[i]);
                        }
                    }
                    this.filteredServices = resulty;
                }else{
                    this.filteredServices = [];
                }
            }else{
                this.filteredServices = null;
            }
        },
        searchServiceAndCategory(){
            if(this.allServices){
                var newsd = this.allServices;
                var result = [];
                if(this.searchbarCategory.select.value != 'all'){ // if selected specialist
                    for(var j = 0; j < newsd.length; j++){
                        for(var k = 0; k < newsd[j].categories.length; k++){
                            if(newsd[j].categories[k].slug == this.searchbarCategory.select.value){
                                result.push(newsd[j]);
                            }
                        }
                        // for(var k = 0; k < newsd[j].practice.length; k++){
                        //     if(newsd[j].practice[k].specialization.slug == this.searchbarCategory.select.value){
                        //         result.push(newsd[j]);
                        //     }
                        // }
                    }
                }else{
                    if(this.searchbarCategory.input.value){
                        result = this.allServices;
                    }
                }
                // console.log(result);
                var resultx = [];
                if(this.searchbarCategory.input.value){
                    var inval = new RegExp(this.searchbarCategory.input.value, 'gi');
                    for(var i = 0; i < result.length; i++){
                        if(result[i].name.match(inval)){
                            resultx.push(result[i]);
                        }
                    }
                }else{
                    resultx = result;
                }
                if(resultx.length){
                    this.filteredServices = resultx;
                }else{
                    this.filteredServices = null;
                }
                // console.log(this.filteredServices);
            }
        },
        searchSpecialist(){
            var newsd = [];
            newsd = this.categories;
            // console.log(newsd);
            if(this.searchbarCategory.input.value){
                var result = [];
                var inval = new RegExp(this.searchbarCategory.input.value, 'gi');
                for(var i = 0; i < newsd.length; i++){
                    if(newsd[i].name.match(inval)){
                        result.push(newsd[i]);
                    }
                }
                this.categoriesShow = result;
            }else{
                this.categoriesShow = newsd;
            }
        }
    }
});