import { useAddressStore } from "@/stores/address";
import { showNotification } from "./general";
export function regionToggle(id){
    const op = document.getElementById(id).nextElementSibling;
    if(op.style.display == 'block'){
        op.style.display = 'none';
    }else{
        op.style.display = 'block';
    }
    // console.log(id);
}
export function aobSelect(id){
    const bt = document.getElementById(id);
    bt.parentElement.previousElementSibling.children[0].innerHTML = bt.innerHTML;
    // console.log(bt);
}
export function selectProvince(id){
    useAddressStore().clearCities();
    useAddressStore().clearDistricts();
    useAddressStore().clearSubDistricts();
    useAddressStore().setCities(id);
}
export function selectProvinceEdit(address, province){
    useAddressStore().setCitiesEdit(address, province);
}
export function selectCity(id){
    useAddressStore().clearDistricts();
    useAddressStore().clearSubDistricts();
    useAddressStore().setDistricts(id);
}
export function selectCityEdit(address, city){
    useAddressStore().setDistrictsEdit(address, city);
}
export function selectDistrict(id){
    useAddressStore().clearSubDistricts();
    useAddressStore().setSubDistricts(id);
}
export function selectDistrictEdit(address, district){
    useAddressStore().setSubDistrictsEdit(address, district);
}
export function selectSubDistrict(id, input){
    document.getElementById(input).value = id;
    // useAddressStore().setSubDistricts(id);
    // console.log(id);
}
export function selectSubDistrictEdit(subdist, input){
    document.getElementById(input).value = subdist;
}
export function submitNewAddress(label, name, postcode, description, phone, note, subdistrict){
    const ir = document.getElementsByClassName('nai-required');
    var ircount = 0;
    for(var i = 0; i < ir.length; i++){
        if(ir[i].value){
            if(ir[i].getAttribute('id') == 'ca-subdist'){
                const aw = document.getElementsByClassName('aii-select-warning');
                for(var j = 0; j < aw.length; j++){
                    aw[j].innerHTML = '';
                }
                ircount = ircount + 1;
            }else{
                ircount = ircount + 1;
                ir[i].parentElement.nextElementSibling.innerHTML = '';
            }
        }else{
            if(ir[i].getAttribute('id') == 'ca-subdist'){
                const aw = document.getElementsByClassName('aii-select-warning');
                for(j = 0; j < aw.length; j++){
                    aw[j].innerHTML = '<small style="color: red;">ini harus dipilih</small>';
                }
            }else{
                ir[i].parentElement.nextElementSibling.innerHTML = '<small style="color: red;">ini diperlukan</small>';
            }
        }
    }
    if(ircount == ir.length){
        const lb = document.getElementById(label).value;
        const nm = document.getElementById(name).value;
        const pc = document.getElementById(postcode).value;
        const dc = document.getElementById(description).value;
        const ph = document.getElementById(phone).value;
        const nt = document.getElementById(note).value;
        const sd = document.getElementById(subdistrict).value;
        useAddressStore().submitNew({label: lb, name: nm, post_code: pc, description: dc, phone: ph, note: nt, subdistrict_id: sd});
    }else{
        showNotification('', 'Mohon periksa kembali form tambah alamat', 2000, null);
    }
}
export function updateAddress(address, label, name, postcode, description, phone, note, subdistrict){
    const lb = document.getElementById(label).value;
    const nm = document.getElementById(name).value;
    const pc = document.getElementById(postcode).value;
    const dc = document.getElementById(description).value;
    const ph = document.getElementById(phone).value;
    const nt = document.getElementById(note).value;
    const sd = document.getElementById(subdistrict).value;
    useAddressStore().updateAddress({address: address, input: {label: lb, name: nm, post_code: pc, description: dc, phone: ph, note: nt, subdistrict_id: sd}});
}
export function setMainAddress(address){
    useAddressStore().setMainAddress(address);
}
export function selectAddress(address, type){
    useAddressStore().selectAddress(address, type);
}
export function deleteAddress(address){
    useAddressStore().deleteAddress(address);
}