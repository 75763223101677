import { defineStore } from "pinia";
import axiosClient from "../axios.js";
import { organizeSchedule } from "@/functions/doctor.js";
import { hideModal, showNotification } from "@/functions/general.js";
// import { useSpecialPageStore } from '@/stores/specialpage.js';

export const usePolyclinicStore = defineStore("polyclinic", {
    state: () => ({
        // ==================== states used on polyclinic and its relation
        polyRegistration: null,
        smLoading: false,
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on polyclinic and its relation
        async setPolyRegistration(){
            try{
                this.polyRegistration = {
                    facility: {
                        data: null,
                        selected: null
                    },
                    polyclinic: {
                        data: null,
                        selected: null
                    },
                    doctor: {
                        data: null,
                        selected: null
                    },
                    schedule: {
                        data: null,
                        selected: null
                    },
                    patient: {
                        name: null
                    },
                    phone: null
                };
                const response = await axiosClient.get(`facilities`);
                this.polyRegistration.facility.data = response.data.data;
                // console.log(this.polyRegistration);
            }catch(error){
                console.log(error.response);
            }
        },
        async selectFacility(data){
            try{
                this.polyRegistration.polyclinic.data = null;
                this.polyRegistration.polyclinic.selected = null;
                this.polyRegistration.doctor.data = null;
                this.polyRegistration.doctor.selected = null;
                this.polyRegistration.schedule.data = null;
                this.polyRegistration.schedule.selected = null;
                const response = await axiosClient.get(`specialization/content?facility_id=${data.id}`);
                this.polyRegistration.polyclinic.data = response.data.data;
                this.polyRegistration.facility.selected = data;
                // console.log(response);
                const rsdoc = await axiosClient.get(`doctor/options?facility_id=${data.id}`);
                this.polyRegistration.doctor.data = rsdoc.data.data;
                // console.log(rsdoc);
                // console.log(this.polyRegistration);
            }catch(error){
                console.log(error.response);
            }
        },
        selectPoly(poly){
            this.polyRegistration.polyclinic.selected = poly;
            this.polyRegistration.doctor.selected = null;
            this.polyRegistration.schedule.data = null;
            this.polyRegistration.schedule.selected = null;
            // console.log(this.polyRegistration);
        },
        selectDoctor(doctor){
            this.polyRegistration.schedule.data = null;
            this.polyRegistration.schedule.selected = null;
            var i;
            var j;
            this.polyRegistration.doctor.selected = doctor;
            if(!this.polyRegistration.polyclinic.selected){
                for(i = 0; i < this.polyRegistration.polyclinic.data.length; i++){
                    for(j = 0; j < this.polyRegistration.doctor.selected.specializations.length; j++){
                        if(this.polyRegistration.polyclinic.data[i].id == this.polyRegistration.doctor.selected.specializations[j].id){
                            this.polyRegistration.polyclinic.selected = this.polyRegistration.polyclinic.data[i];
                        }
                    }
                }
                // console.log('not selected');
                // console.log(this.polyRegistration);
            }else{
                // console.log('selected');
                // console.log(this.polyRegistration);
                var match = 0;
                for(i = 0; i < this.polyRegistration.doctor.selected.specializations.length; i++){
                    if(this.polyRegistration.doctor.selected.specializations[i].id == this.polyRegistration.polyclinic.selected.id){
                        match = match + 1;
                    }
                }
                // console.log(match);
                if(match == 0){
                    for(i = 0; i < this.polyRegistration.polyclinic.data.length; i++){
                        for(j = 0; j < this.polyRegistration.doctor.selected.specializations.length; j++){
                            // console.log(this.polyRegistration.polyclinic.data[i].id);
                            // console.log(this.polyRegistration.doctor.selected.specializations[j].id);
                            if(this.polyRegistration.polyclinic.data[i].id == this.polyRegistration.doctor.selected.specializations[j].id){
                                this.polyRegistration.polyclinic.selected = this.polyRegistration.polyclinic.data[i];
                            }
                        }
                    }
                }
            }
            this.getDoctorsSchedule();
            // console.log(this.polyRegistration);
        },
        async getDoctorsSchedule(){
            try{
                const response = await axiosClient.get(`doctor/${this.polyRegistration.doctor.selected.slug}`);
                // const response = await axiosClient.get(`doctor/dr-stephanie-supriadi-spagr50`);
                console.log(response);
                // console.log(this.polyRegistration);
                for(var i = 0; i < response.data.data.practices.length; i++){
                    if(response.data.data.practices[i].facility.id == this.polyRegistration.facility.selected.id && response.data.data.practices[i].specialization.id == this.polyRegistration.polyclinic.selected.id){
                        this.polyRegistration.schedule.data = organizeSchedule(response.data.data.practices[i].schedules);
                    }
                }
            }catch(error){
                console.log(error);
            }
        },
        selectSchedule(schedule){
            this.polyRegistration.schedule.selected = schedule;
            // console.log(this.polyRegistration);
        },
        patientsName(id){
            const name = document.getElementById(id);
            this.polyRegistration.patient.name = name.value;
        },
        phoneEntry(id){
            const phone = document.getElementById(id);
            this.polyRegistration.phone = phone.value;
        },
        async submitPolyRegistration(){
            if(!this.polyRegistration.facility.selected){
                showNotification('', 'Pilih Rumah Sakit dahulu', 2000, 'var(--red)');
                return;
            }
            if(!this.polyRegistration.polyclinic.selected){
                showNotification('', 'Pilih Spesialis dahulu', 2000, 'var(--red)');
                return;
            }
            if(!this.polyRegistration.doctor.selected){
                showNotification('', 'Pilih Dokter dahulu', 2000, 'var(--red)');
                return;
            }
            if(!this.polyRegistration.schedule.selected){
                showNotification('', 'Pilih Jadwal dahulu', 2000, 'var(--red)');
                return;
            }
            if(!this.polyRegistration.patient.name){
                showNotification('', 'Masukkan nama Pasien', 2000, 'var(--red)');
                return;
            }
            console.log(this.polyRegistration);
            if(!this.polyRegistration.phone){
                showNotification('', 'Masukkan nomor telepon', 2000, 'var(--red)');
                return;
            }
            if(this.polyRegistration.phone){
                if(this.polyRegistration.phone.length < 5 || this.polyRegistration.phone.length > 15){
                    showNotification('', 'Nomor telepon harus valid', 2000, 'var(--red)');
                    return;
                }
            }
            try{
                this.smLoading = true;
                console.log({
                    facility_id: this.polyRegistration.facility.selected.id,
                    specialization_id: this.polyRegistration.polyclinic.selected.id,
                    schedule_id: this.polyRegistration.schedule.selected.time.id,
                    name: this.polyRegistration.patient.name,
                    phone: this.polyRegistration.phone
                });
                const response = await axiosClient.post(`register/submit`, {
                    facility_id: this.polyRegistration.facility.selected.id,
                    specialization_id: this.polyRegistration.polyclinic.selected.id,
                    schedule_id: this.polyRegistration.schedule.selected.time.id,
                    name: this.polyRegistration.patient.name,
                    phone: this.polyRegistration.phone
                });
                if(response.data.success){
                    // useSpecialPageStore().reportConversion('whatsapp-click-' + this.polyRegistration.facility.selected.phone, '');
                    var text = 'Halo, saya ingin mendaftar atas nama ' + this.polyRegistration.patient.name + ' untuk berkonsultasi dengan ' + this.polyRegistration.doctor.selected.user.name + ' di poli ' + this.polyRegistration.polyclinic.selected.name + ' pada hari ' + this.polyRegistration.schedule.selected.day + ' pukul ' + this.polyRegistration.schedule.selected.time.starts + ' - ' + this.polyRegistration.schedule.selected.time.ends + ', jika tidak bisa pada waktu tersebut bisa diinfokan untuk waktu yang lain?';
                    window.open(`https://wa.me/${this.polyRegistration.facility.selected.phone}?text=` + text.replace('&', 'dan'), `blank`);
                    hideModal('signup-modal');
                    this.polyRegistration.facility.data = null;
                    this.polyRegistration.facility.selected = null;
                    this.setPolyRegistration();
                    this.polyRegistration.polyclinic.data = null;
                    this.polyRegistration.polyclinic.selected = null;
                    this.polyRegistration.doctor.data = null;
                    this.polyRegistration.doctor.selected = null;
                    this.polyRegistration.schedule.data = null;
                    this.polyRegistration.schedule.selected = null;
                    document.getElementById('poli-input-name').value = null;
                    document.getElementById('poli-input-phone').value = null;
                }else{
                    showNotification('', 'Ada yang salah', 2000, 'var(--red)');
                }
                this.smLoading = false;
                console.log(response);
            }catch(error){
                this.smLoading = false;
                console.log(error);
            }
        }
    }
});

