<template>
    <section>
        <div class="section-header">
            <!-- <h2>Kategori</h2> -->
        </div>
        <div class="section-body">
            <template v-if="categories">
                <template v-if="!fcLoading">
                    <FilteredCoeComponent v-if="filteredServices"/>
                    <template v-else>
                        <template v-if="!searchbarCategory.input.value">
                            <div class="src-inner" v-if="categories.length">
                                <div class="src-card" v-for="(category) in categories" :key="category.id">
                                    <!-- <router-link :to="'/layanan-unggulan/kategori/' + category.slug" > -->
                                        <div class="src-card-inner" @click="selectCategory(category); pageScrollTop();">
                                            <div class="sci-icon">
                                                <img :src="category.image" alt="melinda hospital" v-if="category.image" />
                                                <img src="../../assets/favicon.webp" alt="melinda hospital" v-else/>
                                            </div>
                                            <div class="sci-descript">
                                                <h3>{{ category.name }}</h3>
                                            </div>
                                        </div>
                                    <!-- </router-link> -->
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="search-not-found">
                                <p>Tidak ditemukan dokter dengan kata kunci :</p>
                                <div v-if="searchbarCategory.input.value">"{{ searchbarCategory.input.value }}"</div>
                                <div v-if="searchbarSelectCategory.input.value.length > 0">"{{ searchbarSelectCategory.input.value }}"</div>
                                <div v-if="searchbarCategory.select.value != 'all'">dalam {{ searchbarCategory.select.title }}</div>
                                <div v-else>dalam Semua Spesialis</div>
                            </div>
                        </template>
                    </template>
                </template>
                <MainCardSkeleton v-else />
            </template>
            <ServiceCategoryListSkeleton v-else />
        </div>
    </section>
    <!-- <section>
        <div class="section-header">
            <h2>Kategori</h2>
        </div>
        <div class="section-body">
            <div class="sb-inner" v-if="categories">
                <div class="sb-card" v-for="(category) in categories.data" :key="category.id">
                    <div class="sbc-label">
                    </div>
                    <router-link :to="'/layanan/kategori/' + category.slug">
                        <div class="sbc-content">
                            <div class="sbc-image">
                                <img :src="category.image" alt="melinda hospital" v-if="category.image">
                            </div>
                            <div class="sbc-detail">
                                <h3>{{ category.name }}</h3>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <ServiceCategoryListSkeleton v-else/>
        </div>
    </section> -->
</template>
<script setup>
    import { computed } from "@vue/runtime-core";
    import { useCoeStore } from "../../stores/coe";
    import ServiceCategoryListSkeleton from "../skeletons/ServiceCategoryListSkeleton.vue";
    import FilteredCoeComponent from "@/components/coe/FilteredCoeComponent.vue";
    import { selectCategory } from "@/functions/coe";
    import { pageScrollTop } from "@/functions/general";
    import MainCardSkeleton from "../skeletons/MainCardSkeleton.vue";
    const categories = computed(() => useCoeStore().categoriesShow);
    const filteredServices = computed(() => useCoeStore().filteredServices);
    const searchbarSelectCategory = computed(() => useCoeStore().searchbarSelectCategory);
    const searchbarCategory = computed(() => useCoeStore().searchbarCategory);
    const fcLoading = computed(() => useCoeStore().fcLoading);
    // const imgsrc = process.env.VUE_APP_ROOT_API;
    useCoeStore().setCategories();
</script>
<style scoped>
    /* section{
        padding-left: 9%;
        padding-right: 9%;
        padding-bottom: 2rem;
        width: 100%;
        transition: 0.2s;
    }
    .section-header{
        padding: 1%;
        padding-top: 0;
    }
    .section-header h2{
        font-family: poppins;
        color: var(--white);
        margin: 0;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    .section-body{
        width: 100%;
    }
    .src-inner{
        display: flex;
        flex-wrap: wrap;
    }
    .src-card{
        width: 25%;
        padding: 1%;
        transition: 0.2s;
        user-select: none;
    }
    .src-card a{
        text-decoration: none;
        color: var(--grey);
    }
    .src-card-inner{
        display: flex;
        align-items: center;
        min-height: 150px;
        height: 100%;
        padding: 1%;
        background: var(--white);
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        overflow: hidden;
        cursor: pointer;
    }
    .sci-icon{
        width: 30%;
        height: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sci-icon img{
        max-width: 100%;
        max-height: 100%;
    }
    .sci-descript{
        color: var(--grey);
        padding-left: 5%;
    }
    .sci-descript h3{
        font-size: 0.9rem;
    }
    .search-not-found{
        font-family: Arial, Helvetica, sans-serif;
        font-style: italic;
        text-align: center;
        color: var(--grey);
        word-break: break-all;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding-left: 4%;
            padding-right: 4%;
        }
        .src-card-inner{
            border-radius: 5px;
        }
    }
    @media only screen and (max-width: 950px){
        .src-card{
            width: 33.33%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding-left: 1%;
            padding-right: 1%;
        }
        .section-header h2{
            color: var(--grey);
        }
        .src-card{
            width: 50%;
            margin-bottom: 1rem;
        }
    }
    @media only screen and (max-width: 550px){
        .src-card{
            width: 100%;
        }
    } */

    /* ===== */

    section{
        padding: 0 9% 0 9%;
        width: 100%;
        transition: 0.2s;
        color: var(--grey);
    }
    .section-header{
        padding: 1%;
        padding-top: 0;
    }
    .section-header h2{
        font-family: poppins;
        color: var(--grey);
        margin: 0;
    }
    .section-body{
        width: 100%;
    }
    .src-inner{
        display: flex;
        flex-wrap: wrap;
        transition: 0.2s;
    }
    .src-card{
        width: 25%;
        padding: 1%;
        transition: 0.2s;
        user-select: none;
    }
    .src-card a{
        text-decoration: none;
        color: var(--grey);
    }
    .src-card-inner{
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 120px;
        height: 100%;
        padding: 1%;
        background: var(--white);
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        overflow: hidden;
        justify-content: center;
        cursor: pointer;
    }
    .sci-icon{
        width: 100%;
        overflow: hidden;
        align-items: center;
        text-align: center;
    }
    .sci-icon img{
        max-width: 100%;
        max-height: 100%;
    }
    .sci-descript{
        color: var(--grey);
        width: 100%;
        text-align: center;
    }
    .sci-descript h3{
        font-size: 0.9rem;
        margin: 0;
    }
    .sb-inner{
        display: flex;
        flex-wrap: wrap;
    }
    .sb-card{
        position: relative;
        min-width: 100px;
        width: 25%;
        padding: 1%;
        transition: 0.2s;
    }
    .sb-card a{
        text-decoration: none;
        color: #575757;
    }
    .sbc-content{
        display: flex;
        border: 1px solid rgb(230, 227, 227);
        border-radius: 10px;
        overflow: hidden;
        height: 100px;
        padding: 5%;
        background: whitesmoke;
    }
    .sbc-image{
        display: flex;
        width: 25%;
        height: 100%;
        overflow: hidden;
        align-items: center;
    }
    .sbc-image img{
        height: 100%;
    }
    .sbc-detail{
        width: 75%;
        padding: 5%;
        display: flex;
        align-items: center;
    }
    .sbc-detail h3{
        font-family: poppins;
        text-align: center;
        color: #575757;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin: 0;
    }
    .search-not-found{
        font-family: Arial, Helvetica, sans-serif;
        font-style: italic;
        text-align: center;
        color: var(--grey);
        word-break: break-all;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding-left: 4%;
            padding-right: 4%;
        }
        .src-card-inner{
            border-radius: 5px;
        }
    }
    @media only screen and (max-width: 1415px){
        .sb-card{
            width: 33.33%;
        }
    }
    @media only screen and (max-width: 1100px){
        .src-card{
            width: 33.33%;
        }
    }
    @media only screen and (max-width: 950px){
        .sb-card{
            width: 50%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding-left: 1%;
            padding-right: 1%;
        }
        .section-header h2{
            color: var(--grey);
        }
        .src-card{
            width: 50%;
            /* margin-bottom: 1rem; */
        }
        .src-card-inner{
            min-height: 200px;
            box-shadow: unset;
            border: 1px solid var(--softgrey);
        }
    }
    @media only screen and (max-width: 650px){
        .sb-card{
            min-width: 250px;
        }
    }
    @media only screen and (max-width: 550px){
        .sb-card{
            width: 100%;
        }
    }
    @media only screen and (max-width: 290px){
        .src-card{
            width: 100%;
        }
    }
</style>