<template>
    <section>
        <div class="section-header">
            <h1>Daftar Transaksi</h1>
        </div>
        <div class="section-body">
            <div class="sb-inner">
                <div class="sbi-product" v-if="orders">
                    <div class="sbi-product-part" v-for="(tx) in orders" :key="tx.name">
                        <router-link :to="('/order/detail/' + tx.name)">
                            <div class="sbi-card">
                                <div class="sbi-card-detail">
                                    <div class="sc-label">
                                        <span class="sc-label-product" v-if="tx.type_code == 'MS'">Produk</span>
                                        <span class="sc-label-service" v-if="tx.type_code == 'SPT'">Layanan</span>
                                        <span class="sc-label-chat" v-if="tx.type_code == 'SCD'">Chat Dokter</span>
                                        <span class="sc-label-chat" v-if="tx.type_code == 'SRD'">Reservasi</span>
                                    </div>
                                    <div class="sc-head-invoice">
                                        <span>{{ tx.name }}</span>
                                    </div>
                                    <div class="total-pay">
                                        <div class="sc-head-row">
                                            <div class="shr-param">
                                                <span>Tanggal</span>
                                            </div>
                                            <div class="shr-value">
                                                <span>{{ tx.date }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sbi-card-product" v-if="tx.products">
                                    <div class="scp-image">
                                        <img :src="tx.products.image" alt="melinda care" />
                                    </div>
                                    <div class="scp-detail">
                                        <h2>{{ tx.products.name }}</h2>
                                        <p v-if="(tx.products.total_product > 1)">dan {{ (tx.products.total_product - 1) }} produk lainnya</p>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
                <OrderListSkeleton v-else/>
            </div>
        </div>
    </section>
</template>

<script setup>
    import OrderListSkeleton from '@/components/skeletons/OrderListSkeleton.vue';
    import { computed } from '@vue/reactivity';
    import { useTransactionStore } from '../../stores/transaction';
    const orders = computed(() => useTransactionStore().orderList);
    useTransactionStore().setOrderList();
    // function expandElement(bt, id){
    //     const button = document.getElementById(bt);
    //     const el = document.getElementById(id);
    //     if(el.style.maxHeight == '0px'){
    //         button.innerHTML = '-';
    //         el.style.maxHeight = el.scrollHeight + 'px';
    //     }else{
    //         button.innerHTML = '+';
    //         el.style.maxHeight = '0';
    //     }
    // }
</script>

<style scoped>
    section{
        padding-top: 0;
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 2.5%;
        width: 100%;
        transition: 0.2s;
        color: var(--grey);
        transition: 0.2s;
    }
    section h1{
        color: var(--white);
        margin: 0;
        /* margin-top: 1rem; */
        margin-bottom: 1rem;
        font-size: 1.1rem;
    }
    .section-body{
        display: flex;
        transition: 0.2s;
    }
    .sb-inner{
        width: 70%;
        padding-right: 1rem;
        transition: 0.2s;
    }
    .sbi-product-part a{
        text-decoration: none;
        color: var(--grey);
    }
    .sbi-card{
        display: flex;
        flex-wrap: wrap;
        border-radius: 10px;
        padding: 5%;
        padding-top: 1rem;
        padding-bottom: 2rem;
        margin-bottom: 1rem;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
        transition: 0.2s;
        background: var(--white);
    }
    .sbi-card-detail{
        width: 66%;
        padding-bottom: 1rem;
        transition: 0.2s;
    }
    .sc-label{
        padding: 2rem 0 2rem 0;
    }
    .sc-label-product{
        background: rgb(84, 136, 84);
        color: var(--white);
        padding: 0.5rem 1rem 0.5rem 1rem;
        border-radius: 5px;
    }
    .sc-label-service{
        background: rgb(105, 105, 143);
        color: var(--white);
        padding: 0.5rem 1rem 0.5rem 1rem;
        border-radius: 5px;
    }
    .sc-label-chat{
        background: rgb(41, 126, 151);
        color: var(--white);
        padding: 0.5rem 1rem 0.5rem 1rem;
        border-radius: 5px;
    }
    .sc-head{
        /* border-bottom: 1px solid var(--grey); */
        padding-bottom: 1rem;
        font-size: 0.9rem;
    }
    .sc-head h2{
        margin: 0;
        margin-bottom: 1rem;
        font-size: 1rem;
    }
    .sc-head-detail{
        display: flex;
        flex-wrap: wrap;
    }
    .sc-head-description{
        width: 70%;
        transition: 0.2s;
    }
    .sc-head-invoice{
        margin-bottom: 0.5rem;
        color: var(--primary);
    }
    .sc-head-row{
        display: flex;
        width: 100%;
    }
    .shr-total{
        font-size: 1rem;
    }
    .shr-param{
        width: 50%;
    }
    .shr-value{
        width: 50%;
    }
    /* .sc-head-shipment{
        width: 30%;
        text-align: center;
        transition: 0.2s;
    } */
    /* .shb-total{
        width: 70%;
        transition: 0.2s;
    } */
    /* .shb-panel{
        position: absolute;
        top: 0;
        right: 0;
        width: auto;
    } */
    .shb-panel-button{
        /* padding: 1rem; */
        /* padding-left: 1rem; */
        /* padding-right: 1rem; */
        /* color: var(--primary); */
    }
    /* .shb-panel-button span{
        cursor: pointer;
        transition: 0.2s;
        color: var(--primary);
        padding-left: 1rem;
        padding-right: 1rem;
        user-select: none;
    }
    .shb-panel-button span:hover{
        color: var(--primary-hover);
    } */
    /* .sc-body{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding-left: 2%;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-in-out;
    } */
    /* .sc-body-product{
        width: 100%;
        font-size: 1rem;
    } */
    /* .sbp-head{
        padding: 2.5%;
        padding-bottom: 0;
    }
    .sbp-head h3{
        margin: 0;
    } */
    /* .product-list{
        width: 100%;
        padding: 2.5%;
    } */
    /* .pl-body{
        display: flex;
        flex-wrap: wrap;
    } */
    /* .sbc-icon{
        width: 20px;
        height: 20px;
        border: 2px solid var(--softgrey);
        border-radius: 2px;
    } */
    /* .sc-body-image{
        width: 30%;
        padding: 1%;
        padding-left: 0;
        transition: 0.2s;
    }
    .sc-body-image img{
        width: 100%;
        border-radius: 5px;
    }
    .sc-body-description{
        width: 70%;
        transition: 0.2s;
        padding: 0.5rem;
    }
    .sc-body-qty{
        margin-top: 1rem;
        color: blue;
    }
    .sbq-unit{
        padding-left: 0.5rem;
    }
    .sc-body-price{
        padding-top: 0.5rem;
        color: brown;
    }
    .pl-footer{
        display: flex;
        flex-wrap: wrap;
    }
    .pf-text{
        width: 60%;
        transition: 0.2s;
    }
    .sc-body-shipment{
        width: 34%;
        transition: 0.2s;
    } */
    .total-pay{
        width: 50%;
        padding-top: 1rem;
    }
    /* .sos-head{
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    .sos-head span{
        cursor: pointer;
        background: var(--white);
        color: var(--softgrey);
        padding: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        border: 1px solid var(--softgrey);
        border-radius: 5px;
        transition: 0.2s;
        height: 10%;
    }
    .sos-head span:hover{
        background: var(--smoke);
    } */
    /* .sos-body{
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        height: 75%;
        overflow: auto;
    }
    .sod-list{
        cursor: pointer;
        padding: 0.2rem;
    }
    .sod-list:hover{
        background: var(--smoke);
    }
    .sb-button{
        position: relative;
        width: 30%;
        transition: 0.2s;
    }
    .sbb-inner{
        border-radius: 10px;
        min-height: 100px;
        z-index: 10;
        background: var(--white);
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
    } */
    .sbi-card-product{
        width: 34%;
        transition: 0.2s;
    }
    .scp-image{
        width: 100%;
        height: 150px;
    }
    .scp-image img{
        /* width: 100%; */
        max-width: 100%;
        height: 100%;
    }
    .scp-detail h2{
        font-size: 1.1rem;
    }
    .scp-detail p{
        font-style: italic;
        color: var(--primary);
    }
    @media only screen and (max-width: 1500px){
        section{
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    @media only screen and (max-width: 1100px){
        /* .modal-content-body{
            border: 1px solid var(--softgrey);
        }
        .address-list{
            width: 100%;
            height: auto;
            border: none;
            padding-left: 1rem;
            padding-right: 1rem;
        }
        .address-panel{
            width: 100%;
            min-height: 100%;
            height: auto;
            padding-left: 1rem;
            padding-right: 1rem;
            margin-bottom: 1rem;
        }
        .payment-list{
            width: 100%;
            height: auto;
            border: none;
            padding-left: 1rem;
            padding-right: 1rem;
        }
        .payment-panel{
            width: 100%;
            min-height: 50%;
            height: auto;
            padding-left: 1rem;
            padding-right: 1rem;
        } */
        /* .sc-body-check{
            width: 10%;
        } */
        /* .sc-body-image{
            width: 27%;
        } */
        /* .sc-body-description{
            width: 73%;
        } */
    }
    @media only screen and (max-width: 1050px){
        .section-body{
            display: inline;
        }
        .sb-inner{
            width: 100%;
            padding-right: 0;
        }
        /* .sc-body-product{
            width: 100%;
        } */
        /* .sc-body-shipment{
            width: 50%;
        } */
        /* .sb-button{
            width: 100%;
            min-height: 310px;
        } */
        /* .sbb-inner{
            border: 1px solid var(--softgrey);
            border-radius: 0;
            box-shadow: unset;
        } */
    }
    @media only screen and (max-width: 750px){
        section{
            padding-left: 2.5%;
            padding-right: 2.5%;
            background: var(--white);
        }
        section h1{
            color: var(--grey);
        }
        /* .modal-content-head{
        } */
        .sc-head-description{
            width: 100%;
            margin-bottom: 1rem;
        }
        /* .sc-head-shipment{
            width: 50%;
            text-align: left;
        } */
        /* .sc-head-payment{
            width: 50%;
            text-align: left;
        } */
        /* .shb-total{
            width: 100%;
        } */
        /* .shb-panel{
            position: absolute;
            top: 0;
            right: 0;
            width: auto;
        } */
        .sc-body{
            /* margin-bottom: 5rem; */
            padding-left: 0;
        }
        /* .sc-body-product{
            padding-right: 0;
        } */
        /* .product-list{
            padding-left: 0;
            padding-right: 0;
        } */
        .sbi-card{
            box-shadow: unset;
            padding: 0;
        }
        .sbi-card-detail{
            width: 100%;
        }
        .sbi-card-product{
            width: 100%;
        }
        .sc-body-check{
            width: 10%;
        }
        /* .sc-body-image{
            width: 50%;
        } */
        /* .sc-body-description{
            width: 50%;
        } */
        /* .sc-body-shipment{
            width: 100%;
        } */
        .shipment-btn{
            /* padding: 2%; */
            /* height: 100%; */
        }
        /* .pf-text{
            width: 100%;
        } */
        .pf-buttons{
            width: 100%;
        }
        /* .payment-summary{
            padding-left: 0;
        }
        .payment-panel-button{
            padding-left: 0;
        } */
        .total-pay{
            width: 100%;
        }
    }
    @media only screen and (max-width: 370px){
        .sc-body-check{
            width: 10%;
        }
        /* .sc-body-image{
            width: 100%;
        } */
        /* .sc-body-description{
            width: 100%;
            padding: 0;
        } */
    }
</style>