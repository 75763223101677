<template>
    <div class="sbi-card">
        <div class="sc-head-invoice">
            <div class="shi-text">
                <span></span>
            </div>
            <div class="shi-status">
                <div class="shi-status-label">
                    <span></span>
                </div>
            </div>
        </div>
        <div class="sbi-card-part">
            <div class="scp-inner" v-for="n in 2" :key="n">
                <div class="sc-head">
                    <h2></h2>
                    <div class="sc-head-detail">
                        <div class="sc-head-description">
                            <div class="sc-head-row">
                                <div class="shr-param">
                                    <span></span>
                                </div>
                                <div class="shr-value">
                                    <span></span>
                                </div>
                            </div>
                            <div class="sc-head-row">
                                <div class="shr-param">
                                    <span></span>
                                </div>
                                <div class="shr-value">
                                    <span></span>
                                </div>
                            </div>
                            <div class="sc-head-row">
                                <div class="shr-param">
                                    <span></span>
                                </div>
                                <div class="shr-value">
                                    <span></span>
                                </div>
                            </div>
                        </div>
                        <div class="sc-head-shipment">
                            <span></span>
                            <div class="shs-name">
                                <strong></strong>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div class="sc-head-bottom">
                        <div class="shb-total">
                            <div class="sc-head-row">
                                <div class="shr-param">
                                    <span></span>
                                </div>
                                <div class="shr-value">
                                    <span></span>
                                </div>
                            </div>
                        </div>
                        <div class="shb-panel">
                            <div class="shb-panel-button">
                                <span :id="('spb-btn-' + n)" @click="expandElement('spb-btn-' + n, 'sc-body-' + n)">+</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sc-body" :id="('sc-body-' + n)" style="max-height: 0px;">
                    <div class="sc-body-product">
                        <div class="sbp-head">
                            <!-- <h3 v-if="order.type_code == 'MS'">Produk</h3> -->
                            <!-- <h3 v-else>Layanan</h3> -->
                        </div>
                        <div class="product-list" v-for="m in 2" :key="m">
                            <div class="pl-body">
                                <div class="sc-body-image">
                                    <!-- <img :src="product.image" alt="melinda care" /> -->
                                </div>
                                <div class="sc-body-description">
                                    <h4>Product Name</h4>
                                </div>
                                <div class="sc-body-qty">
                                    <span class="sbq-count">QT</span>
                                    <span class="sbq-unit">unit</span>
                                    <div class="sc-body-price">
                                        <span>price</span>
                                    </div>
                                </div>
                            </div>
                            <div class="pl-footer">
                                <div class="pf-text">
                                    <!-- <p>Catatan</p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="total-pay">
            <div class="sc-head-row shr-total">
                <div class="shr-param">
                    <strong>Total Bayar</strong>
                </div>
                <div class="shr-value">
                    <strong>totalprice</strong>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    function expandElement(bt, id){
        const button = document.getElementById(bt);
        const el = document.getElementById(id);
        if(el.style.maxHeight == '0px'){
            button.innerHTML = '-';
            el.style.maxHeight = el.scrollHeight + 'px';
        }else{
            button.innerHTML = '+';
            el.style.maxHeight = '0';
        }
    }
</script>

<style scoped>
    section{
        padding-top: 2.5%;
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 2.5%;
        width: 100%;
        transition: 0.2s;
        color: var(--grey);
        transition: 0.2s;
    }
    section h1{
        color: var(--white);
        margin: 0;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 1.1rem;
    }
    .section-body{
        display: flex;
        transition: 0.2s;
    }
    .sb-inner{
        width: 70%;
        padding-right: 1rem;
        transition: 0.2s;
    }
    .sbi-card{
        border-radius: 10px;
        padding: 5%;
        padding-top: 1rem;
        padding-bottom: 2rem;
        margin-bottom: 5rem;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
        transition: 0.2s;
        /* font-family: sans-serif; */
        background: var(--white);
        /* border: 1px solid green; */
    }
    .sbi-card-part{
        /* padding-top: 2rem; */
        /* margin-bottom: 2rem; */
        /* border-top: 1px solid var(--softgrey); */
        border-bottom: 1px solid var(--softgrey);
    }
    .scp-inner{
        border-top: 1px solid var(--softgrey);
        padding: 1rem 0 0 0;
    }
    .sc-head{
        /* border-bottom: 1px solid var(--grey); */
        padding-bottom: 1rem;
        font-size: 0.9rem;
    }
    .sc-head h2{
        margin: 0;
        margin-bottom: 1rem;
        font-size: 1rem;
        width: 200px;
        height: 1rem;
        animation: pulse-bg 1s infinite;
        border-radius: 1rem;
    }
    .sc-head-detail{
        display: flex;
        flex-wrap: wrap;
    }
    .sc-head-description{
        width: 70%;
        transition: 0.2s;
    }
    .sc-head-invoice{
        margin-bottom: 0.5rem;
    }
    .shi-text span{
        padding: 0 5rem 0 5rem;
        animation: pulse-bg 1s infinite;
        border-radius: 1rem;
    }
    .shi-status{
        padding: 1rem 0 1rem 0;
    }
    .shi-status-label{
        width: 200px;
        padding: 1.1rem 1rem 1.1rem 1rem;
        text-align: center;
        user-select: none;
        animation: pulse-bg 1s infinite;
    }
    /* .ssl-waiting{
        background: rgb(104, 109, 34);
        color: var(--white);
    }
    .ssl-confirmed{
        background: rgb(12, 117, 86);
        color: var(--white);
    }
    .ssl-done{
        background: rgb(7, 163, 46);
        color: var(--white);
    } */
    .sc-head-row{
        display: flex;
        width: 100%;
        margin-bottom: 0.5rem;
    }
    .shr-total{
        font-size: 1rem;
    }
    .shr-param{
        width: 50%;
    }
    .shr-param span{
        padding: 0 5rem 0 5rem;
        animation: pulse-bg 1s infinite;
        border-radius: 1rem;
    }
    .shr-value{
        width: 50%;
    }
    .shr-value span{
        padding: 0 1rem 0 1rem;
        animation: pulse-bg 1s infinite;
        border-radius: 1rem;
    }
    .sc-head-shipment{
        width: 30%;
        text-align: center;
        transition: 0.2s;
    }
    .sc-head-shipment span{
        padding: 0 5rem 0 5rem;
        animation: pulse-bg 1s infinite;
        border-radius: 1rem;
    }
    .shs-name{
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .shs-name strong{
        padding: 0 4.5rem 0 4.5rem;
        animation: pulse-bg 1s infinite;
        border-radius: 1rem;
    }
    /* .sc-head-payment{
        width: 25%;
        text-align: center;
        transition: 0.2s;
    } */
    .sc-head-bottom{
        position: relative;
        display: flex;
        flex-wrap: wrap;
    }
    .shb-total{
        width: 70%;
        transition: 0.2s;
        /* border: 1px solid red; */
    }
    .shb-panel{
        position: absolute;
        top: 0;
        right: 0;
        width: auto;
        /* width: 50%;
        text-align: right;
        transition: 0.2s;
        border: 1px solid green; */
    }
    .shb-panel-button{
        /* padding: 1rem; */
        /* padding-left: 1rem; */
        /* padding-right: 1rem; */
        /* color: var(--primary); */
    }
    .shb-panel-button span{
        cursor: pointer;
        transition: 0.2s;
        color: var(--primary);
        padding-left: 1rem;
        padding-right: 1rem;
        user-select: none;
    }
    .shb-panel-button span:hover{
        color: var(--primary-hover);
    }
    .sc-body{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding-left: 2%;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-in-out;
    }
    .sc-body-product{
        width: 100%;
        font-size: 1rem;
    }
    .sbp-head{
        padding: 2.5%;
        padding-bottom: 0;
    }
    .sbp-head h3{
        margin: 0;
        /* margin-top: 1rem; */
    }
    .product-list{
        width: 100%;
        /* border-bottom: 1px solid var(--softgrey); */
        padding: 2.5%;
    }
    .pl-body{
        display: flex;
        flex-wrap: wrap;
    }
    .sbc-icon{
        width: 20px;
        height: 20px;
        border: 2px solid var(--softgrey);
        border-radius: 2px;
    }
    .sc-body-image{
        width: 30%;
        padding: 1%;
        padding-left: 0;
        transition: 0.2s;
    }
    .sc-body-image img{
        width: 100%;
        border-radius: 5px;
    }
    .sc-body-description{
        width: 70%;
        transition: 0.2s;
        padding: 0.5rem;
    }
    .sc-body-qty{
        margin-top: 1rem;
        color: blue;
    }
    .sbq-unit{
        padding-left: 0.5rem;
    }
    .sc-body-price{
        padding-top: 0.5rem;
        color: brown;
    }
    .pl-footer{
        display: flex;
        flex-wrap: wrap;
    }
    .pf-text{
        width: 60%;
        transition: 0.2s;
    }
    .sc-body-shipment{
        width: 34%;
        transition: 0.2s;
    }
    .total-pay{
        width: 50%;
        padding-top: 1rem;
    }
    .sos-head{
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    .sos-head span{
        cursor: pointer;
        background: var(--white);
        color: var(--softgrey);
        padding: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        border: 1px solid var(--softgrey);
        border-radius: 5px;
        transition: 0.2s;
        height: 10%;
    }
    .sos-head span:hover{
        background: var(--smoke);
    }
    .sos-body{
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        height: 75%;
        overflow: auto;
        /* border: 1px solid green; */
    }
    .sod-list{
        cursor: pointer;
        padding: 0.2rem;
    }
    .sod-list:hover{
        background: var(--smoke);
    }
    .sb-button{
        position: relative;
        /* display: flex; */
        width: 30%;
        /* min-height: 200px; */
        /* border: 1px solid var(--softgrey); */
        /* padding-left: 1rem; */
        /* justify-content: right; */
        transition: 0.2s;
    }
    .sbb-inner{
        /* border: 1px solid var(--softgrey); */
        border-radius: 10px;
        min-height: 100px;
        /* width: 100%; */
        /* top: 4.81rem; */
        /* transition: 0.2s; */
        z-index: 10;
        background: var(--white);
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
    }
    /* .cart-summary{
        padding: 1rem;
    }
    .cs-inner h2{
        margin: 0;
        margin-bottom: 0.5rem;
    }
    .cs-row{
        display: flex;
        flex-wrap: nowrap;
        padding: 1% 0 1% 0;
    }
    .cs-row-param{
        width: 50%;
    }
    .cs-row-value{
        width: 50%;
    }
    .buy-button{
        position: relative;
        width: 100%;
        height: 50px;
    }
    .buy-button span{
        position: absolute;
        left: 1rem;
        bottom: 1rem;
        padding: 1%;
        padding-left: 2rem;
        padding-right: 2rem;
        background: var(--primary);
        color: var(--white);
        border-radius: 2px;
        cursor: pointer;
        user-select: none;
    } */
    @media only screen and (max-width: 1500px){
        section{
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    @media only screen and (max-width: 1100px){
        /* .modal-content-body{
            border: 1px solid var(--softgrey);
        }
        .address-list{
            width: 100%;
            height: auto;
            border: none;
            padding-left: 1rem;
            padding-right: 1rem;
        }
        .address-panel{
            width: 100%;
            min-height: 100%;
            height: auto;
            padding-left: 1rem;
            padding-right: 1rem;
            margin-bottom: 1rem;
        }
        .payment-list{
            width: 100%;
            height: auto;
            border: none;
            padding-left: 1rem;
            padding-right: 1rem;
        }
        .payment-panel{
            width: 100%;
            min-height: 50%;
            height: auto;
            padding-left: 1rem;
            padding-right: 1rem;
        } */
        /* .sc-body-check{
            width: 10%;
        } */
        .sc-body-image{
            width: 27%;
        }
        .sc-body-description{
            width: 73%;
        }
    }
    @media only screen and (max-width: 1050px){
        .section-body{
            display: inline;
        }
        .sb-inner{
            width: 100%;
            padding-right: 0;
        }
        .sc-body-product{
            width: 100%;
        }
        .sc-body-shipment{
            width: 50%;
        }
        .sb-button{
            width: 100%;
            min-height: 310px;
        }
        .sbb-inner{
            border: 1px solid var(--softgrey);
            border-radius: 0;
            box-shadow: unset;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding-left: 2.5%;
            padding-right: 2.5%;
            background: var(--white);
        }
        section h1{
            color: var(--grey);
        }
        /* .modal-content-head{
        } */
        .sc-head-description{
            width: 100%;
            margin-bottom: 1rem;
        }
        .sc-head-shipment{
            width: 50%;
            text-align: left;
        }
        /* .sc-head-payment{
            width: 50%;
            text-align: left;
        } */
        .shb-total{
            width: 100%;
        }
        /* .shb-panel{
            position: absolute;
            top: 0;
            right: 0;
            width: auto;
        } */
        .sc-body{
            /* margin-bottom: 5rem; */
            padding-left: 0;
        }
        .sc-body-product{
            padding-right: 0;
        }
        .product-list{
            padding-left: 0;
            padding-right: 0;
        }
        .sbi-card{
            box-shadow: unset;
            padding: 0;
        }
        .sc-body-check{
            width: 10%;
        }
        .sc-body-image{
            width: 50%;
            /* border: 1px solid green; */
        }
        .sc-body-description{
            width: 50%;
            /* padding: 0; */
            /* border: 1px solid green; */
        }
        .sc-body-shipment{
            width: 100%;
        }
        .shipment-btn{
            /* padding: 2%; */
            /* height: 100%; */
        }
        .pf-text{
            width: 100%;
        }
        .pf-buttons{
            width: 100%;
        }
        /* .payment-summary{
            padding-left: 0;
        }
        .payment-panel-button{
            padding-left: 0;
        } */
        .total-pay{
            width: 100%;
        }
    }
    @media only screen and (max-width: 370px){
        .sc-body-check{
            width: 10%;
        }
        .sc-body-image{
            width: 100%;
        }
        .sc-body-description{
            width: 100%;
            padding: 0;
        }
    }
</style>