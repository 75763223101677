import { defineStore } from "pinia";
// import axiosOne from "../axios_one.js";
import axiosTwo from "../axios_two.js";
import { removeHTML } from "@/functions/general.js";

export const useToolStore = defineStore("tool", {
    state: () => ({
        // ==================== states used on tools page and its relation
        data: null,
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on tools page and its relation
        async setToolData(){
            try{
                const services = [];
                const response = await axiosTwo.get(`service`);
                for(var i = 0; i < response.data.services.length; i++){
                    services.push(
                        {
                            title: response.data.services[i].name,
                            description: removeHTML(response.data.services[i].description)
                        }
                    );
                }
                console.log(services);
            }catch(error){
                console.log(error.response);
            }
        },
    }
});