<template>
    <section>
        <div class="section-head">
            <h1>Profile</h1>
            <!-- {{ loggedIn }} -->
        </div>
        <div class="section-body">
            <div class="section-body-inner" v-if="loggedIn">
                <div class="sbi-data">
                    <div class="sbi-data-inner">
                        <div class="sdi-row">
                            <div class="sdi-row-param">
                                <span>Nama</span>
                            </div>
                            <div class="sdi-row-value">
                                <span>{{ loggedIn.name || 'Belum ada'}}</span>
                            </div>
                        </div>
                        <div class="sdi-row">
                            <div class="sdi-row-param">
                                <span>Email</span>
                            </div>
                            <div class="sdi-row-value">
                                <span>{{ loggedIn.email || 'Belum ada'}}</span>
                            </div>
                        </div>
                        <div class="sdi-row">
                            <div class="sdi-row-param">
                                <span>Telepon</span>
                            </div>
                            <div class="sdi-row-value">
                                <span>{{ loggedIn.phone || 'Belum ada' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sbi-tab">
                    <DoctorProfileComponent v-if="loggedIn.role_id == 3"/>
                    <UserProfileComponent v-else/>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { computed } from "@vue/runtime-core";
    import { useAccountStore } from '../../stores/account';
    import UserProfileComponent from "./UserProfileComponent.vue";
    import DoctorProfileComponent from "./DoctorProfileComponent.vue";
    const loggedIn = computed(() => useAccountStore().loggedIn);
</script>

<style scoped>
    section{
        padding-top: 2rem;
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 2.5%;
        width: 100%;
        transition: 0.2s;
        color: var(--grey);
    }
    section h1{
        margin: 0;
        margin-bottom: 1rem;
    }
    .section-head{
        text-align: center;
        border-bottom: 1px solid var(--softgrey);
    }
    .section-body-inner{
        display: flex;
        flex-wrap: wrap;
    }
    .sbi-data{
        width: 40%;
        /* border: 1px solid green; */
        /* border-radius: 5px; */
        padding-top: 2rem;
        padding-right: 1rem;
        overflow: hidden;
        transition: 0.2s;
        text-align: center;
    }
    .sdi-row{
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        text-align: left;
        margin-bottom: 1rem;
    }
    .sdi-row-param{
        width: 30%;
    }
    .sdi-row-value{
        width: 70%;
    }
    .sbi-tab{
        width: 60%;
        padding: 2rem;
        padding-left: 1rem;
        padding-right: 0;
        transition: 0.2s;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    @media only screen and (max-width: 1050px){
        .sbi-data{
            width: 100%;
            padding-right: 0;
        }
        .sbi-tab{
            width: 100%;
            padding-left: 0;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
    }
</style>