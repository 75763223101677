import { setCookie, getCookie, showNotification } from "@/functions/general";
import { sessionExpiration } from "@/equipments/settings.js";
import { clearInput } from "@/functions/account.js";
import { useChatStore } from "./chat.js";
import { defineStore } from "pinia";
import axiosClient from "../axios.js";
import router from "@/router";
import { useGeneralStore } from "./general.js";

export const useAccountStore = defineStore("account", {
    state: () => ({
        // ==================== states used on user account and its relation
        loggedIn: null,
        register: null,
        googleURL: null,
        mailphone: null,
        waitingProcess: null,
        successMessage: null,
        errorMessage: null
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on user account and its relation
        async setRegister(mp, inputid){
            try{
                const response = await axiosClient.post(`auth/register`, mp);
                this.register = response.data;
                this.mailphone = mp.mailphone;
                this.waitingProcess = null;
                window.sessionStorage.setItem('authToken', response.data.accessToken);
                const input = document.getElementById(inputid);
                input.value = '';
                input.blur();
                console.log(this.register);
            }catch(error){
                this.waitingProcess = null;
                if(error.response?.data?.message){
                    this.errorMessage = error.response.data.message;
                }
                console.log(error.response);
            }
        },
        async setGoogleURL(){
            try{
                const response = await axiosClient.get(`auth/google/url`);
                this.googleURL = response.data;
            }catch(error){
                console.log(error);
            }
        },
        async resendEmail(){
            try{
                const response = await axiosClient.post(`verify/resend/email`);
                this.waitingProcess = null;
                console.log(response.data);
            }catch(error){
                this.waitingProcess = null;
                console.log(error);
            }
        },
        async setPassword(params){
            try{
                const response = await axiosClient.post(`auth/password/reset`, params);
                this.waitingProcess = null;
                console.log(response.data);
                this.waitingProcess = null;
                this.errorMessage = null;
                clearInput(['input-set-password', 'input-set-password-confirm']);
                this.successMessage = 'Atur Password berhasil, silakan Login';
            }catch(error){
                const errorData = error.response.data;
                console.log(error);
                this.waitingProcess = null;
                this.errorMessage = errorData.errors.password;
            }
        },
        async setLogin(params){
            useGeneralStore().setWaitingProcess(1);
            try{
                // await axiosClient.post(`auth/login`, params);
                const response = await axiosClient.post(`auth/login`, params);
                // this.loggedIn = response.data.user;
                setCookie('session', response.data.accessToken, sessionExpiration);
                this.userSync();
                // this.waitingProcess = null;
                clearInput(['nlf-mailphone-input', 'nlf-password-input']);
                useGeneralStore().setWaitingProcess(null);
                window.location.reload();
            }catch(error){
                useGeneralStore().setWaitingProcess(null);
                if(error.response?.data?.message){
                    showNotification('', error.response?.data?.message, 2000, 'var(--primary)');
                }
                console.log(error);
            }
        },
        async userSync(){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                const response = await axiosClient.get(`auth/me`);
                this.loggedIn = response.data.user;
                setCookie('session', getCookie('session'), sessionExpiration);
                useChatStore().syncChats();
            }catch(error){
                console.log(error);
            }
        },
        async signOut(){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            try{
                await axiosClient.post(`auth/logout`);
                this.loggedIn = null;
                setCookie('session', getCookie('session'), 0);
                router.push('/');
                window.location.reload();
                // console.log(getCookie('session'));
            }catch(error){
                console.log(error);
            }
        },
        clearRegister(){
            this.register = null;
            this.mailphone = null;
        },
        setAuthToken(token){
            // console.log(token);
            window.sessionStorage.setItem('authToken', token);
            // console.log(window.sessionStorage.getItem('authToken'));
        },
        setWaitingProcess(value){
            this.waitingProcess = value;
        }
    }
});