// import { useGeneralStore } from '@/stores/general';
import { useAccountStore } from '@/stores/account';
import { showNotification } from './general';

export function signUp(a){
    const mp = document.getElementById(a);
    if(mp.value){
        const params = {mailphone: mp.value, role:'User'};
        useAccountStore().setRegister(params, a);
        // mp.value = '';
        // mp.blur();
        useAccountStore().setWaitingProcess(1);
    }else{
        alert('Masukkan email atau nomor ponsel');
    }
}
export function goSignUp(event){
    if(event.key === 'Enter'){
        event.preventDefault();
        document.getElementById('signup-button').click();
    }
}
export function resendEmail(){
    useAccountStore().setWaitingProcess(1);
    // const mailphone = computed(() => useAccountStore().mailphone);
    // const params = {token: token}
    useAccountStore().resendEmail();
}
export function newAttempt(){
    useAccountStore().clearRegister();
}
export function setPassword(a, b){
    useAccountStore().setWaitingProcess(1);
    const password = document.getElementById(a);
    const passwordConfirm = document.getElementById(b);
    if(password.value && passwordConfirm.value){
        const params = {password: document.getElementById(a).value, password_confirmation: document.getElementById(b).value};
        useAccountStore().setPassword(params);
    }else{
        alert('Password dan Konfirmasi Password diperlukan');
    }
}
export function toPasswordInput(event){
    if(event.key === 'Enter'){
        event.preventDefault();
        document.getElementById('nlf-password-input').focus();
    }
}
export function clickLogin(event){
    if(event.key === 'Enter'){
        event.preventDefault();
        document.getElementById('nbt-login').click();
        // const usermail = document.getElementById('nlf-mailphone-input');
        // const password = document.getElementById('nlf-password-input');
        // if(usermail.value && password.value){
        // }else{
        //     showNotification('', 'Kedua input Email/Ponsel dan Password harus diisi', 2000);
        // }
    }
}
export function signIn(){
    const mailphone = document.getElementById('nlf-mailphone-input');
    const password = document.getElementById('nlf-password-input');
    if(mailphone.value && password.value){
        useAccountStore().setLogin({username: mailphone.value, password: password.value});
    }else{
        showNotification('', 'Kedua input Email/Ponsel dan Password harus diisi', 2000);
    }
}
export function signOut(){
    useAccountStore().signOut();
}
export function clearInput(array){
    for(var i = 0; i < array.length; i++){
        document.getElementById(array[i]).value = '';
    }
}