<template>
    <SurveyFormComponent/>
</template>

<script setup>
    import SurveyFormComponent from '@/components/survey/SurveyFormComponent.vue';
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>