<template>
    <div class="content">
        <TopBackgroundComponent/>
        <ConfirmationComponent/>
    </div>
</template>
<script setup>
    import TopBackgroundComponent from "../components/TopBackgroundComponent.vue";
    import ConfirmationComponent from "../components/order/ConfirmationComponent.vue";
    window.scrollTo(0, 0);
</script>
<style scoped>
    /*  */
</style>
