<template>
    <div class="sbi-form">
        <div class="sbi-form-inner" v-if="register">
            <div class="sfi-loader" v-if="waitingProcess">
                <p>Loading...</p>
            </div>
            <div class="sfi-head">
            </div>
            <div class="sfi-body">
                <div class="sfi-phone">
                    <p style="color: green;">Kami telah mengirim email ke : <span style="color: maroon;">{{ mailphone }}</span>, silakan cek email tersebut, lalu klik verifikasi untuk memverifikasi akun anda</p>
                    <hr/>
                    <p>Belum menerima email ?</p>
                    <div class="new-attempt">
                        <span class="new-attempt-btn" @click="resendEmail();">Kirim ulang email !</span>
                        <span class="new-attempt-link" @click="newAttempt">Daftar lagi</span>
                    </div>
                </div>
                <hr/>
                <div class="sfi-or" v-if="googleURL">
                    <p>atau</p>
                </div>
                <div class="sfi-google">
                    <div class="sfi-google-button" v-if="googleURL">
                        <a :href="googleURL">
                            <div class="sgb-inner">
                                <div class="sgb-logo">
                                    <img src="../../assets/images/logos/google-small.webp" alt="melinda care" />
                                </div>
                                <div class="sgb-text">
                                    <span>Daftar dengan Google</span>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="sbi-form-inner" v-else>
            <div class="sfi-loader" v-if="waitingProcess">
                <p>Loading...</p>
            </div>
            <div class="sfi-head">
            </div>
            <div class="sfi-body">
                <div class="sfi-phone">
                    <div class="sfi-input">
                        <div class="sfi-label">Email/Nomor Ponsel</div>
                        <input type="text" placeholder="Email/Nomor Ponsel" id="mailphone" @keydown="goSignUp" />
                    </div>
                    <div class="error-message" v-if="errorMessage">
                        <p>{{ errorMessage }}</p>
                    </div>
                    <div class="sfi-input">
                        <div class="sfi-button" id="signup-button" @click="signUp('mailphone');">Daftar</div>
                    </div>
                </div>
                <hr/>
                <div class="sfi-or" v-if="googleURL">
                    <p>atau</p>
                </div>
                <div class="sfi-google">
                    <div class="sfi-google-button" v-if="googleURL">
                        <a :href="googleURL">
                            <div class="sgb-inner">
                                <div class="sgb-logo">
                                    <img src="../../assets/images/logos/google-small.webp" alt="melinda care" />
                                </div>
                                <div class="sgb-text">
                                    <span>Daftar dengan Google</span>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed } from "@vue/runtime-core";
    import { useAccountStore } from '../../stores/account';
    import { signUp, goSignUp, resendEmail, newAttempt } from '../../functions/account';
    const register = computed(() => useAccountStore().register);
    const googleURL = computed(() => useAccountStore().googleURL);
    const mailphone = computed(() => useAccountStore().mailphone);
    const waitingProcess = computed(() => useAccountStore().waitingProcess);
    const errorMessage = computed(() => useAccountStore().errorMessage);
    useAccountStore().setGoogleURL();
</script>

<style>
    .sbi-form{
        width: 50%;
        padding: 2rem;
        padding-left: 1rem;
        padding-right: 0;
        transition: 0.2s;
    }
    .sbi-form-inner{
        position: relative;
        padding: 2rem;
        border: 1px solid var(--softgrey);
        border-radius: 5px;
        transition: 0.2s;
        overflow: hidden;
    }
    .sfi-loader{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation: pulse-bg-transparent 1s infinite;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--grey);
    }
    .new-attempt{
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .new-attempt-btn{
        background: var(--primary);
        color: var(--white);
        cursor: pointer;
        user-select: none;
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .new-attempt-link{
        margin-left: 2rem;
        color: var(--primary);
        cursor: pointer;
        user-select: none;
    }
    .sfi-input{
        margin-bottom: 1rem;
    }
    .sfi-label{
        margin-bottom: 0.5rem;
    }
    .sfi-input input{
        width: 100%;
        height: 2.5rem;
        border: 1px solid var(--softgrey);
        border-radius: 5px;
        padding: 1%;
        padding-left: 1rem;
        outline: none;
    }
    .sfi-input input::-webkit-outer-spin-button,
    .sfi-input input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    .sfi-input input[type=number] {
        -moz-appearance: textfield;
    }
    .error-message{
        color: var(--red);
        font-weight: 300;
        font-family: arial;
        font-style: italic;
        font-size: 0.9rem;
    }
    .sfi-button{
        width: 200px;
        background: var(--primary);
        color: var(--white);
        text-align: center;
        padding: 0.5rem;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.2s;
        user-select: none;
    }
    .sfi-button:hover{
        background: var(--primary-hover);
    }
    .sfi-or{
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 50%;
        text-align: left;
        transition: 0.2s;
    }
    .sfi-or p{
        margin: 0;
        padding-left: 1rem;
    }
    .sfi-google a{
        text-decoration: none;
        color: var(--grey);
    }
    .sfi-google-button{
        height: 50px;
        width: 50%;
    }
    .sgb-inner{
        display: flex;
        padding: 0.5rem;
        padding-left: 0;
        padding-right: 0;
        border: 1px solid var(--softgrey);
        border-radius: 5px;
        height: 100%;
        width: 100%;
        align-items: center;
        cursor: pointer;
        user-select: none;
        transition: 0.2s;
        overflow: hidden;
        background: var(--white);
    }
    .sgb-inner:hover{
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15), 0 5px 20px 0 rgba(0, 0, 0, 0.15);
        border: 1px solid var(--white);
    }
    .sgb-logo{
        width: 25%;
        height: 100%;
        text-align: center;
    }
    .sgb-logo img{
        height: 100%;
    }
    .sgb-text{
        width: 75%;
        padding: 1rem;
        white-space: nowrap;
    }
    @media only screen and (max-width: 1600px){
        .sfi-google-button{
            width: 75%;
        }
    }
    @media only screen and (max-width: 1500px){
        /* section{
            padding-left: 5%;
            padding-right: 5%;
        } */
    }
    @media only screen and (max-width: 1050px){
        /* .sbi-image{
            width: 0;
            padding-right: 0;
        } */
        /* .sbi-image img{
            width: 0;
        } */
        .sbi-form{
            width: 100%;
            padding-left: 0;
        }
        .sfi-google-button{
            width: 50%;
        }
    }
    @media only screen and (max-width: 750px){
        /* section{
            padding-left: 2.5%;
            padding-right: 2.5%;
        } */
        .sbi-form-inner{
            padding: 0.5rem;
        }
        .sfi-or{
            width: 100%;
            text-align: center;
        }
        .sfi-or p{
            padding-left: 0;
        }
        .sfi-google-button{
            width: 100%;
        }
    }
    @media only screen and (max-width: 450px){
        .sbi-form-inner{
            border: none;
            border-radius: 0;
        }
        .sfi-button{
            width: 100%;
        }
    }
    @media only screen and (max-width: 310px){
        .sbi-form-inner{
            padding: 0.5rem;
        }
        .sfi-google-button{
            font-size: 0.75rem;
        }
    }
    /*  */
</style>