import axios from "axios";
const axiosOne = axios.create({
  baseURL: process.env.VUE_APP_ONE_API + 'api/',
  headers: { "Content-Type": "application/json", Accept: "application/json" }
});

axiosOne.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${sessionStorage.getItem("authToken")}`;
  return config;
});

export default axiosOne;