<template>
    <!-- <section> -->
        <!-- <div class="section-head">
            <h2>Fasilitas Kesehatan</h2>
        </div> -->
        <div class="section-body">
            <div class="sb-inner">
                <div class="sb-part" v-for="facility in facilities" :key="facility">
                    <a :href="facility?.web_url" target="_blank">
                        <div class="sb-part-inner">
                            <div class="sb-part-image">
                                <img :src="facility.thumbnail?.path_url" :alt="facility.name"/>
                            </div>
                            <div class="sb-part-detail">
                                <div class="spd-label">
                                    <h3>{{ facility.category }}</h3>
                                </div>
                                <div class="spd-title">
                                    <h4>{{ facility.name }}</h4>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    <!-- </section> -->
</template>

<script setup>
    import { computed } from 'vue';
    import { useFacilityStore } from '@/stores/facility';
    const facilities = computed(() => useFacilityStore().allFacilities);
    useFacilityStore().getAllFacilities();
    // const sites = computed(() => useFacilityStore().ourSites);
    // useFacilityStore().setOurSites();
</script>

<style scoped>
    section{
        /* padding: 2.5rem 10% 0 10%; */
        color: var(--grey);
        transition: 0.2s;
    }
    .section-head h2{
        font-size: 1.2rem;
    }
    .sb-inner{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    .sb-part{
        width: 33.33%;
        padding: 0.5rem;
        transition: 0.2s;
    }
    .sb-part a{
        color: var(--grey);
    }
    .sb-part-inner{
        overflow: hidden;
        background: var(--smoke);
    }
    .sb-part-image{
        /* max-height: 200px; */
        overflow: hidden;
    }
    .sb-part-image img{
        width: 100%;
    }
    .sb-part-detail{
        padding: 0.5rem;
    }
    .spd-label h3{
        margin: 0;
        font-size: 0.9rem;
    }
    .spd-title h4{
        margin: 0;
        font-size: 1.1rem;
    }
    @media only screen and (max-width: 1500px){
        section{
            /* padding: 2.5rem 5% 0 5%; */
        }
        /* .sb-part{
            width: 50%;
        } */
    }
    @media only screen and (max-width: 1000px){
        .sb-part{
            width: 50%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            /* padding: 2.5rem 2.5% 0 2.5%; */
        }
        .sb-part{
            width: 100%;
        }
    }
</style>