<template>
    <div class="content">
        <!-- <TopBackgroundComponent/> -->
        <DarkCrumbComponent :data="crumbdata"/>
        <DoctorProfileComponent/>
    </div>
</template>
<script setup>
    // import TopBackgroundComponent from '../components/TopBackgroundComponent.vue';
    import DarkCrumbComponent from '@/components/DarkCrumbComponent.vue';
    import DoctorProfileComponent from "../components/doctor/DoctorProfileComponent.vue";
    const crumbdata = [
        {
            title: 'Home',
            type: 'link',
            to: '/'
        },
        {
            title: 'Cari Dokter',
            type: 'link',
            to: '/dokter'
        },
        {
            title: 'Profil Dokter',
            type: 'text'
        }
    ];
    window.scrollTo(0, 0);
</script>
<style scoped>
    /*  */
</style>
