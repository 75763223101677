<template>
    <ToolComponent/>
</template>

<script setup>
    import ToolComponent from '@/components/tools/ToolComponent.vue';
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>