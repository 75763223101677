<template>
    <section style="background: var(--cream);">
        <div class="section-inner">
            <div class="si-head">
                <h1>Verifikasi Peserta Event</h1>
                <h2>{{ allData?.detail.title }}</h2>
                <!-- <div style="padding: 1rem 0 1rem 0; border: 1px solid red; border-radius: 5px; text-align: center; padding: 1rem;">
                    <p>Untuk seluruh peserta FUN RUN wajib konfirmasi kembali ke nomor CS <a href="https://api.whatsapp.com/send?phone=6285939199326&text=Nama: Komunitas: " target="_blank">085939199326</a> (maks. 3 hari kedepan) dengan FORMAT : NAMA & NAMA KOMUNITAS (sesuai pengumuman) Untuk update informasi Follow Channel WhatsApp kami di link berikut: </p><a href="https://whatsapp.com/channel/0029VaIjfen8PgsPttRBzr1U" style="font-size: 1.5rem; font-weight: 700;">Klik di Sini!</a>
                </div> -->
            </div>
            <div class="si-body">
                <div class="sb-qr-code" v-if="verificationData">
                    <div class="qr-instruction">
                        <p>Tunjukkan kode ini ke panitia!</p>
                    </div>
                    <div class="qr-field" id="qr-field">
                        <qrcode-vue :value="verificationData?.qrCode" :size="250" level="H" />
                    </div>
                    <div class="qr-data" id="qr-data">
                        <div>{{ verificationData?.qrCode }}</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { computed } from '@vue/reactivity';
    import { useEventStore } from '@/stores/event';
    import { useRoute } from 'vue-router';
    import QrcodeVue from 'qrcode.vue'
    // import QRCodeStyling, {
    //     // DrawType,
    //     // TypeNumber,
    //     // Mode,
    //     // ErrorCorrectionLevel,
    //     // DotType,
    //     // CornerSquareType,
    //     // CornerDotType,
    //     // Extension
    // } from 'qr-code-styling';
    // import { onMounted } from '';
    // import { QrCodeStyling } from '@/functions/qrcodestyling';
    // import { inputSea } from '@/functions/event';
    // onMounted(() => {
    //     // generateQR();
    // });
    const verificationData = computed(() => useEventStore().eventVerification);
    useEventStore().setEventVerification(useRoute().params.slug);
    // function tableScroll(e){
    //     if(e.srcElement.scrollLeft > 0){
    //         e.srcElement.previousElementSibling.classList.add('stf-shadow');
    //     }else{
    //         e.srcElement.previousElementSibling.classList.remove('stf-shadow');
    //     }
    // }
    // function generateQR(){
    //     // const qrdata = shuffle('uyfdguysgdfsdgukjfhsudidpiosafipohjiohfiuohfsdiuoh');
    //     const qrCode = new QRCodeStyling({
    //         width: 300,
    //         height: 300,
    //         type: "svg",
    //         data: 'qrdata',
    //         image: "https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg",
    //         dotsOptions: {
    //             color: "#4267b2",
    //             type: "extra-rounded"
    //         },
    //         backgroundOptions: {
    //             color: "#e9ebee",
    //         },
    //         imageOptions: {
    //             crossOrigin: "anonymous",
    //             margin: 20
    //         }
    //     });
    //     document.getElementById("qr-field").innerHTML = "";
    //     // document.getElementById("qr-data").innerHTML = "<p>" + qrdata + "</p>";
    //     qrCode.append(document.getElementById("qr-field"));
    // }
</script>

<style scoped>
    section{
        padding: 100px 10% 2.5rem 10%;
        transition: 0.2s;
    }
    .si-head{
        display: flex;
        justify-content: center;
        padding: 0 0 2rem 0;
    }
    .si-head h1{
        margin: 0 0 1rem 0;
        font-size: 2rem;
        transition: 0.2s;
    }
    .cuti-baru-button button{
        background: var(--primary);
        color: var(--white);
        border: none;
        padding: 0.5rem 1rem 0.5rem 1rem;
        user-select: none;
        cursor: pointer;
    }
    .si-body{
        /* overflow: auto; */
    }
    .qr-instruction{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .qr-field{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .qr-data{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 100px 5% 2.5rem 5%;
        }
    }
    /* @media only screen and (max-width: 1000px){
        .sbp-filter-board{
            width: 100%;
        }
    } */
    @media only screen and (max-width: 750px){
        section{
            padding: 100px 2.5% 2.5rem 2.5%;
        }
        .si-head h1{
            font-size: 1.5rem;
        }
    }
</style>