<template>
    <template v-if="facilities">
        <div class="sb-inner" v-if="facilities.length > 0">
            <div class="sb-card" v-for="(facility) in facilities" :key="facility">
                <router-link :to="'/fasilitas-kesehatan/detail/' + facility.slug">
                    <div class="sbc-content">
                        <div class="sbc-label">
                            <!-- <span>{{ specialistActive }}</span> -->
                        </div>
                        <div class="sbc-image">
                            <img :src="facility.thumbnail" alt="melinda care" v-if="facility.thumbnail" />
                            <img src="../../assets/images/pictures/emptypp.webp" alt="melinda care" v-else/>
                        </div>
                        <div class="sbc-detail">
                            <h3>{{ facility.name }}</h3>
                            <div class="sd-specialist">
                                <span>{{ facility.type }}</span>
                            </div>
                            <div class="sd-stats">
                                <!-- <div class="sd-stats-row">
                                    <div class="sd-stats-param">Almamater</div>
                                    <div class="sd-stats-value">-</div>
                                </div>
                                <div class="sd-stats-row">
                                    <div class="sd-stats-param">Tempat Praktik</div>
                                    <div class="sd-stats-value">-</div>
                                </div> -->
                                <p class="sd-stats-tip">Klik untuk informasi selengkapnya</p>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
        <div class="search-not-found" v-else>
            <p>Tidak ditemukan dokter dengan kata kunci :</p>
            <div v-if="searchbarCategory.input.value">"{{ searchbarCategory.input.value }}"</div>
            <div v-if="searchbarSelectCategory.input.value.length > 0">"{{ searchbarSelectCategory.input.value }}"</div>
            <!-- <div v-if="searchbarCategory.select.value != 'all'">dalam {{ searchbarCategory.select.title }}</div>
            <div v-else>dalam Semua Spesialis</div> -->
        </div>
    </template>
</template>
<script setup>
    // import DoctorListSkeleton from '../skeletons/DoctorListSkeleton.vue';
    // import { useRoute } from 'vue-router';
    import { computed } from "@vue/runtime-core";
    import { useFacilityStore } from "../../stores/facility";
    // const specialistActive = computed(() => useFacilityStore().specialistActive);
    const facilities = computed(() => useFacilityStore().filteredFacilities);
    const searchbarCategory = computed(() => useFacilityStore().searchbarCategory);
    const searchbarSelectCategory = computed(() => useFacilityStore().searchbarSelectCategory);
    // useFacilityStore().setDlbc(useRoute().params.slug);
</script>
<style scoped>
    .section-body{
        width: 100%;
    }
    .sb-inner{
        display: flex;
        flex-wrap: wrap;
    }
    .sb-card{
        position: relative;
        min-width: 290px;
        width: 25%;
        padding: 1%;
        padding-top: 1rem;
        transition: 0.2s;
        user-select: none;
    }
    .sb-card a{
        text-decoration: none;
        color: #575757;
    }
    .sbc-label{
        position: absolute;
        top: 0;
        left: 0;
        /* width: 100%; */
        max-width: 50%;
        /* border: 1px solid green; */
        border-radius: 0 0 15px 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: var(--white);
    }
    .sbc-label span{
        padding: 5%;
        padding-top: 10%;
        padding-left: 10px;
        padding-right: 10px;
        background: rgb(207, 195, 19);
        /* color: var(--white); */
        border-radius: 0 0 15px 0;
    }
    .sbc-content{
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        height: 450px;
        transition: 0.2s;
        background: var(--white);
        /* background-image: conic-gradient(at 60% -50%, rgb(255, 255, 255), rgb(124, 124, 124), rgb(255, 255, 255)); */
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .sbc-content:hover{
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.37), 0 6px 20px 0 rgba(0, 0, 0, 0.37);
    }
    .sbc-image{
        width: 100%;
        min-height: 150px;
        height: 41%;
        overflow: hidden;
        background: lightgrey;
    }
    .sbc-image img{
        width: 100%;
    }
    .sbc-detail{
        padding: 2.5%;
    }
    .sd-specialist{
        background: var(--primary);
        color: var(--white);
        padding: 0.5rem;
        font-family: 'Courier New', Courier, monospace;
        font-size: 0.9rem;
        min-height: 75px;
    }
    .sd-stats{
        border-top: 1px solid var(--smoke);
        padding-top: 1rem;
    }
    .sd-stats-row{
        display: flex;
        flex-wrap: nowrap;
    }
    .sd-stats-param{
        width: 45%;
    }
    .sd-stats-value{
        width: 55%;
        padding-left: 1rem;
    }
    .sd-stats-tip{
        font-size: 0.9rem;
        font-style: italic;
        color: var(--softgrey);
        margin: 0;
        margin-top: 1rem;
    }
    .sbc-detail h3{
        font-family: poppins;
        text-align: left;
        color: #575757;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin: 0;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
    .search-not-found{
        font-family: Arial, Helvetica, sans-serif;
        font-style: italic;
        text-align: center;
        color: var(--grey);
        word-break: break-all;
    }
    @media only screen and (max-width: 1415px){
        .sb-card{
            width: 25%;
        }
    }
    @media only screen and (max-width: 950px){
        .sb-card{
            width: 50%;
        }
    }
    @media only screen and (max-width: 650px){
        .sb-card{
            min-width: 250px;
        }
    }
    @media only screen and (max-width: 550px){
        .sb-card{
            width: 100%;
        }
    }
    /*  */
</style>