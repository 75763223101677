import { useServiceStore } from "@/stores/service";
import { useDoctorStore } from "@/stores/doctor";
export const days = [
    {
        id: 0,
        name: 'Minggu'
    },
    {
        id: 1,
        name: 'Senin'
    },
    {
        id: 2,
        name: 'Selasa'
    },
    {
        id: 3,
        name: 'Rabu'
    },
    {
        id: 4,
        name: 'Kamis'
    },
    {
        id: 5,
        name: 'Jumat'
    },
    {
        id: 6,
        name: 'Sabtu'
    }
];
export const month = [
    {
        id: 0,
        name: 'Januari'
    },
    {
        id: 1,
        name: 'Pebruari'
    },
    {
        id: 2,
        name: 'Maret'
    },
    {
        id: 3,
        name: 'April'
    },
    {
        id: 4,
        name: 'Mei'
    },
    {
        id: 5,
        name: 'Juni'
    },
    {
        id: 6,
        name: 'Juli'
    },
    {
        id: 7,
        name: 'Agustus'
    },
    {
        id: 8,
        name: 'September'
    },
    {
        id: 9,
        name: 'Oktober'
    },
    {
        id: 10,
        name: 'November'
    },
    {
        id: 11,
        name: 'Desember'
    }
];
export function createCalendar(reqmonth, reqyear){
    const date = new Date();
    const today = date.getDay(); // today's day
    const tdate = date.getDate(); // today's date
    const tyear = date.getFullYear(); // this year
    const tmonth = date.getMonth(); // this month
    var rmname = '';
    for(var i = 0; i < month.length; i++){
        if(month[i].id == reqmonth){
            rmname = month[i].name;
        }
    }
    const headdata = {now:date.getTime(),today:{time: createTimestamp(tdate, tmonth, tyear).getTime(), index: today, day: days[today].name, date: tdate, month: tmonth, year: tyear}, month: {index: reqmonth, name: month[reqmonth].name}, year:{count:reqyear}};
    const sheet = {head:{title:rmname + ' ' + reqyear, data: headdata},body:{days: days}};
    for(var j = 0; j < days.length; j++){
        sheet.body.days[j].date = [];
    }
    const datenum = daysInMonth(reqmonth, reqyear);
    const fd = firstDay(reqyear, reqmonth);
    var st = 0;
    var dx = fd;
    for(i = 0; i < datenum; i++){
        const dt = i + 1;
        for(j = 0; j < sheet.body.days.length; j++){
            if(sheet.body.days[j].id == dx){
                const datestamp = createTimestamp(dt, reqmonth, reqyear);
                sheet.body.days[j].date.push({num:dt, time: datestamp.getTime()});
                st = 1;
            }else{
                if(st == 0){
                    sheet.body.days[j].date.push({num:0});
                }
            }
        }
        if(dx == 6){
            dx = 0;
        }else{
            dx = dx + 1;
        }
    }
    // =======================================================
    // console.log(datenum);
    // // console.log(rdate);
    // console.log(tdate);
    // console.log(today);
    // console.log(sheet);
    // // console.log(days);
    // console.log(daysInMonth(tmonth, tyear));
    // console.log(reqmonth + ' - ' + reqyear);
    // console.log(tmonth + ' - ' + tyear);
    // console.log('Time Now : ' + date.getTime());
    return sheet;
}
export function availableCalendar(sheet, schedules){
    const avsche = [];
    for(var i = 0; i < schedules.length; i++){
        if(!avsche.includes(schedules[i].day_id)){
            var dayid;
            if(schedules[i].day_id == 7){
                dayid = 0;
            }else{
                dayid = schedules[i].day_id;
            }
            avsche.push(dayid);
        }
    }
    avsche.sort();
    for(i = 0; i < sheet.body.days.length; i++){
        if(avsche.includes(sheet.body.days[i].id)){
            sheet.body.days[i].schedule = {available: true};
        }else{
            sheet.body.days[i].schedule = {available: false};
        }
    }
    console.log(sheet);
    return sheet;
}
export function daysInMonth(iMonth, iYear){
    return 32 - new Date(iYear, iMonth, 32).getDate();
}
export function firstDay(iYear, iMonth) {
    return new Date(iYear, iMonth, 1).getDay();
}
export function prevMonth(iMonth, iYear, type){
    let mn;
    let yr;
    if(iMonth == 0){
        mn = 11;
        yr = (iYear - 1);
    }else{
        mn = (iMonth - 1);
        yr = iYear;
    }
    if(type == 'service'){
        useServiceStore().setServiceCalendar(mn, yr);
    }else if(type == 'doctor'){
        useDoctorStore().setDoctorCalendar(mn, yr);
    }
}
export function nextMonth(iMonth, iYear, type){
    let mn;
    let yr;
    if(iMonth == 11){
        mn = 0;
        yr = (iYear + 1);
    }else{
        mn = (iMonth + 1);
        yr = iYear;
    }
    if(type == 'service'){
        useServiceStore().setServiceCalendar(mn, yr);
    }else if(type == 'doctor'){
        useDoctorStore().setDoctorCalendar(mn, yr);
    }
}
export function getDay(id){
    for(var i = 0; i < days.length; i++){
        if(days[i].id == id){
            return days[i].name;
        }
    }
    return false;
}
export function createTimestamp(d, m, y){ // month starts from 0
    var dtstamp;
    var mtstamp = m + 1;
    if(d < 10){
        dtstamp = '0' + d;
    }else{
        dtstamp = '' + d;
    }
    if(mtstamp < 10){
        mtstamp = '0' + mtstamp;
    }
    var dmstamp = y + '-' + mtstamp + '-' + dtstamp;
    // console.log(dmstamp);
    return new Date(dmstamp);
}
export function getMonthNum(string){
    const sm = string.split(' ');
    if(sm.length == 3){
        for(var i = 0; i < month.length; i++){
            if(month[i].name == sm[1]){
                // const mtcount = String(month[i].id + 1);
                // const mtpadded = mtcount.padStart(2, '0');
                return sm[2] + '-' + String(month[i].id + 1).padStart(2, '0') + '-' + sm[0];
            }
        }
    }
    return false;
}