import { useTransactionStore } from "@/stores/transaction";
import router from "@/router";
import { showNotification } from "./general";
import { useMedicineStore } from "@/stores/medicine";
export function priceToggle(){
    const ic = document.getElementById('srv-button-icon');
    const op = document.getElementById('srv-option');
    if(op.style.display == 'block'){
        ic.children[0].style.transform = 'rotate(0)';
        op.style.display = 'none';
    }else{
        ic.children[0].style.transform = 'rotate(180deg)';
        op.style.display = 'block';
    }
}
export function selectUnit(id, stock){
    const bt = document.getElementById(id);
    const ic = document.getElementById('srv-button-icon');
    bt.parentElement.style.display = 'none';
    bt.parentElement.previousElementSibling.children[0].innerHTML = bt.innerHTML;
    ic.children[0].style.transform = 'rotate(0)';
    document.getElementById('srv-input-id').value = stock.id;
    document.getElementById('srv-input-unit').value = stock.unit;
    useMedicineStore().selectedStock = stock;
    // useMedicineStore().medicineDetailStock = stock.total;
    // document.getElementById('srv-input-stock').value = stock.total;
    // document.getElementById('srv-text-stock').innerHTML = stock.total;
    console.log(stock);
}
export function directOrder(detail){
    // console.log(detail);
    const quantity = document.getElementById('input-qty');
    const minbuy = quantity.getAttribute('minvalue');
    if(useMedicineStore().selectedStock.total >= quantity.value){
        if(quantity.value >= minbuy){
            useTransactionStore().setDirectOrder('product', 'product', {id: document.getElementById('srv-input-id').value, unit: document.getElementById('srv-input-unit').value, qt: quantity.value, detail: detail});
            router.push('/order/products/confirmation');
        }else{
            showNotification('', 'Minimal pembelian untuk produk ini adalah ' + minbuy, 5000, null);
        }
    }else{
        showNotification('', 'Stock/persediaan untuk varian yang anda pilih tidak cukup', 2000, 'var(--primary)');
        console.log(useMedicineStore().selectedStock);
    }
}
export function resetInputQty(){
    const quantity = document.getElementById('input-qty');
    const minbuy = quantity.getAttribute('minvalue');
    if(quantity.value < minbuy){
        quantity.value = minbuy;
    }
}
// =====
export function inputSelectCategory(e){
    useMedicineStore().inputSelectCategory(e.srcElement.value);
    if(e.srcElement.value){
        e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'flex';
    }else{
        useMedicineStore().searchbarSelectCategory.input.value = '';
        e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'none';
    }
}
export function inputCategories(e){
    useMedicineStore().inputCategories(e.srcElement.value);
    if(e.srcElement.value){
        e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'flex';
    }else{
        e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'none';
    }
}
// export function inputMedicineList(e){
//     useMedicineStore().inputMedicineList(e.srcElement.value);
// }
export function selectCategory(category, inputid){
    useMedicineStore().selectCategory(category);
    const input = document.getElementById(inputid);
    input.value = null;
    useMedicineStore().searchbarSelectCategory.input.value = '';
    input.parentElement.nextElementSibling.children[0].style.display = 'none';
}
export function clearInputCategories(){
    const input = document.getElementById('sii-input-specialist');
    input.value = null;
    useMedicineStore().searchbarCategories.input = {value: null};
    useMedicineStore().inputCategories('');
    input.parentElement.nextElementSibling.children[0].style.display = 'none';
}
export function clearInputSelectCategory(){
    const input = document.getElementById('input-select-mdcategory');
    input.value = '';
    useMedicineStore().searchbarSelectCategory.input.value = '';
    useMedicineStore().inputSelectCategory('');
    useMedicineStore().searchMedicineAndCategory();
    input.parentElement.nextElementSibling.children[0].style.display = 'none';
}