export function accordion(bt){
    const button = document.getElementById(bt);
    const content = button.nextElementSibling;
    if(content.style.maxHeight == content.scrollHeight + 'px'){
        button.children[1].children[0].style.transform = 'rotate(0)';
        content.style.maxHeight = '0px';
    }else{
        button.children[1].children[0].style.transform = 'rotate(180deg)';
        content.style.maxHeight = content.scrollHeight + 'px';
    }
    // console.log(content.style.maxHeight);
}