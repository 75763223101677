<template>
    <div class="content">
        <MedicineDetailComponent/>
    </div>
</template>
<script setup>
    import MedicineDetailComponent from "../components/medicine/MedicineDetailComponent.vue";
    window.scrollTo(0, 0);
</script>
<style scoped>
    /*  */
</style>
