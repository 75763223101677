<template>
    <div class="content">
        <TopBackgroundComponent/>
        <CrumbComponent :data="crumbdata"/>
        <CoeDetailComponent/>
    </div>
</template>
<script setup>
    import TopBackgroundComponent from "../components/TopBackgroundComponent.vue";
    import CrumbComponent from '@/components/CrumbComponent.vue';
    import CoeDetailComponent from '@/components/coe/CoeDetailComponent.vue';
    const crumbdata = [
        {
            title: 'Home',
            type: 'link',
            to: '/'
        },
        {
            title: 'Layanan',
            type: 'link',
            to: '/layanan'
        },
        {
            title: 'Detail Layanan',
            type: 'text'
        }
    ];
    window.scrollTo(0, 0);
</script>
<style scoped>
    /*  */
</style>