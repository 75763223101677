<template>
    <div class="content">
        <RegisterComponent/>
    </div>
</template>
<script setup>
    import RegisterComponent from "../components/account/RegisterComponent.vue";
    window.scrollTo(0, 0);
</script>
<style scoped>
    /*  */
</style>