<template>
    <section>
        <div class="section-header">
            <h1>Keranjang Belanja</h1>
            <span class="clear-cart" @click="clearCart" v-if="cart">Kosongkan Keranjang</span>
            <!-- <p>Hello</p> -->
        </div>
        <div class="section-body" v-if="cart">
            <div class="sb-inner">
                <div class="sbi-card" v-for="cs in cart.seller" :key="cs.id">
                    <div class="sc-head">
                        <h2>{{ cs.name }}</h2>
                        <p class="sc-head-delete" @click="deleteSeller(cs.id);">Hapus</p>
                    </div>
                    <div class="sc-body">
                        <div class="product-list" v-for="product in cs.product" :key="product.id">
                            <div class="pl-body">
                                <div class="sc-body-check">
                                    <div class="sbc-icon" @click="itemUncheck(product.id);" v-if="product.checked == 1">
                                        <img src="../../assets/images/icons/checked.png" alt="melinda care" />
                                    </div>
                                    <div class="sbc-icon" @click="itemCheck(product.id);" v-else></div>
                                </div>
                                <div class="sc-body-image">
                                    <img :src="product.img" alt="melinda care" />
                                </div>
                                <div class="sc-body-description">
                                    <h3>{{ product.name }} ({{ product.unit }})</h3>
                                </div>
                                <div class="sc-body-qty">
                                    <span class="sbq-count">{{ product.qt }}</span>
                                    <span class="sbq-unit">{{ product.unit }}</span>
                                    <div class="sc-body-price">
                                        <span style="color: var(--primary); padding-right: 0.5rem;">x</span>
                                        <span>Rp{{ number_format(product.price, 0, ',', '.') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="pl-footer">
                                <div class="pf-text">
                                    <p>Sub Total : <span>Rp{{ number_format((product.qt * product.price), 0, ',', '.') }}</span></p>
                                </div>
                                <div class="pf-buttons">
                                    <div class="pb-delete" @click="deleteItem(product.id)">Hapus</div>
                                    <div class="pb-input">
                                        <div class="pb-input-button" @click="minCartQuantity(cs.id, cs.name, product.id, product.name, product.unit, product.img, product.price, 1); iQtyInput('pii-' + cs.id + '-' + product.id, '-')">
                                            <span>-</span>
                                        </div>
                                        <div class="pb-input-input">
                                            <input type="text" :id="'pii-' + cs.id + '-' + product.id" minvalue="1" :value="product.qt" @blur="resetInputQty('pii-' + cs.id + '-' + product.id)" />
                                        </div>
                                        <div class="pb-input-button" @click="plusCartQuantity(cs.id, cs.name, product.id, product.name, product.unit, product.img, product.price, 1); iQtyInput('pii-' + cs.id + '-' + product.id, '+');">
                                            <span>+</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sb-button">
                <div class="sbb-inner sticky-top-board">
                    <div class="cart-summary">
                        <div class="cs-inner">
                            <h2>Ringkasan Belanja</h2>
                            <div class="cs-row">
                                <div class="cs-row-param">
                                    <span>Harga Barang</span>
                                </div>
                                <div class="cs-row-value">
                                    <span>Rp{{ number_format(cart.total_price, 0, ',', '.') }}</span>
                                </div>
                            </div>
                            <!-- <div class="cs-row">
                                <div class="cs-row-param">
                                    <span>Biaya Layanan</span>
                                </div>
                                <div class="cs-row-value">
                                    <span>Rp5.000</span>
                                </div>
                            </div> -->
                            <hr/>
                            <div class="cs-row">
                                <div class="cs-row-param">
                                    <strong>Total Harga</strong>
                                </div>
                                <div class="cs-row-value">
                                    <strong>Rp{{ number_format(cart.total_price, 0, ',', '.') }}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="loggedIn">
                        <div class="buy-button" v-if="cartChecked > 0">
                            <router-link to="/order/products/confirmation">
                                <span @click="confirmCart">Beli</span>
                            </router-link>
                        </div>
                        <div v-else>
                            <div class="cart-warning">
                                <small>Pilih produk dulu</small>
                            </div>
                            <div class="buy-button buy-button-false">
                                <span>Beli</span>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="buy-button" v-if="cartChecked > 0">
                            <!-- <router-link to="/order/products/confirmation"> -->
                                <span @click="showLogin">Beli</span>
                            <!-- </router-link> -->
                        </div>
                        <div v-else>
                            <div class="cart-warning">
                                <small>Pilih produk dulu</small>
                            </div>
                            <div class="buy-button buy-button-false">
                                <span>Beli</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-body">
            <p class="empty-message">Keranjang belanja anda kosong</p>
        </div>
    </section>
</template>

<script setup>
    import { computed } from "@vue/runtime-core";
    import { useAccountStore } from "@/stores/account";
    import { useTransactionStore } from '../../stores/transaction';
    import { showLogin, number_format } from "@/functions/general";
    import { iQtyInput, minCartQuantity, plusCartQuantity, itemCheck, itemUncheck, clearCart, deleteSeller, deleteItem, confirmCart, resetInputQty } from '@/functions/cart';
    const loggedIn = computed(() => useAccountStore().loggedIn);
    const cart = computed(() => useTransactionStore().cart);
    const cartChecked = computed(() => useTransactionStore().cartChecked);
    // useTransactionStore().getConfirmCart();
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
</script>

<style scoped>
    section{
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 2.5%;
        width: 100%;
        transition: 0.2s;
        color: var(--white);
        transition: 0.2s;
    }
    section h1{
        /* color: var(--white); */
        font-size: 1rem;
    }
    .section-header{
        padding-bottom: 1rem;
    }
    .clear-cart{
        cursor: pointer;
        /* color: var(--white); */
    }
    .clear-cart:hover{
        color: var(--smoke);
    }
    .empty-message{
        /* color: var(--white); */
        font-style: italic;
    }
    .section-body{
        display: flex;
        transition: 0.2s;
    }
    .sb-inner{
        width: 70%;
        padding-right: 1rem;
        transition: 0.2s;
        color: var(--grey);
    }
    .sbi-card{
        /* border: 1px solid var(--softgrey); */
        border-radius: 5px;
        padding: 5%;
        margin-bottom: 1rem;
        /* background: var(--smoke); */
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
        transition: 0.2s;
        background: var(--white);
    }
    .sc-head{
        border-bottom: 1px solid var(--grey);
    }
    .sc-head h2{
        font-size: 1rem;
        margin: 0;
        margin-bottom: 0.5rem;
    }
    .sc-head-delete{
        cursor: pointer;
        color: var(--primary);
        transition: 00.2s;
        display: none;
    }
    .sc-head-delete:hover{
        color: var(--primary-hover);
    }
    .sc-body{
        /* display: flex; */
        width: 100%;
        padding-left: 2%;
    }
    .product-list{
        /* display: flex; */
        width: 100%;
        border-bottom: 1px solid var(--softgrey);
        padding: 5%;
    }
    .pl-body{
        display: flex;
        flex-wrap: wrap;
    }
    .sc-body-check{
        display: flex;
        width: 10%;
        justify-content: left;
        align-items: center;
        transition: 0.2s;
        /* border: 1px solid green; */
    }
    .sbc-icon{
        width: 37px;
        height: 37px;
        border: 2px solid var(--softgrey);
        border-radius: 2px;
        cursor: pointer;
        transition: 0.2s;
        user-select: none;
    }
    .sbc-icon:hover{
        background: var(--smoke);
    }
    .sbc-icon img{
        width: 100%;
    }
    .sc-body-image{
        width: 40%;
        padding: 1%;
        transition: 0.2s;
    }
    .sc-body-image img{
        width: 100%;
        border-radius: 5px;
    }
    .sc-body-description{
        width: 50%;
        transition: 0.2s;
        /* border: 1px solid green; */
        padding-left: 1rem;
    }
    .sc-body-description h3{
        margin: 0;
        font-size: 1rem;
    }
    .sc-body-qty{
        margin-top: 1rem;
        color: blue;
    }
    .sbq-unit{
        padding-left: 0.5rem;
    }
    .sc-body-price{
        padding-top: 0.5rem;
        color: brown;
    }
    .pl-footer{
        display: flex;
        flex-wrap: wrap;
    }
    .pf-text{
        width: 60%;
        transition: 0.2s;
        /* border: 1px solid green; */
    }
    .pf-buttons{
        display: flex;
        width: 40%;
        transition: 0.2s;
    }
    .pb-delete{
        width: 25%;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: 0.2s;
        color: var(--primary);
    }
    .pb-delete:hover{
        color: var(--primary-hover);
    }
    .pb-input{
        display: flex;
        width: 75%;
        justify-content: center;
        text-align: center;
    }
    .pb-input-button{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25%;
        border: 1px solid var(--softgrey);
        border-radius: 5px;
        cursor: pointer;
        user-select: none;
    }
    .pb-input-input{
        width: 50%;
        padding: 1%;
        padding-left: 5%;
        padding-right: 5%;
    }
    .pb-input-input input{
        border: none;
        border-bottom: 1px solid var(--softgrey);
        height: 100%;
        width: 100%;
        text-align: center;
        outline: none;
        font-size: 1rem;
    }
    .pb-input-input input::-webkit-outer-spin-button,
    .pb-input-input input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    .pb-input-input input[type=number] {
        -moz-appearance: textfield;
    }
    .sb-button{
        position: relative;
        /* display: flex; */
        width: 30%;
        /* min-height: 200px; */
        /* border: 1px solid var(--softgrey); */
        /* padding-left: 1rem; */
        /* justify-content: right; */
        transition: 0.2s;
        color: var(--grey);
    }
    .sbb-inner{
        /* border: 1px solid var(--softgrey); */
        border-radius: 5px;
        min-height: 100px;
        /* width: 100%; */
        /* top: 4.81rem; */
        /* transition: 0.2s; */
        z-index: 5;
        background: var(--white);
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
    }
    .cart-summary{
        padding: 1rem;
    }
    .cs-inner h2{
        margin: 0;
        /* margin-top: 0.5rem; */
        margin-bottom: 0.5rem;
        font-size: 1rem;
    }
    .cs-row{
        display: flex;
        flex-wrap: nowrap;
        padding: 1% 0 1% 0;
    }
    .cs-row-param{
        width: 50%;
    }
    .cs-row-value{
        width: 50%;
    }
    .buy-button{
        position: relative;
        width: 100%;
        height: 50px;
        /* padding: 1%;
        padding-top: 2rem; */
        /* border: 1px solid green; */
    }
    .buy-button span{
        position: absolute;
        left: 1rem;
        bottom: 1rem;
        padding: 1%;
        padding-left: 2rem;
        padding-right: 2rem;
        background: var(--primary);
        color: var(--white);
        border-radius: 2px;
        cursor: pointer;
        user-select: none;
    }
    .cart-warning{
        color: red;
        padding: 0 1rem 0 1rem;
    }
    .buy-button-false span{
        background: var(--softgrey);
        color: var(--grey);
        cursor: not-allowed;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    @media only screen and (max-width: 1100px){
        .sc-body-check{
            width: 10%;
        }
        .sc-body-image{
            width: 27%;
        }
        .sc-body-description{
            width: 63%;
        }
    }
    @media only screen and (max-width: 1050px){
        .section-body{
            display: inline;
        }
        .sb-inner{
            width: 100%;
            padding-right: 0;
        }
        .sb-button{
            width: 100%;
            min-height: 310px;
        }
        .sbb-inner{
            border: 1px solid var(--softgrey);
            border-radius: 0;
            box-shadow: unset;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding-left: 2.5%;
            padding-right: 2.5%;
            color: var(--grey);
        }
        .sc-body-check{
            width: 10%;
        }
        .sc-body-image{
            width: 45%;
        }
        .sc-body-description{
            width: 45%;
        }
        .sbi-card{
            /* box-shadow: unset; */
        }
        .pf-text{
            width: 100%;
        }
        .pf-buttons{
            width: 100%;
        }
    }
    @media only screen and (max-width: 370px){
        .sc-body-check{
            width: 10%;
        }
        .sc-body-image{
            width: 90%;
        }
        .sc-body-description{
            width: 100%;
            padding-left: 0;
        }
    }
</style>