<template>
    <div class="sbi-tab-inner">
        <div class="sti-head">
            <div class="sti-head-button shb-active" id="shb-chat-list" @click="showTab('sti-head-button', 'shb-chat-list', 'sti-body-part', 'sbp-chat', 'shb-active', 'sbp-active');">
                <span>Chat List</span>
            </div>
            <div class="sti-head-button" id="shb-reservations" @click="showTab('sti-head-button', 'shb-reservations', 'sti-body-part', 'sbp-reservations', 'shb-active', 'sbp-active');">
                <span>Reservations</span>
            </div>
        </div>
        <div class="sti-body">
            <div class="sti-body-part sbp-active" id="sbp-chat">
                <div class="sbp-chat-head">
                    <div class="sch-inner">
                        <div class="sch-button sch-button-active" id="sch-button-active" @click="showTab('sch-button', 'sch-button-active', 'scb-part', 'scb-part-active', 'sch-button-active', 'sp-active');">
                            <span>Active</span>
                        </div>
                        <div class="sch-button" id="sch-button-paid" @click="showTab('sch-button', 'sch-button-paid', 'scb-part', 'scb-part-paid', 'sch-button-active', 'sp-active');">
                            <span>Paid</span>
                        </div>
                        <div class="sch-button" id="sch-button-done" @click="showTab('sch-button', 'sch-button-done', 'scb-part', 'scb-part-done', 'sch-button-active', 'sp-active');">
                            <span>Done</span>
                        </div>
                    </div>
                </div>
                <div class="sbp-chat-body">
                    <div class="scb-part sp-active" id="scb-part-active">
                        <!-- {{ activeChats }} -->
                        <template v-if="activeChats">
                            <template v-if="activeChats.length > 0">
                                <div class="scb-part-inner" v-for="chat in activeChats" :key="chat">
                                    <div class="spi-list spi-list-active">
                                        <div class="spi-list-detail">
                                            <span>{{ chat.user.name }}</span>
                                        </div>
                                        <div class="spi-list-option">
                                            <div class="slo-part">
                                                <span @click="doctorActiveChats(chat.room.name);">Buka</span>
                                            </div>
                                            <div class="slo-part">
                                                <span @click="endChat(chat.transaction.name);">Done</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <p>Tidak ada chat yang aktif</p>
                            </template>
                        </template>
                    </div>
                    <div class="scb-part" id="scb-part-paid">
                        <!-- {{ paidChats }} -->
                        <template v-if="paidChats">
                            <template v-if="paidChats.length > 0">
                                <div class="scb-part-inner">
                                    <div class="spi-list" v-for="chat in paidChats" :key="chat">
                                        <div class="spi-list-detail">
                                            <span>{{ chat.user.name }}</span>
                                        </div>
                                        <div class="spi-list-option">
                                            <div class="slo-part">
                                                <span @click="startChat(chat.transaction.name);">Start</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <p>Belum ada Paid Chat</p>
                            </template>
                        </template>
                        <!-- <div class="scb-part-inner">
                            <div class="spi-list">
                                <div class="spi-list-detail">
                                    <span>Sergei Brin</span>
                                </div>
                                <div class="spi-list-option">
                                    <span>Start</span>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div class="scb-part" id="scb-part-done">
                        <!-- {{ doneChats }} -->
                        <template v-if="doneChats">
                            <template v-if="doneChats.length > 0">
                                <div class="scb-part-inner" v-for="chat in doneChats" :key="chat">
                                    <div class="spi-list spi-list-active">
                                        <div class="spi-list-detail">
                                            <span>{{ chat.user.name }}</span>
                                        </div>
                                        <div class="spi-list-option">
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <p>Tidak ada Done Chat</p>
                            </template>
                        </template>
                    </div>
                </div>
            </div>
            <div class="sti-body-part" id="sbp-reservations">
                <template v-if="reservations">
                    <div class="scb-part-inner" v-for="reservation in reservations" :key="reservation">
                        <div class="spi-list spi-list-active">
                            <div class="spi-list-detail">
                                <span>{{ reservation.facility.name }}</span>
                            </div>
                            <!-- <div class="spi-list-option">
                                <div class="slo-part">
                                    <span @click="doctorActiveChats(chat.room.name);">Buka</span>
                                </div>
                                <div class="slo-part">
                                    <span @click="endChat(chat.transaction.name);">Done</span>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </template>
                <template v-else>
                    <p>Tidak ada reservasi</p>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed } from "@vue/runtime-core";
    import { useAccountDoctorStore } from '@/stores/accountDoctorStore';
    import { showTab } from '@/functions/general';
    import { doctorActiveChats } from '@/functions/chat';
    const activeChats = computed(() => useAccountDoctorStore().activeChats);
    const paidChats = computed(() => useAccountDoctorStore().paidChats);
    const doneChats = computed(() => useAccountDoctorStore().doneChats);
    const reservations = computed(() => useAccountDoctorStore().reservations);
    useAccountDoctorStore().setupChat();
    useAccountDoctorStore().setReservation();
    function startChat(tx){
        useAccountDoctorStore().startChat(tx);
    }
    function endChat(tx){
        useAccountDoctorStore().endChat(tx);
    }
</script>

<style scoped>
    .sbi-tab-inner{
        position: relative;
        padding: 2rem;
        border: 1px solid var(--softgrey);
        border-radius: 5px;
        transition: 0.2s;
        overflow: hidden;
    }
    .sti-head{
        display: flex;
        width: 100%;
        border-bottom: 1px solid var(--softgrey);
    }
    .sti-head-button{
        width: 50%;
        cursor: pointer;
        user-select: none;
        color: var(--grey);
        border-bottom: 2px solid var(--white);
        text-align: center;
        padding: 0.5rem;
    }
    .shb-active{
        border-bottom: 2px solid var(--primary);
    }
    .sti-body{
        display: flex;
        width: 100%;
        overflow: hidden;
    }
    .sti-body-part{
        min-width: 100%;
        width: 100%;
        display: none;
    }
    .sbp-active{
        display:  block;
        padding-top: 1rem;
    }
    .sch-inner{
        display: flex;
        width: 100%;
    }
    .sch-button{
        width: 33.33%;
        cursor: pointer;
        user-select: none;
        color: var(--grey);
        text-align: left;
        padding: 0.5rem;
        padding-left: 0;
        transition: 0.2s;
    }
    .sch-button:hover{
        /* background: var(--smoke); */
        color: var(--primary-hover);
    }
    .sch-button-active{
        color: var(--primary);
    }
    .sbp-chat-body{
        display: flex;
        width: 100%;
        overflow: hidden;
    }
    .scb-part{
        min-width: 100%;
        display: none;
    }
    .scb-part-inner{
        padding-top: 0.5rem;
    }
    .sp-active{
        display: block;
    }
    .spi-list{
        display: flex;
        border: 1px solid var(--softgrey);
        border-radius: 5px;
        align-items: center;
        padding: 0.5rem;
        color: var(--grey);
        margin-bottom: 1rem;
        height: 50px;
    }
    /* .spi-list-active{
        cursor: pointer;
    } */
    /* .spi-list-active:hover{
        background: var(--smoke);
    } */
    .spi-list-detail{
        width: 75%;
    }
    .spi-list-option{
        width: 25%;
        display: flex;
        /* border: 1px solid green; */
    }
    .slo-part{
        width: 50%;
    }
    .slo-part span{
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        color: var(--white);
        background: var(--primary);
        border-radius: 5px;
        cursor: pointer;
        user-select: none;
        transition: 0.2s;
        cursor: pointer;
    }
    .slo-part span:hover{
        background: var(--primary-hover);
    }
    @media only screen and (max-width: 750px){
        .sbi-tab-inner{
            padding: 0.5rem;
        }
    }
    @media only screen and (max-width: 450px){
        .sbi-tab-inner{
            border: none;
            border-radius: 0;
        }
    }
    @media only screen and (max-width: 310px){
        .sbi-tab-inner{
            padding: 0.5rem;
        }
    }
</style>