import { useServiceStore } from "@/stores/service";
import { useTransactionStore } from "@/stores/transaction";
import { showNotification } from "./general";
// import { useDoctorStore } from "@/stores/doctor";
import router from "@/router";
export function scheduleToggle(bticon, option){
    const ic = document.getElementById(bticon);
    const op = document.getElementById(option);
    if(op.style.display == 'block'){
        ic.children[0].style.transform = 'rotate(0)';
        op.style.display = 'none';
    }else{
        ic.children[0].style.transform = 'rotate(180deg)';
        op.style.display = 'block';
    }
}
export function showSop(id){
    const sp = document.getElementsByClassName('srv-option-part');
    for(var i = 0; i < sp.length; i++){
        sp[i].classList.remove('sop-active');
    }
    document.getElementById(id).classList.add('sop-active');
}
export function setSchedules(day, date, title){
    useServiceStore().setServiceSchedules(day, date, title);
}
export function selectSchedule(id, service, schedule){
    const bt = document.getElementById(id);
    const ic = document.getElementById('srv-icon-service');
    useServiceStore().selectSchedule(schedule);
    bt.parentElement.parentElement.parentElement.parentElement.style.display = 'none';
    bt.parentElement.parentElement.parentElement.parentElement.previousElementSibling.children[0].innerHTML = bt.innerHTML;
    ic.children[0].style.transform = 'rotate(0)';
    document.getElementById('srv-input-service').value = service;
}
export function orderService(detail){
    const scinput = document.getElementById('srv-input-schedule');
    if(scinput.value){
        const serviceSchedules = useServiceStore().serviceSchedules;
        const schedule = {id: scinput.value, date: serviceSchedules.head.date_format, date_text: serviceSchedules.head.date_text, payment: null};
        useTransactionStore().setDirectOrder('service', 'health_facility', {id: document.getElementById('srv-input-service').value, schedule: schedule, detail: detail});
        useTransactionStore().setDirectOrderSchedule(schedule);
        // router.push('/direct-order/service/confirmation');
        router.push('/order/service/confirmation');
    }else{
        showNotification('', 'Pilih Jadwal dahulu', 2000, 'orange');
    }
}
export function paymentCheck(method, service){ // service = service.id
    const payment = useTransactionStore().payment;
    for(var i = 0; i < payment.length; i++){
        for(var j = 0; j < payment[i].service.length; j++){
            payment[i].service[j].checked = 0;
        }
    }
    for(i = 0; i < payment.length; i++){
        if(payment[i].id == method){
            for(j = 0; j < payment[i].service.length; j++){
                if(payment[i].service[j].id == service){
                    payment[i].service[j].checked = 1;
                }
            }
        }
    }
    useTransactionStore().directOrderSchedule.payment = service;
}
export function paymentUncheck(){
    const payment = useTransactionStore().payment;
    for(var i = 0; i < payment.length; i++){
        for(var j = 0; j < payment[i].service.length; j++){
            payment[i].service[j].checked = 0;
        }
    }
    useTransactionStore().directOrderSchedule.payment = null;
}
export function checkOut(order){
    // if(order.name == 'reservation'){
    //     useTransactionStore().directOrderService = useDoctorStore().selectSchedule;
    // }
    useTransactionStore().directCheckout(order);
}
export function inputSelectCategory(e){
    useServiceStore().inputSelectCategory(e.srcElement.value);
    if(e.srcElement.value){
        e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'flex';
    }else{
        useServiceStore().searchbarSelectCategory.input.value = '';
        e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'none';
    }
}
export function inputSpecialist(e){
    useServiceStore().inputSpecialist(e.srcElement.value);
    if(e.srcElement.value){
        e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'flex';
    }else{
        e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'none';
    }
}
export function selectCategory(specialist, inputid){
    useServiceStore().selectCategory(specialist);
    const input = document.getElementById(inputid);
    input.value = null;
    useServiceStore().searchbarSelectCategory.input.value = '';
    input.parentElement.nextElementSibling.children[0].style.display = 'none';
}
export function clearInputCategory(){
    const input = document.getElementById('sii-input-specialist');
    input.value = null;
    useServiceStore().searchbarCategory.input = {value: null};
    useServiceStore().inputSpecialist('');
    input.parentElement.nextElementSibling.children[0].style.display = 'none';
}
export function clearInputSelectCategory(){
    const input = document.getElementById('input-select-svcategory');
    input.value = '';
    useServiceStore().searchbarSelectCategory.input.value = '';
    useServiceStore().inputSelectCategory('');
    useServiceStore().searchServiceAndCategory();
    input.parentElement.nextElementSibling.children[0].style.display = 'none';
}