export function mainDescription(){
    const navbar = document.querySelector('.navbar');
    const footer = document.querySelector('footer');
    const content = document.getElementById('cd-content');
    if(content.classList.contains('cd-content-active')){
        navbar.classList.remove('element-hidden');
        footer.classList.remove('element-hidden');
        content.classList.remove('cd-content-active');
        document.getElementsByTagName('html')[0].style.overflow = 'auto';
    }else{
        navbar.classList.add('element-hidden');
        footer.classList.add('element-hidden');
        content.classList.add('cd-content-active');
        document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    }
}
export function cmcIndex(){
    const index = document.getElementById('cmc-index');
    const shadow = document.getElementById('cmc-index-shadow');
    if(index.classList.contains('cmc-index-active')){
        index.classList.remove('cmc-index-active');
        shadow.classList.remove('cis-active');
    }else{
        index.classList.add('cmc-index-active');
        shadow.classList.add('cis-active');
    }
}