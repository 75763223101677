<template>
    <div class="content">
        <TopBackgroundComponent/>
        <WIshListComponent/>
    </div>
</template>
<script setup>
    import TopBackgroundComponent from '../components/TopBackgroundComponent.vue';
    import WIshListComponent from '@/components/order/WIshListComponent.vue';
    window.scrollTo(0, 0);
</script>
<style scoped>
    /*  */
</style>
