<template>
    <section>
        <div class="section-header">
            <h2>Spesialis {{ specialistActive }}</h2>
        </div>
        <div class="section-body">
            <div class="sb-inner" v-if="doctors">
                <template v-if="doctors.length > 0">
                    <div class="sb-card" v-for="(doctor) in doctors" :key="doctor?.id">
                        <router-link :to="('/dokter/' + useRoute().params.specialist + '/' + doctor.slug)">
                            <div class="sbc-content">
                                <div class="sbc-label">
                                    <span>Spesialis {{ specialistActive }}</span>
                                </div>
                                <div class="sbc-image">
                                    <img :src="doctor.image[0].image" alt="melinda care" v-if="doctor.image[0]?.image"/>
                                    <img src="../../assets/images/pictures/emptypp.webp" alt="melinda care" v-else/>
                                </div>
                                <div class="sbc-detail">
                                    <h3>{{ doctor.name }}</h3>
                                    <div class="sd-stats">
                                        <div class="sd-stats-row">
                                            <div class="sd-stats-param">Almamater</div>
                                            <div class="sd-stats-value">-</div>
                                        </div>
                                        <div class="sd-stats-row">
                                            <div class="sd-stats-param">Tempat Praktik</div>
                                            <div class="sd-stats-value">-</div>
                                        </div>
                                        <p class="sd-stats-tip">Klik untuk informasi selengkapnya</p>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </template>
                <div class="search-not-found" v-else>
                    <p>Tidak ditemukan Dokter pada spesialisasi ini</p>
                </div>
            </div>
            <DoctorListSkeleton v-else/>
        </div>
    </section>
</template>
<script setup>
    import DoctorListSkeleton from '../skeletons/DoctorListSkeleton.vue';
    import { useRoute } from 'vue-router';
    import { computed } from "@vue/runtime-core";
    import { useDoctorStore } from "../../stores/doctor";
    const specialistActive = computed(() => useDoctorStore().specialistActive);
    const doctors = computed(() => useDoctorStore().doctorListByCategoryAndHealthFacility);
    useDoctorStore().setDlbcahf(useRoute().params.specialist, useRoute().params.hf);
</script>
<style scoped>
    section{
        padding-left: 9%;
        padding-right: 9%;
        padding-bottom: 2.5%;
        width: 100%;
        transition: 0.2s;
        color: #575757;
    }
    .section-header{
        padding: 1%;
        padding-top: 0;
    }
    .section-header h2{
        font-family: poppins;
        color: var(--white);
        margin: 0;
    }
    .section-body{
        width: 100%;
    }
    .sb-inner{
        display: flex;
        flex-wrap: wrap;
    }
    .sb-card{
        position: relative;
        min-width: 290px;
        width: 25%;
        padding: 1%;
        padding-top: 1rem;
        transition: 0.2s;
        user-select: none;
    }
    .sb-card a{
        text-decoration: none;
        color: #575757;
    }
    .sbc-label{
        position: absolute;
        top: 0;
        left: 0;
    }
    .sbc-label span{
        padding: 5%;
        padding-top: 10%;
        padding-left: 10px;
        padding-right: 10px;
        background: rgb(207, 195, 19);
        color: var(--white);
        border-radius: 0 0 15px 0;
    }
    .sbc-content{
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        height: 450px;
        transition: 0.2s;
        background: var(--white);
        /* background-image: conic-gradient(at 60% -50%, rgb(255, 255, 255), rgb(124, 124, 124), rgb(255, 255, 255)); */
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .sbc-content:hover{
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.37), 0 6px 20px 0 rgba(0, 0, 0, 0.37);
    }
    .sbc-image{
        width: 100%;
        min-height: 150px;
        height: 41%;
        overflow: hidden;
        background: lightgrey;
    }
    .sbc-image img{
        width: 100%;
    }
    .sbc-detail{
        padding: 2.5%;
    }
    .sd-stats{
        border-top: 1px solid var(--smoke);
        padding-top: 1rem;
    }
    .sd-stats-row{
        display: flex;
        flex-wrap: nowrap;
    }
    .sd-stats-param{
        width: 45%;
    }
    .sd-stats-value{
        width: 55%;
        padding-left: 1rem;
    }
    .sd-stats-tip{
        font-size: 0.9rem;
        font-style: italic;
        color: var(--softgrey);
        margin: 0;
        margin-top: 1rem;
    }
    .sbc-detail h3{
        font-family: poppins;
        text-align: left;
        color: #575757;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin: 0;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
    .search-not-found{
        font-family: Arial, Helvetica, sans-serif;
        font-style: italic;
        text-align: center;
        color: var(--grey);
    }
    @media only screen and (max-width: 1500px){
        section{
            padding-left: 4%;
            padding-right: 4%;
        }
    }
    @media only screen and (max-width: 1415px){
        .sb-card{
            width: 33.33%;
        }
    }
    @media only screen and (max-width: 950px){
        .sb-card{
            width: 50%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding-left: 1%;
            padding-right: 1%;
        }
        .section-header h2{
            color: var(--grey);
        }
    }
    @media only screen and (max-width: 650px){
        .sb-card{
            min-width: 250px;
        }
    }
    @media only screen and (max-width: 550px){
        .sb-card{
            width: 100%;
        }
    }
</style>