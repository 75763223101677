import { defineStore } from "pinia";
import axiosClient from "../axios.js";
import { getCookie } from "@/functions/general.js";
import { showNotification, hideModal } from "@/functions/general.js";
import { useTransactionStore } from '@/stores/transaction';

export const useAddressStore = defineStore("address", {
    state: () => ({
        // ==================== states used on address and its relation
        selectedAddress: null,
        addresses: null,
        provinces: null,
        cities: null,
        districts: null,
        subDistricts: null,
        addressLoader: null
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on address and its relation
        async setSelectedAddress(){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            const response = await axiosClient.get(`user/address/selected`);
            this.selectedAddress = response.data.data;
            // console.log(this.selectedAddress);
        },
        async setAddresses(){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            const response = await axiosClient.get(`user/address/me`);
            this.addresses = response.data;
            // console.log(this.addresses);
        },
        async setProvinces(){
            const response = await axiosClient.get(`region/province`);
            this.provinces = response.data;
        },
        async setCities(province){
            const response = await axiosClient.get(`region/province/` + province);
            this.cities = response.data;
            // console.log(this.cities);
        },
        async setDistricts(city){
            const response = await axiosClient.get(`region/city/` + city);
            this.districts = response.data;
            // console.log(this.districts);
        },
        async setSubDistricts(district){
            const response = await axiosClient.get(`region/district/` + district);
            this.subDistricts = response.data;
        },
        async submitNew(params){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            this.addressLoader = 1;
            try{
                await axiosClient.post(`user/address`, params);
                // const response = await axiosClient.post(`user/address`, params);
                const inputs = document.getElementsByClassName('new-address-input');
                for(var i = 0; i < inputs.length; i++){
                    inputs[i].value = '';
                }
                this.clearCities();
                this.clearDistricts();
                this.clearSubDistricts();
                this.setAddresses();
                this.setSelectedAddress();
                showNotification('', 'Tambah Alamat berhasil', 2000, null);
                this.addressLoader = null;
                // console.log(response);
            }catch(error){
                this.addressLoader = null;
                console.log(error);
            }
        },
        async setCitiesEdit(address, province){
            for(var i = 0; i < this.addresses.length; i++){
                if(this.addresses[i].id == address){
                    const response = await axiosClient.get(`region/province/` + province);
                    this.addresses[i].edit_data = {cities: response.data, districts: null, subDistricts: null, subdist_selected: null};
                }
            }
            // console.log(this.addresses);
        },
        async setDistrictsEdit(address, city){
            for(var i = 0; i < this.addresses.length; i++){
                if(this.addresses[i].id == address){
                    const response = await axiosClient.get(`region/city/` + city);
                    this.addresses[i].edit_data.districts = response.data;
                }
            }
            // console.log(this.addresses);
        },
        async setSubDistrictsEdit(address, district){
            for(var i = 0; i < this.addresses.length; i++){
                if(this.addresses[i].id == address){
                    const response = await axiosClient.get(`region/district/` + district);
                    this.addresses[i].edit_data.subDistricts = response.data;
                }
            }
            // console.log(this.addresses);
        },
        async deleteAddress(address){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            this.addressLoader = 1;
            try{
                const response = await axiosClient.delete(`user/address/delete/` + address);
                console.log(response);
                this.setAddresses();
                setTimeout(function(){
                    showNotification('', 'Hapus Alamat berhasil', 2000, null);
                }, 500);
                this.addressLoader = null;
            }catch(error){
                this.addressLoader = null;
                console.log(error);
            }
        },
        async updateAddress(params){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            this.addressLoader = 1;
            try{
                await axiosClient.put(`user/address/update/` + params.address, JSON.stringify(params.input));
                // const response = await axiosClient.put(`user/address/update/` + params.address, JSON.stringify(params.input));
                this.setAddresses();
                this.setSelectedAddress();
                setTimeout(function(){
                    showNotification('', 'Ubah Alamat berhasil', 2000, null);
                }, 500);
                this.addressLoader = null;
                // console.log(response);
            }catch(error){
                this.addressLoader = null;
                console.log(error);
            }
        },
        async setMainAddress(address){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            this.addressLoader = 1;
            try{
                await axiosClient.put(`user/address/select/` + address, JSON.stringify({main: true}));
                // const response = await axiosClient.put(`user/address/select/` + address, JSON.stringify({selected: true}));
                this.setAddresses();
                this.setSelectedAddress();
                setTimeout(function(){
                    showNotification('', 'Alamat Utama dipilih', 2000, null);
                }, 500);
                this.addressLoader = null;
                // console.log(response);
            }catch(error){
                this.addressLoader = null;
                console.log(error);
            }
        },
        async selectAddress(address, type){
            window.sessionStorage.setItem('authToken', getCookie('session'));
            this.addressLoader = 1;
            try{
                await axiosClient.put(`user/address/select/` + address, JSON.stringify({selected: true}));
                // const response = await axiosClient.put(`user/address/select/` + address, JSON.stringify({selected: true}));
                this.setAddresses();
                this.setSelectedAddress();
                setTimeout(function(){
                    showNotification('', 'Alamat Baru dipilih', 2000, null);
                }, 500);
                if(type == 'cart'){
                    useTransactionStore().getConfirmCart();
                }
                hideModal('sa-modal');
                this.addressLoader = null;
                // console.log(response);
            }catch(error){
                this.addressLoader = null;
                console.log(error);
            }
        },
        clearProvinces(){
            this.provinces = null;
        },
        clearCities(){
            this.cities = null;
        },
        clearDistricts(){
            this.districts = null;
        },
        clearSubDistricts(){
            this.subDistricts = null;
        }
    }
});