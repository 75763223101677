<template>
    <section>
        <!-- <div class="section-bg"></div> -->
        <div class="section-content" v-if="serviceDetail">
            <div class="sc-inner">
                <div class="sci-left">
                    <div class="sl-head">
                        <!-- <p class="small-title">Melinda Care</p> -->
                        <h1 class="main-title">{{ serviceDetail.name}}</h1>
                        <p class="p-date">{{ serviceDetail.health_facility }}</p>
                    </div>
                    <div class="sl-body">
                        <div class="sl-img" v-if="serviceDetail.thumbnail">
                            <img :src="serviceDetail.thumbnail_url" alt="melinda care"/>
                        </div>
                        <div class="sl-img sl-img-empty" v-else>
                            <img src="../../assets/images/pictures/emptyphoto.jpg" alt="empty picture"/>
                        </div>
                        <div class="sl-content">
                            <div v-html="serviceDetail.content"></div>
                        </div>
                    </div>
                    <!-- <div class="sc-share">
                        <h2>Share to</h2>
                        <div class="sc-share-inner">
                            <div class="scs-part">
                                <a href="https://www.facebook.com" target="_blank">
                                    <img src="../../assets/images/icons/cc-facebook.png" alt="food traveller">
                                </a>
                            </div>
                            <div class="scs-part">
                                <a href="https://www.twitter.com" target="_blank">
                                    <img src="../../assets/images/icons/cc-twitter.png" alt="food traveller">
                                </a>
                            </div>
                            <div class="scs-part">
                                <a href="https://www.linkedin.com" target="_blank">
                                    <img src="../../assets/images/icons/cc-linkedin.png" alt="food traveller">
                                </a>
                            </div>
                            <div class="scs-part">
                                <a href="https://www.instagram.com" target="_blank">
                                    <img src="../../assets/images/icons/cc-instagram.png" alt="food traveller">
                                </a>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="sci-right">
                    <!-- <div class="sr-order">
                        <div class="sr-order-head">
                            <div class="soh-panel-button" @click="soToggle('sr-order-body');">Sembunyikan</div>
                        </div>
                        <div class="sr-order-body mhresize" id="sr-order-body">
                            <div class="sr-order-body-inner">
                                <div class="so-row">
                                    <div class="so-row-param">Harga</div>
                                    <div class="so-row-value">Rp{{ number_format(serviceDetail.price, 0, ',', '.') }}</div>
                                </div>
                                <div class="so-row">
                                    <div class="so-row-param">Jadwal</div>
                                    <div class="so-row-value">
                                        <input type="hidden" id="srv-input-service" :value="serviceDetail.id" />
                                        <input type="hidden" id="srv-input-schedule" :value="selectedSchedule" />
                                        <div class="srv-select" v-if="serviceDetail.schedules">
                                            <div class="srv-button trigger" @click="scheduleToggle('srv-icon-service', 'srv-option-service');">
                                                <div class="srv-button-text trigger">
                                                    <div class="sbt-price trigger" style="width: 100%;">
                                                        <span class="med-price trigger">Pilih Jadwal</span>
                                                    </div>
                                                    <div class="sbt-unit trigger" style="width: 0;">
                                                        <span class="trigger"></span>
                                                    </div>
                                                </div>
                                                <div class="srv-button-icon trigger icon-flstage" id="srv-icon-service">
                                                    <img src="../../assets/images/icons/arrow-1.png" alt="melinda care" />
                                                </div>
                                            </div>
                                            <div class="srv-option float-stage" id="srv-option-service">
                                                <div class="srv-option-part sop-calendar sop-active" id="sop-calendar" v-if="serviceCalendar">
                                                    <div class="service-calendar-head">
                                                        <div class="sch-button" @click="prevMonth(serviceCalendar.head.data.month.index, serviceCalendar.head.data.year.count, 'service');">
                                                            <span>{{ '<' }}</span>
                                                        </div>
                                                        <div class="sch-title">
                                                            <h3>{{ serviceCalendar.head.title }}</h3>
                                                        </div>
                                                        <div class="sch-button" @click="nextMonth(serviceCalendar.head.data.month.index, serviceCalendar.head.data.year.count, 'service');">
                                                            <span>{{ '>' }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="service-calendar-body">
                                                        <div class="scb-inner">
                                                            <div class="scb-day" v-for="day in serviceCalendar.body.days" :key="day">
                                                                <div class="scb-day-name">
                                                                    <span>{{ day.name }}</span>
                                                                </div>
                                                                <div class="scb-day-date">
                                                                    <div class="sdd-num" v-for="dt in day.date" :key="dt">
                                                                        <div class="sdd-num-inner" v-if="dt.time >= serviceCalendar.head.data.today.time && dt.time < (serviceCalendar.head.data.today.time + maxAvailableService)">
                                                                            <div class="sni-inner sni-true" v-if="dt.num > 0" @click="showSop('sop-schedule'); setSchedules(day.id, dt.num, serviceCalendar.head.title);">
                                                                                <div class="sni-core sni-core-active" v-if="dt.time == serviceCalendar.head.data.today.time">
                                                                                    <span>{{ dt.num }}</span>
                                                                                </div>
                                                                                <div class="sni-core" v-else>
                                                                                    <span>{{ dt.num }}</span>
                                                                                </div>
                                                                                <div class="sni-available" v-if="day.schedule.available"></div>
                                                                            </div>
                                                                            <div class="sni-inner" v-else>
                                                                                <span></span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="sni-inner" v-else>
                                                                            <div class="sni-inner" v-if="dt.num > 0">
                                                                                <div class="sni-core-outofrange">
                                                                                    <span>{{ dt.num }}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="sni-inner" v-else>
                                                                                <div class="sni-core-outofrange">
                                                                                    <span></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="srv-option-part" id="sop-schedule">
                                                    <div class="sop-schedule-head" v-if="serviceSchedules">
                                                        <div class="ssh-button">
                                                            <span class="ssh-button-btn" @click="showSop('sop-calendar');">{{ '<' }}</span>
                                                        </div>
                                                        <div class="ssh-title">
                                                            <span class="ssh-title-btn" @click="showSop('sop-calendar');">{{ serviceSchedules.head.day + ' ' + serviceSchedules.head.title }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="sop-schedule-body" v-if="serviceSchedules">
                                                        <div class="ssb-inner" v-if="serviceSchedules.body.schedules.length > 0">
                                                            <div class="srv-option-button" :id="'sob-' + index" v-for="(schedule, index) in serviceSchedules.body.schedules" :key="schedule" @click="selectSchedule('sob-' + index, serviceDetail.id, schedule.id);">
                                                                <div class="sbt-price trigger">
                                                                    <span class="med-price trigger">{{ serviceSchedules.head.day + ' ' + serviceSchedules.head.title }}</span>
                                                                </div>
                                                                <div class="sbt-unit trigger">
                                                                    <span class="trigger">{{ schedule.start_hour }} - {{ schedule.end_hour }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="ssb-inner ssb-inner-false" v-else>
                                                            <p>Tidak ada jadwal pada tanggal ini</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="so-cart">
                                    <div class="so-cart-buy">
                                        <button class="buy-button" @click="orderService(serviceDetail)">Reservasi</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="sr-part sr-categories" v-if="serviceCategories">
                        <h2>Categories</h2>
                        <div class="sr-link" v-for="category in serviceCategories" :key="category">
                            <router-link :to="'/layanan'" @click="setAutoSelectCategoryData(category);">
                                <h3>{{ category.name }}</h3>
                            </router-link>
                        </div>
                    </div>
                    <!-- <div class="sr-part sr-tag">
                        <h2>Tags</h2>
                        <div class="sr-tag-inner">
                            <router-link to="">Kesehatan</router-link>
                            <router-link to="">Obat</router-link>
                            <router-link to="">Penyakit</router-link>
                            <router-link to="">Nutrisi</router-link>
                            <router-link to="">Makanan</router-link>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <ServiceDetailSkeleton v-else/>
    </section>
</template>

<script setup>
    import ServiceDetailSkeleton from '@/components/skeletons/ServiceDetailSkeleton.vue';
    import { useRoute } from 'vue-router';
    import { computed } from "@vue/runtime-core";
    import { useCoeStore } from "../../stores/coe";
    // import { number_format } from '@/functions/general';
    import { setAutoSelectCategoryData } from '@/functions/coe';
    // import { prevMonth, nextMonth } from '@/functions/calendar';
    // import { maxAvailableService } from '@/equipments/settings';
    const date = new Date();
    const serviceDetail = computed(() => useCoeStore().serviceDetail);
    // const serviceCalendar = computed(() => useCoeStore().serviceCalendar);
    // const serviceSchedules = computed(() => useCoeStore().serviceSchedules);
    // const selectedSchedule = computed(() => useCoeStore().selectedSchedule);
    const serviceCategories = computed(() => useCoeStore().categories);
    useCoeStore().setServiceDetail(useRoute().params.slug, date.getMonth(), date.getFullYear());
    useCoeStore().setCategories();
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
</script>

<style scoped>
    section{
        position: relative;
        width: 100%;
        min-height: 15rem;
        padding-top: 0;
        padding-bottom: 2.5rem;
        color: #1B4674;
        transition: 0.2s;
    }
    /* .section-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 39rem;
        background: #FEF8F0;
        z-index: -1;
        transition: 0.2s;
    } */
    .small-title{
        /* color: #75C6EF; */
        font-weight: 600;
        font-size: 1.125rem;
        margin: 0;
    }
    .main-title{
        font-family: poppins;
        font-size: 3.25rem;
        font-weight: 700;
        margin-top: 1rem;
        margin-bottom: 1rem;
        transition: 0.2s;
        line-height: 1.2;
    }
    .p-date{
        margin: 0;
        margin-top: 1.6875rem;
        font-size: 1rem;
        font-weight: 400;
        width: 31.9375rem;
        transition: 0.2s;
    }
    .section-content{
        width: 100%;
        height: 100%;
        padding-left: 10%;
        padding-right: 10%;
        transition: 0.2s;
    }
    .sc-inner{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        /* padding-top: 2rem; */
        transition: 0.2s;
        color: var(--white);
    }
    .sci-left{
        width: 75%;
        transition: 0.2s;
    }
    .sl-body{
        padding-top: 2rem;
    }
    .sl-img{
        margin-bottom: 2rem;
    }
    .sl-img img{
        width: auto;
        width: 100%;
        transition: 0.2s;
    }
    .sl-img-empty{
        display: flex;
        align-items: center;
        max-height: 500px;
        overflow: hidden;
    }
    .sl-content{
        text-align: justify;
        color: var(--grey);
    }
    .sl-content img{
        max-width: 100%;
    }
    .sc-share{
        margin-top: 2rem;
    }
    .sc-share h2{
        font-family: poppins;
        font-size: 1rem;
        font-weight: 700;
    }
    .sc-share-inner{
        display: flex;
        flex-wrap: nowrap;
        padding-top: 1rem;
    }
    .scs-part{
        margin-right: 1rem;
    }
    .sci-right{
        width: 25%;
        padding-top: 370px;
        padding-left: 5rem;
        transition: 0.2s;
    }
    .sr-order{
        position: relative;
        width: 100%;
        background: var(--white);
        color: var(--grey);
        margin-bottom: 2rem;
        z-index: 5;
    }
    .sr-order-head{
        position: relative;
        min-height: 27px;
        z-index: 5;
    }
    .soh-panel-button{
        position: absolute;
        top: 0;
        right: 0;
        display: none;
        padding: 10px;
        padding-bottom: 0;
        background: var(--white);
        border-top: 1px solid var(--smoke);
        border-top-left-radius: 10px;
        z-index: 5;
        transition: 0.2s;
    }
    .sr-order-body{
        max-height: 500px;
        transition: max-height 0.2s ease-in-out;
        z-index: 10;
    }
    .so-row{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 1rem;
    }
    .so-row-param{
        width: 37%;
    }
    .so-row-value{
        width: 63%;
    }
    .srv-select{
        position: relative;
        user-select: none;
    }
    .srv-button{
        display: flex;
        cursor: pointer;
        padding: 0.5rem;
        border: 1px solid var(--softgrey);
        border-radius: 5px;
    }
    .srv-button-text{
        display: flex;
        width: 90%;
    }
    .srv-button-text .sbt-price{
        padding-left: 0.5rem;
    }
    .srv-button-text .sbt-unit{
        padding-left: 1rem;
    }
    .sbt-price{
        width: 50%;
    }
    .sbt-unit{
        width: 50%;
    }
    .srv-button-icon{
        width: 10%;
        text-align: center;
    }
    .srv-button-icon img{
        transition: 0.2s;
    }
    .srv-option{
        position: absolute;
        top: 115%;
        left: 0;
        width: 100%;
        border: 1px solid var(--softgrey);
        border-radius: 5px;
        background: var(--white);
        display: none;
        padding: 0.5rem;
    }
    .srv-option-part{
        display: none;
    }
    .sop-active{
        display: block;
    }
    .sop-schedule-head{
        display: flex;
        margin-bottom: 1rem;
    }
    .ssh-button{
        width: 15%;
    }
    .ssh-button-btn{
        padding: 0.5rem;
        cursor: pointer;
        user-select: none;
        font-size: 0.9rem;
    }
    .ssh-title{
        width: 85%;
        text-align: center;
    }
    .ssh-title-btn{
        padding: 0.5rem;
        cursor: pointer;
        user-select: none;
        font-size: 0.9rem;
    }
    .srv-option-button{
        display: flex;
        padding: 0.5rem;
        cursor: pointer;
        transition: 0.2s;
    }
    .srv-option-button:hover{
        background: var(--smoke);
    }
    .med-price{
        color: blue;
    }
    .ssb-inner-false{
        min-height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.9rem;
        font-style: italic;
        color: var(--softgrey);
    }
    .service-calendar-head{
        display: flex;
        text-align: center;
    }
    .sch-button{
        width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: no;
    }
    .sch-title{
        width: 80%;
    }
    .scb-day{
        width: 100%;
        display: flex;
    }
    .scb-day-name{
        width: 25%;
        padding: 0.5rem;
        padding-left: 0;
    }
    .scb-day-date{
        width: 75%;
        display: flex;
    }
    .sdd-num{
        display: flex;
        width: 16.5%;
        text-align: center;
        justify-content: center;
    }
    .sdd-num-inner{
        position: relative;
        width: 100%;
    }
    .sni-available{
        position: absolute;
        width: 10px;
        height: 10px;
        top: 5px;
        right: 5px;
        background: rgba(39, 108, 197, 0.438);
        border-radius: 10px;
    }
    .sni-true{
        cursor: pointer;
    }
    .sni-true:hover{
        background: var(--smoke);
    }
    .sni-core{
        padding: 0.5rem 0 0.5rem 0;
        color: var(--grey);
    }
    .sni-core-active{
        background: var(--primary);
        color: var(--white);
    }
    .sni-core-outofrange{
        padding: 0.5rem;
    }
    .so-cart-buy{
        width: 50%;
        display: flex;
        justify-content: left;
    }
    .buy-button{
        background: var(--primary);
        color: var(--white);
        padding: 0.5rem;
        min-width: 100px;
        border: none;
        cursor: pointer;
    }
    .sr-part h2{
        font-family: poppins;
        font-weight: 700;
        font-size: 1.25rem;
        color: #bbbbbb;
    }
    .sr-categories{
        width: 100%;
        transition: 0.2s;
        margin-bottom: 2rem;
    }
    .sr-link{
        margin-top: 1rem;
    }
    .sr-link a{
        color: #1B4674;
        text-decoration: none;
    }
    .sr-tag{
        width: 100%;
        transition: 0.2s;
        margin-bottom: 2rem;
    }
    .sr-tag-inner{
        display: flex;
        flex-wrap: wrap;
        padding-top: 1rem;
        width: 100%;
    }
    .sr-tag-inner a{
        font-size: 1rem;
        color: #575757;
        border: 1px solid #575757;
        border-radius: 50px;
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        text-decoration: none;
        margin-top: 1rem;
        margin-right: 1rem;
    }
    @media only screen and (max-width: 1500px){
        .section-content{
            padding-left: 5%;
            padding-right: 5%;
        }
        /* .section-bg{
            height: 31rem;
        } */
        .main-title{
            font-size: 2.6rem;
        }
        .p-date{
            margin-top: 1rem;
        }
        /* .sci-right{
            padding-top: 27rem;
        } */
    }
    @media only screen and (max-width: 1050px){
        .sc-inner{
            padding-top: 0;
        }
        .sci-left{
            width: 100%;
        }
        .sl-img img{
            width: 100%;
        }
        .main-title{
            font-size: 2rem;
        }
        .p-date{
            width: auto;
        }
        .sci-right{
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding-top: 2rem;
            padding-left: 0;
        }
        .sr-categories{
            width: 50%;
            margin-bottom: 0;
        }
        .sr-tag{
            width: 50%;
            margin-bottom: 0;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding-top: 1rem;
        }
        /* .section-bg{
            height: 19rem;
        } */
        .section-content{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .sc-inner{
            color: var(--grey);
        }
        .sci-right{
            padding-top: 1rem;
        }
        .so-row-param{
            width: 100%;
            margin-bottom: 0.5rem;
        }
        .so-row-value{
            width: 100%;
        }
        .sr-categories{
            width: 100%;
        }
        .sr-tag{
            width: 100%;
        }
    }
</style>