<template>
    <div class="content">
        <TopBackgroundComponent/>
        <CrumbComponent :data="crumbdata"/>
        <FindDoctorHeader/>
        <DoctorSpecialistByHealthFacilityComponent/>
        <!-- <ServicesComponent/>
        <ServiceListByCategory/> -->
    </div>
</template>
<script setup>
    import TopBackgroundComponent from '../components/TopBackgroundComponent.vue';
    import CrumbComponent from '@/components/CrumbComponent.vue';
    import FindDoctorHeader from '@/components/doctor/FindDoctorHeader.vue';
    import DoctorSpecialistByHealthFacilityComponent from '../components/doctor/DoctorSpecialistByHealthFacilityComponent.vue';
    const crumbdata = [
        {
            title: 'Home',
            type: 'link',
            to: '/'
        },
        {
            title: 'Cari Dokter',
            type: 'link',
            to: '/dokter'
        },
        {
            title: 'Dokter Spesialis',
            type: 'text'
        }
    ];
    window.scrollTo(0, 0);
    // import ServicesComponent from "../components/services/ServicesComponent.vue";
    // import ServiceListByCategory from "../components/services/ServiceListByCategory.vue";
</script>
<style scoped>
    /*  */
</style>