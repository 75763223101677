<template>
    <EventVerificationScannerComponent/>
</template>

<script setup>
    import EventVerificationScannerComponent from '@/components/event/EventVerificationScannerComponent.vue';
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>