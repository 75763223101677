import { defineStore } from "pinia";
import axiosClient from "../axios_one.js";

export const useShortenerStore = defineStore("shortener", {
    state: () => ({
        // ==================== states used on shortener page and its relation
        head: null,
        body: null,
        disclaimer: null
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on shortener page and its relation
        async getUrl(token){
            console.log(token);
            try{
                const response = await axiosClient.get(`link-alias/` + token);
                if(response.status == 200){
                    location.href = response.data.target;
                    // alert(response.data.target);
                    // console.log(response.data.target);
                }
                // console.log(response);
            }catch(error){
                location.href = process.env.BASE_URL + 'not-found';
                console.log(error);
            }
        },
    }
});
