import { useChatStore } from '@/stores/chat';
import router from '@/router';
import { showNotification } from './general';

export function sendMessage(chat, sender){
    const tg = document.getElementById('chat-input-' + chat);
    if(tg.value){
        if(tg.value.length < 201){
            useChatStore().sendMessage(chat, tg.value, sender);
            tg.value = '';
            tg.focus();
        }else{
            showNotification('', 'Panjang pesan maksimal adalah 200 karakter', 2000);
        }
    }
}
export function enterSend(event){
    if(event.key === 'Enter'){
        event.preventDefault();
        event.srcElement.parentElement.parentElement.nextElementSibling.click();
    }
}
export function chatToggle(cb, id){
    const chatbox = document.getElementById(cb);
    const tg = document.getElementById(id);
    const ic = document.getElementById('cbb-icon').children[0];
    if(chatbox.classList.contains('chatbox-active')){
        chatbox.classList.remove('chatbox-active');
        tg.classList.remove('cbody-active');
        ic.style.transform = 'rotate(0)';
    }else{
        chatbox.classList.add('chatbox-active');
        tg.classList.add('cbody-active');
        ic.style.transform = 'rotate(180deg)';
    }
}
export function selectChatWith(a){
    // const stage = document.getElementById('chat-box-stage');
    const stpart = document.getElementsByClassName('cbs-part');
    if(a == 'doctor'){
        router.push('/dokter');
        showNotification('', 'Silakan pilih Dokter !', 5000);
    }else if(a == 'cs'){
        for(var i = 0; i < stpart.length; i++){
            stpart[i].style.display = 'none';
        }
        document.getElementById('cbs-cs').style.display = 'block';
        // stage.scrollLeft = stage.children[0].scrollWidth * 2;
    }
}
export function goChatDoctor(){
    const stpart = document.getElementsByClassName('cbs-part');
    for(var i = 0; i < stpart.length; i++){
        stpart[i].style.display = 'none';
    }
    document.getElementById('cbs-doctor').style.display = 'block';
}
export function cbsBack(){
    const stpart = document.getElementsByClassName('cbs-part');
    for(var i = 0; i < stpart.length; i++){
        stpart[i].style.display = 'none';
    }
    document.getElementById('chat-box-select').style.display = 'block';
    // const stage = document.getElementById('chat-box-stage');
    // stage.scrollLeft = 0;
}
export function showCtb(index){
    const bt = document.getElementsByClassName('cth-inner');
    const cl = document.getElementsByClassName('ctb-inner');
    const cs = document.getElementById('chat-screen-' + index);
    for(var i = 0; i < bt.length; i++){
        bt[i].classList.remove('cth-inner-active');
        cl[i].classList.remove('ctb-inner-active');
    }
    document.getElementById('cth-inner-' + index).classList.add('cth-inner-active');
    document.getElementById('ctb-inner-' + index).classList.add('ctb-inner-active');
    cs.scrollTop = cs.scrollHeight;
    if(document.getElementById('chat-input-' + index)){
        document.getElementById('chat-input-' + index).focus();
    }
}
export function doctorActiveChats(chat){
    const chatbox = document.getElementById('chat-box');
    const tg = document.getElementById('chat-box-body');
    const ic = document.getElementById('cbb-icon').children[0];
    chatbox.classList.add('chatbox-active');
    tg.classList.add('cbody-active');
    ic.style.transform = 'rotate(180deg)';
    document.getElementById('cbo-doctor').click();
    document.getElementById('cth-inner-' + chat).click();
}