<template>
    <div class="content">
        <TopBackgroundComponent/>
        <CrumbComponent :data="crumbdata"/>
        <!-- <ServiceHeaderComponent/> -->
        <CoeListByCategoryComponent/>
    </div>
</template>
<script setup>
    import TopBackgroundComponent from "../components/TopBackgroundComponent.vue";
    import CrumbComponent from '@/components/CrumbComponent.vue';
    // import ServiceHeaderComponent from "../components/coe/ServiceHeaderComponent.vue";
    import CoeListByCategoryComponent from "../components/coe/CoeListByCategoryComponent.vue";
    const crumbdata = [
        {
            title: 'Home',
            type: 'link',
            to: '/'
        },
        {
            title: 'Layanan Unggulan',
            type: 'link',
            to: '/layanan-unggulan'
        },
        {
            title: 'Daftar Layanan Unggulan',
            type: 'text'
        }
    ];
    window.scrollTo(0, 0);
</script>
<style scoped>
    /*  */
</style>
