import { defineStore } from "pinia";

export const useGeneralStore = defineStore("general", {
    state: () => ({
        // ==================== states used on related page and its relation
        waitingProcess: null,
        window: window
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on related page and its relation
        setWaitingProcess(value){
            this.waitingProcess = value;
        }
    }
});