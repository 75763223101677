<template>
    <EventAttendanceFormComponent/>
</template>

<script setup>
    import EventAttendanceFormComponent from '@/components/event/EventAttendanceFormComponent.vue';
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>