import { useContactStore } from "@/stores/contact";
import { showNotification } from "./general";
export function optionToggle(button){
    const bt = document.getElementById(button);
    if(bt.nextElementSibling.classList.contains('sso-active')){
        bt.nextElementSibling.classList.remove('sso-active');
        bt.children[2].classList.remove('ssb-icon-active');
    }else{
        bt.nextElementSibling.classList.add('sso-active');
        bt.children[2].classList.add('ssb-icon-active');
    }
}
export function selectSpecialist(sp){
    optionToggle('ssb-select-specialist');
    document.getElementById('cform-input-doctor_speciality_id').value = sp.id;
    document.getElementById('ssb-text-specialist').innerHTML = sp.name;
}
export function submitForm(){
    const form = document.getElementById('sci-form-contact');
    var vdfalse = 0;
    var params = [];
    var fd = new FormData();
    for(var i = 0; i < form.children.length; i++){
        if(form.children[i].classList.contains('input')){
            const input = form.children[i].children[1];
            const name = input.getAttribute('name');
            const value = input.value.trim();
            const validate = inputValidation(input);
            if(validate.status == 1){
                fd.append([name], value);
                params.push({[name]: value});
                document.getElementById('cform-alert-' + name).innerHTML = '';
            }else{
                vdfalse = vdfalse + 1;
                document.getElementById('cform-alert-' + name).innerHTML = '<small>' + validate.message + '</small>';
            }
        }else if(form.children[i].classList.contains('select')){
            const input = form.children[i].children[0];
            const name = input.getAttribute('name');
            const value = input.value.trim();
            const validate = inputValidation(input);
            if(validate.status == 1){
                fd.append([name], value);
                params.push({[name]: value});
                document.getElementById('cform-alert-' + name).innerHTML = '';
            }else{
                vdfalse = vdfalse + 1;
                document.getElementById('cform-alert-' + name).innerHTML = '<small>' + validate.message + '</small>';
            }
        }
    }
    if(vdfalse == 0){
        fd.append('doctor_speciality_id', '2efa1fc3-de35-4559-ae1c-a599a7ca6f38');
        useContactStore().submitForm(fd);
        console.log(params);
    }else{
        showNotification('', 'Silakan cek ' + vdfalse + ' input yang belum benar', 2000, null);
    }
}
export function inputValidation(input){
    var result = {status: 1, message: 'validasi berhasil'};
    if(input.hasAttribute('email')){
        if(!emailValidation(input)){
            result = {status: 0, message: input.getAttribute('label') + ' harus valid'};
        }
    }
    if(input.hasAttribute('phone')){
        if(!phoneValidation(input)){
            result = {status: 0, message: input.getAttribute('label') + ' harus valid'};
        }
    }
    if(input.hasAttribute('required')){
        if(!input.value){
            result = {status: 0, message: input.getAttribute('label') + ' diperlukan'};
        }
    }
    return result;
}
export function emailValidation(input){
    if(input.value){
        const eival = input.value.trim();
        if(!eival.match(' ')){
            const iv = eival.split('@');
            if(iv.length == 2){
                const iv1 = iv[1].split('.');
                if(iv1[1]){
                    if(iv1[1].length > 1){
                        return true;
                    }
                }
            }
        }
    }
    return false;
}
export function phoneValidation(input){
    if(input.value){
        if(input.value.length > 5 && input.value.length < 15){
            return true;
        }
    }
    return false;
}
export function clearForm(){
    console.log('clear form');
    const form = document.getElementById('sci-form-contact');
    for(var i = 0; i < form.children.length; i++){
        var input;
        if(form.children[i].classList.contains('input')){
            input = form.children[i].children[1];
            input.value = null;
            // const name = input.getAttribute('name');
            // const value = input.value.trim();
            // const validate = inputValidation(input);
            // if(validate.status == 1){
            //     fd.append([name], value);
            //     params.push({[name]: value});
            //     document.getElementById('cform-alert-' + name).innerHTML = '';
            // }else{
            //     vdfalse = vdfalse + 1;
            //     document.getElementById('cform-alert-' + name).innerHTML = '<small>' + validate.message + '</small>';
            // }
        }else if(form.children[i].classList.contains('select')){
            input = form.children[i].children[0];
            input.value = null;
            // const name = input.getAttribute('name');
            // const value = input.value.trim();
            // const validate = inputValidation(input);
            // if(validate.status == 1){
            //     fd.append([name], value);
            //     params.push({[name]: value});
            //     document.getElementById('cform-alert-' + name).innerHTML = '';
            // }else{
            //     vdfalse = vdfalse + 1;
            //     document.getElementById('cform-alert-' + name).innerHTML = '<small>' + validate.message + '</small>';
            // }
        }
    }
}