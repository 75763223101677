<template>
    <section v-if="facilities">
        <div class="section-header">
            <h2>{{ activeCategory }}</h2>
        </div>
        <div class="section-body">
            <!-- {{ facilities }} -->
            <div class="sb-inner">
                <div class="sb-card" v-for="(facility) in facilities" :key="facility.id">
                    <div class="sbc-label">
                        <span>{{ activeCategory }}</span>
                    </div>
                    <router-link :to="'/fasilitas-kesehatan/detail/' + facility.slug">
                        <div class="sbc-content">
                            <div class="sbc-image">
                                <img :src="facility.thumbnail" alt="melinda care" />
                            </div>
                            <div class="sbc-detail">
                                <h3>{{ facility.name }}</h3>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </section>
    <HealthFacilityByCategorySkeleton v-else/>
</template>
<script setup>
    import { useRoute } from 'vue-router'
    import { computed } from "@vue/runtime-core";
    import { useFacilityStore } from "../../stores/facility";
    import HealthFacilityByCategorySkeleton from "../skeletons/HealthFacilityByCategorySkeleton.vue";
    const activeCategory = computed(() => useFacilityStore().activeCategory);
    const facilities = computed(() => useFacilityStore().healthFacilityByCategory);
    // const imgsrc = process.env.VUE_APP_ROOT_API;
    useFacilityStore().setHfbc(useRoute().params.slug);
</script>
<style scoped>
    section{
        padding-left: 9%;
        padding-right: 9%;
        padding-bottom: 2.5%;
        width: 100%;
        transition: 0.2s;
    }
    .section-header{
        padding: 1%;
        padding-top: 0;
    }
    .section-header h2{
        font-family: poppins;
        color: var(--white);
        margin: 0;
    }
    .section-body{
        width: 100%;
    }
    .sb-inner{
        display: flex;
        flex-wrap: wrap;
    }
    .sb-card{
        position: relative;
        min-width: 290px;
        width: 25%;
        padding: 1%;
        transition: 0.2s;
    }
    .sb-card a{
        text-decoration: none;
        color: #575757;
    }
    .sbc-label{
        position: absolute;
        top: 10%;
        left: 10%;
    }
    .sbc-label span{
        padding: 5%;
        padding-left: 10px;
        padding-right: 10px;
        background: #75C6EF;
        color: #ffffff;
        border-radius: 50px;
    }
    .sbc-content{
        /* border: 1px solid rgb(230, 227, 227); */
        border-radius: 10px;
        overflow: hidden;
        height: 270px;
        background: var(--white);
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .sbc-image{
        width: 100%;
        min-height: 150px;
        height: 41%;
        overflow: hidden;
        background: lightgrey;
    }
    .sbc-image img{
        width: 100%;
    }
    .sbc-detail{
        padding: 2.5%;
    }
    .sbc-detail h3{
        font-family: poppins;
        text-align: left;
        color: #575757;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin: 0;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }
    .sbc-detail p{
        font-family: poppins-light;
        font-weight: 600;
        font-size: 0.9rem;
        text-align: justify;
        color: #575757;
        margin: 0;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding-left: 4%;
            padding-right: 4%;
        }
    }
    @media only screen and (max-width: 1415px){
        .sb-card{
            width: 25%;
        }
    }
    @media only screen and (max-width: 950px){
        .sb-card{
            width: 50%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding-left: 1%;
            padding-right: 1%;
        }
        .section-header h2{
            color: var(--grey);
        }
    }
    @media only screen and (max-width: 650px){
        .sb-card{
            min-width: 250px;
        }
    }
    @media only screen and (max-width: 550px){
        .sb-card{
            width: 100%;
        }
    }
    /*  */
</style>