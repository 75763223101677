<template>
    <section>
        <div class="section-inner">
            <div class="si-head">
                <h1>Pengumuman Peserta Event</h1>
                <h2>{{ allData?.detail.title }}</h2>
                <div style="padding: 1rem 0 1rem 0; border: 1px solid red; border-radius: 5px; text-align: center; padding: 1rem;" v-if="allData?.detail?.id == 48">
                    <!-- <p>Untuk seluruh peserta FUN RUN wajib konfirmasi kembali ke nomor CS <a href="https://api.whatsapp.com/send?phone=6285939199326&text=Nama: Komunitas: " target="_blank">085939199326</a> (maks. 3 hari kedepan) dengan FORMAT : NAMA & NAMA KOMUNITAS (sesuai pengumuman) Untuk update informasi Follow Channel WhatsApp kami di link berikut: </p><a href="https://whatsapp.com/channel/0029VaIjfen8PgsPttRBzr1U" style="font-size: 1.5rem; font-weight: 700;">Klik di Sini!</a> -->
                    <!-- <p>Untuk seluruh peserta FUN RUN yang belum mendapat kode pengambilan baju hingga hari Selasa 23 Juli 2024 silakan klik link di bawah ini:</p> -->
                    <p>Info Penting : Pengambilan Jersey Fun Run HUT Melinda Hospital hanya dapat diambil di hari MINGGU, 28 JULI 2024 PUKUL 04.00 WIB</p>
                    <p>
                        <a href="https://api.whatsapp.com/send/?phone=+6287843882204&text=Halo. saya atas nama ..... ingin meminta kode pengambilan baju." style="font-size: 1.5rem; font-weight: 700;" target="_blank">Klik di Sini!</a>
                    </p>
                </div>
                <!-- <div class="si-head-inner">
                    <div class="cuti-baru">
                        <div class="cuti-baru-button">
                            <button @click="showModal('cuti-baru-modal');">Buat KPI Baru</button>
                        </div>
                        <div class="modal srp-modal" id="cuti-baru-modal">
                            <div class="srp-modal-head">
                                <div class="smh-close" @click="hideModal('cuti-baru-modal')">
                                    <span>x</span>
                                </div>
                            </div>
                            <div class="srp-modal-body">
                                <div class="smb-form" id="form-new-kpi">
                                    <div class="smb-form-input">
                                        <div class="sfi-label">
                                            <span>Tanggal</span>
                                        </div>
                                        <div class="sfi-input">
                                            <input type="date" :id="'input-date-new'"/>
                                        </div>
                                    </div>
                                    <div class="smb-form-input">
                                        <div class="sfi-label">
                                            <span>Pekerjaan</span>
                                        </div>
                                        <div class="sfi-input">
                                            <input type="text" :id="'input-title-new'"/>
                                        </div>
                                    </div>
                                    <div class="smb-form-input">
                                        <input type="hidden" name="friend" label="Poli" class="poli-input" :id="'input-kpitype-new'"/>
                                        <div class="sfi-label">Pilih Jenis Pekerjaan</div>
                                        <div class="sfi-select" v-if="kpiOptions">
                                            <div class="sfi-select-button" :id="'ssb-signup-specialistnew'" @click="optionToggle('ssb-signup-specialistnew');">
                                                <div class="btn-cover float-button"></div>
                                                <div class="ssb-text" :id="'ssb-text-temannew'">
                                                    <span>Pilih Jenis Pekerjaan</span>
                                                </div>
                                                <div class="float-icon">
                                                    <img src="../../assets/images/icons/arrow-1.png" alt="rsia melinda"/>
                                                </div>
                                            </div>
                                            <div class="sfi-select-option float" id="sso-friend">
                                                <div class="sso-notif">
                                                    <span v-if="kpiOptions.length > 1">ada {{ (kpiOptions.length) }} Jenis Pekerjaan</span>
                                                    <span v-else></span>
                                                </div>
                                                <div class="sso-search float-button">
                                                    <div class="sso-search-inner float-button">
                                                        <div class="sso-search-input float-button">
                                                            <input type="text" class="input-select float-button" id="input-searchjob-new" @input="inputSf('input-searchjob-new');" placeholder="masukkan judul pekerjaan" autocomplete="off"/>
                                                        </div>
                                                        <div class="sso-search-close float-button">
                                                            <div class="ssc-button float-button" @click="clearInputSf('input-searchjob-new');">
                                                                <span class="float-button">x</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="sso-button-list">
                                                    <template v-for="friend in kpiOptions" :key="friend.id">
                                                        <div class="sso-button" @click="selectOption({title: friend.name, value: friend?.id}, {button: 'ssb-signup-specialist', input: 'input-kpitype-new', title: 'ssb-text-temannew'}); selectFriend();">
                                                            <span>{{ friend.name }}</span>
                                                        </div>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else>Loading.....</div>
                                    </div>
                                    <div class="smb-form-input">
                                        <div class="sfi-label">
                                            <span>Deskripsi</span>
                                        </div>
                                        <div class="sfi-input">
                                            <textarea :id="'input-description-new'"></textarea>
                                        </div>
                                    </div>
                                    <div class="smb-form-input">
                                        <div class="sfi-button" v-if="!loader">
                                            <button @click="newKpi();">Buat KPI</button>
                                        </div>
                                        <div class="sfi-button" v-else>
                                            <button>Loading.....</button>
                                        </div>
                                    </div>
                                    <br/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="si-body">
                <div class="si-body-tab">
                    <!-- <div class="sbt-head">
                        <div class="sbt-head-part shp-active" id="shp-all" @click="showTab({button: 'sbt-head-part', content: 'sbt-body-part'}, {button: 'shp-all', content: 'sbp-all'});">
                            <span>KPI Saya</span>
                        </div>
                        <div class="sbt-head-part" id="shp-transfer" @click="showTab({button: 'sbt-head-part', content: 'sbt-body-part'}, {button: 'shp-transfer', content: 'sbp-transfer'});">
                            <span>Laporan</span>
                        </div>
                    </div> -->
                    <div class="sbt-body">
                        <div class="sbt-body-part sbp-active" id="sbp-all">
                            <div class="sbp-filter">
                                <!-- <h2>Filter</h2> -->
                                <div class="sbp-filter-board">
                                    <div class="sfb-part sfb-part-from">
                                        <input type="text" id="filter-name-announcement" filter="from" @input="inputSea" autocomplete="off" placeholder="masukkan nama peserta untuk mencari"/>
                                    </div>
                                    <div class="sfb-part sfb-part-from">
                                        <button>Cari</button>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div class="sbp-content">
                                <div class="sbi-table">
                                    <div class="sbi-table-fixed">
                                        <div class="sbi-table-head stf-head">
                                            <div class="shp-num">
                                                <span>No</span>
                                            </div>
                                            <div class="stf-head-part">
                                                <span>{{ allData?.columns[0].label }}</span>
                                            </div>
                                        </div>
                                        <div class="sbi-table-body">
                                            <div :class="'stb-row stb-row-' + (index % 2)" v-for="(data, index) in allData?.rows" :key="data">
                                                <div class="sbp-num">
                                                    <span>{{ index + (allData?.meta?.from || 1) }}</span>
                                                </div>
                                                <div class="stf-body-part">
                                                    <div class="day-name">{{ data[0]?.value || '' }}</div>
                                                    <!-- <span>{{ data.date }}</span> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sbi-table-scroll" @scroll="tableScroll">
                                        <div class="sbi-table-head">
                                            <template v-for="(data, index) in allData?.columns" :key="data">
                                                <div class="sts-head-part" v-if="index > 0">
                                                    <span>{{ data.label }}</span>
                                                </div>
                                            </template>
                                            <!-- <div class="sts-head-part">
                                                <span>Instansi</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Golongan</span>
                                            </div>
                                            <div class="sts-head-part">
                                                <span>Catatan</span>
                                            </div> -->
                                        </div>
                                        <div class="sbi-table-body">
                                            <div class="stb-row" v-for="(data, index) in allData?.rows" :key="data">
                                                <template v-for="(dt, ix) in data" :key="dt">
                                                    <div :class="'sts-row-part srp-' + (index % 2)" v-if="ix > 0">
                                                        <span>{{ dt.value }}</span>
                                                    </div>
                                                </template>
                                                <!-- <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span>{{ '-' }}</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span>{{ '-' }}</span>
                                                </div>
                                                <div :class="'sts-row-part srp-' + (index % 2)">
                                                    <span>{{ '-' }}</span>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sc-pagination">
                                    <div class="sc-pagination-inner">
                                        <div class="spi-part" v-for="link in allData?.meta?.links" :key="link" @click="useEventStore().dataByPage(link.label);">
                                            <span v-if="link.url">{{ filterLinkLabel(link.label) }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="sbp-content" v-else>
                                <div class="sbp-content-note">
                                    <p>Tidak ada KPI</p>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="section-inner" v-else>
            <div class="si-head">
                <h1>Pengumuman Peserta Event</h1>
            </div>
            <div class="si-body">
                <div class="sbp-content-note">
                    <p>Tidak ada pengumuman untuk event ini.</p>
                </div>
            </div>
        </div> -->
    </section>
</template>

<script setup>
    import { computed } from '@vue/reactivity';
    import { useEventStore } from '@/stores/event';
    import { useRoute } from 'vue-router';
    import { inputSea } from '@/functions/event';
    // import { showModal, hideModal, removeHTML, wordsLimit, 
    //     optionToggle, 
    //     selectOption,
    //     selectToggle
    //  } from '@/functions/general';
    // import { showTab, actionToggle, selectFriend, newKpi, updateKpi, inputLampiran, uplampKpi, startJob, endJob, clearInputSf, inputSf, kpiFilterSelectDateFrom, kpiFilterSelectDateTo, filterLinkLabel } from '@/functions/kpi';
    const allData = computed(() => useEventStore().displayedParticipants);
    // const kpiOptions = computed(() => useEventStore().kpiOptionsFiltered);
    // // const transfer = computed(() => useEventStore().transferList);
    // // const request = computed(() => useEventStore().transferRequests);
    // const loader = computed(() => useEventStore().loader);
    // const kpiReports = computed(() => useEventStore().kpiReports);
    useEventStore().setParticipants(useRoute().params.slug);
    // const date = new Date();
    // useEventStore().getKpiReport(date.getFullYear(), date.getMonth() + 1);
    function tableScroll(e){
        if(e.srcElement.scrollLeft > 0){
            e.srcElement.previousElementSibling.classList.add('stf-shadow');
        }else{
            e.srcElement.previousElementSibling.classList.remove('stf-shadow');
        }
    }
</script>

<style scoped>
    /* @import url('../../assets/css/selectoption.css');
    @import url('../../assets/css/report_table.css'); */
    section{
        padding: 100px 10% 2.5rem 10%;
        transition: 0.2s;
    }
    .si-head{
        padding: 0 0 2rem 0;
    }
    .si-head h1{
        margin: 0 0 1rem 0;
        font-size: 2rem;
        transition: 0.2s;
    }
    .cuti-baru-button button{
        background: var(--primary);
        color: var(--white);
        border: none;
        padding: 0.5rem 1rem 0.5rem 1rem;
        user-select: none;
        cursor: pointer;
    }
    .si-body{
        /* overflow: auto; */
    }
    .si-body-tab{
        border: 1px solid var(--softgrey);
    }
    .sbt-head{
        width: 100%;
        display: flex;
        background: var(--softgrey);
        overflow: auto;
    }
    .sbt-head::-webkit-scrollbar{
        display: none;
    }
    .sbt-head-part{
        min-width: 100px;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem 0 1rem 0;
        font-size: 1rem;
        font-weight: 700;
        text-align: center;
        cursor: pointer;
        user-select: none;
    }
    .shp-active{
        color: var(--primary);
        background: var(--white);
    }
    .sbt-body-part{
        display: none;
    }
    .sbp-active{
        display: block;
    }
    /* filter starts */
    .sbp-filter{
        padding: 1rem;
        transition: 0.2s;
    }
    .sbp-filter h2{
        margin: 1rem 0 1rem 0;
        padding: 0 0.5rem 0 0.5rem;
        font-size: 1.2rem;
        transition: 0.2s;
    }
    .sbp-filter-board{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        user-select: none;
        transition: 0.2s;
    }
    .sfb-part{
        width: 50%;
        padding: 0.5rem;
        transition: 0.2s;
    }
    .sfb-part h3{
        margin: 0 0 1rem 0;
        font-size: 1.1rem;
        transition: 0.2s;
    }
    .sfb-part input{
        width: 100%;
        height: 35px;
        border: 1px solid var(--softgrey);
        border-radius: 5px;
        outline: none;
        padding: 0.5rem;
    }
    .sfb-part button{
        height: 35px;
        border: none;
        border-radius: 5px;
        background: var(--primary);
        color: var(--white);
        padding: 0.5rem 1rem 0.5rem 1rem;
        transition: 0.2s;
    }
    .sfb-part button:hover{
        background: var(--primary-hover);
    }
    /* .sfb-part-button{
        padding: 0 0 0.5rem 0.5rem;
        transition: 0.2s;
    } */
    .spb-inner{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: left;
        align-items: end;
    }
    .spb-inner button{
        border: none;
        color: var(--white);
        width: 100%;
        height: 35px;
        transition: 0.2s;
    }
    .spb-reset{
        width: 50%;
        padding: 0 0.25rem 0 0;
    }
    .spb-reset button{
        background: var(--grey);
    }
    .spb-reset button:hover{
        background: var(--grey-hover);
    }
    .spb-filter{
        width: 50%;
        padding: 0 0 0 0.25rem;
    }
    .spb-filter button{
        background: var(--primary);
    }
    .spb-filter button:hover{
        background: var(--primary-hover);
    }
    /* filter ends */
    .sbp-content{
        position: relative;
        min-height: 500px;
        padding: 1rem 0 0 0;
        /* overflow: auto; */
    }
    .sbp-content-note{
        text-align: center;
        font-size: 0.9rem;
        font-style: italic;
        color: var(--softgrey);
    }
    .sbi-table{
        width: 100%;
        display: flex;
    }
    .sbi-table-fixed{
        width: 30%;
        transition: 0.2s;
        z-index: 1;
        padding: 0 0 10rem 0;
    }
    .stf-head-part{
        width: 75%;
    }
    .shp-num{
        width: 25%;
        text-align: center;
    }
    .stf-body-part{
        width: 70%;
    }
    .sbp-num{
        width: 25%;
        text-align: center;
    }
    .stf-shadow{
        box-shadow: 5px 0 5px 0 rgba(0, 0, 0, 0.2);
    }
    .sbi-table-scroll{
        position: relative;
        width: 75%;
        overflow: auto;
    }
    .sbi-table-scroll::-webkit-scrollbar{
        display: none;
    }
    .sbi-table-head{
        display: flex;
        font-size: 1.1rem;
        font-weight: 700;
        padding: 0.5rem 0 0.5rem 0;
        white-space: nowrap;
        transition: 0.2s;
    }
    .sts-head-part{
        min-width: 150px;
        width: 30%;
        text-align: left;
        padding: 0 0.5rem 0 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .shp-center{
        text-align: center;
    }
    .sbi-table-body{
        font-size: 1rem;
    }
    .stb-row{
        width: 100%;
        height: 75px;
        display: flex;
        font-size: 1.1rem;
        transition: 0.2s;
        align-items: center;
    }
    .stb-row-report{
        height: 200px;
    }
    .stb-row-0{
        /* background: var(--smoke); */
    }
    .sts-row-part{
        min-width: 150px;
        width: 30%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: left;
        overflow: auto;
        padding: 0.5rem;
        /* border: 1px solid green; */
    }
    .srp-option{
        overflow: unset;
    }
    .srp-0{
        /* background: var(--smoke); */
    }
    .srp-accordion{
        position: relative;
        width: 100%;
        height: 37px;
        user-select: none;
    }
    .srp-accordion-button{
        width: 100%;
        height: 100%;
        text-align: center;
    }
    .sab-icon img{
        transition: 0.2s;
    }
    .arrow-active{
        transform: rotate(180deg);
    }
    .srp-accordion-options{
        position: absolute;
        top: 100%;
        left: 5%;
        width: 90%;
        background: var(--white);
        z-index: 1;
        display: none;
        border: 1px solid var(--softgrey);
        border-radius: 5px;
    }
    .sao-active{
        display: block;
    }
    .sao-list{
        padding: 0.5rem;
    }
    .srp-button{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .srp-button span{
        padding: 0.1rem 1rem 0.1rem 1rem;
        background: var(--primary);
        color: var(--white);
        cursor: pointer;
        user-select: none;
    }
    .lembur-button{
        display: none;
        justify-content: center;
        align-items: center;
        padding: 1rem 0 0 0;
    }
    .lembur-button span{
        padding: 0.1rem 1rem 0.1rem 1rem;
        background: var(--grey);
        color: var(--white);
        cursor: pointer;
        user-select: none;
    }
    .lembur-button-active{
        display: flex;
    }
    .srp-modal{
        padding: 5rem 25% 1rem 25%;
        overflow: auto;
    }
    .srp-modal-head{
        position: absolute;
        top: 0;
        right: 0;
        width: 37px;
        height: 37px;
        color: var(--white);
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .srp-modal-body{
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        background: var(--white);
        padding: 1rem;
        animation-name: fromTop;
        animation-duration: 0.5s;
    }
    .smb-form{
        width: 100%;
    }
    .srp-modal-question{
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        background: var(--white);
        padding: 1rem;
        animation-name: fromTop;
        animation-duration: 0.5s;
    }
	.smq-text{
		text-align: center;
	}
	.smq-button{
		display: flex;
		justify-content: center;
	}
	.smq-button-part{
		width: 50%;
		padding: 1rem;
	}
	.smq-button button{
		border: none;
		min-width: 100px;
		padding: 0.5rem 1rem 0.5rem 1rem;
		transition: 0.2s;
		color: var(--white);
	}
	.sbp-yes button{
		background: var(--primary);
	}
	.sbp-yes button:hover{
		background: var(--primary-hover);
	}
	.sbp-no button{
		background: var(--red);
	}
	.sbp-no button:hover{
		background: var(--red-hover);
	}
    .sc-pagination{
        position: absolute;
        bottom: 100px;
        left: 1rem;
        right: 1rem;
        width: 100%;
        display: flex;
        /* justify-content: right; */
        font-weight: 700;
        /* border: 1px solid green; */
        padding: 1rem 0 1rem 0;
        z-index: 1;
    }
    .sc-pagination-inner{
        width: 100%;
        display: flex;
        justify-content: left;
    }
    .spi-part{
        /* cursor: pointer; */
        /* border: 1px solid green; */
    }
    .spi-part span{
        padding: 0.5rem;
        color: var(--primary);
        cursor: pointer;
    }
    .spi-part span:hover{
        color: var(--primary-hover);
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 100px 5% 2.5rem 5%;
        }
    }
    @media only screen and (max-width: 1000px){
        .sbp-filter-board{
            width: 100%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 100px 2.5% 2.5rem 2.5%;
        }
        /* filter starts */
        .sbp-filter{
            padding: 1rem 2.5% 1rem 2.5%;
        }
        .sbp-filter h2{
            padding: 0;
            font-size: 1.1rem;
        }
        .sfb-part{
            width: 100%;
        }
        .sfb-part h3{
            font-size: 0.9rem;
        }
        .sfb-part-from{
            padding: 0 0.25rem 0.5rem 0;
        }
        .sfb-part-to{
            padding: 0 0 0.5rem 0.25rem;
        }
        .sfb-part-button{
            width: 100%;
            padding: 0 0 0.5rem 0;
        }
        /* filter ends */
        .si-head h1{
            font-size: 1.5rem;
        }
        .sbi-table-head{
            font-size: 0.75rem;
        }
        .stb-row{
            font-size: 0.75rem;
        }
        .srp-modal{
            padding: 75px 0 0 0;
        }
        .srp-modal-body{
            height: 100%;
        }
    }
</style>