<template>
    <section>
        <div class="section-content">
            <div class="sc-head">
                <p class="small-title">Melinda Hospital</p>
                <h1 class="main-title" id="index-h1-0">
                    <strong>Mengembalikan Kebebasan Bergerak dengan Robotic Total Knee Replacement di Rumah Sakit Melinda 2 Bandung</strong>
                </h1>
                <div class="sh-image">
                    <img src="../../assets/images/pictures/coriheader.png" alt="Robotic Total Knee Replacement"/>
                </div>
            </div>
            <div class="sc-inner">
                <div class="sci-left">
                    <!-- {{ articleDetail }} -->
                    <div class="sl-head">
                    </div>
                    <div class="sl-body">
                        <div class="sl-img">
                            <!-- <img src="../../assets/images/pictures/coripic.png" alt="Robotic Total Knee Replacement"/> -->
                        </div>
                        <div class="sl-order">
                            <a href="https://api.whatsapp.com/send/?phone=+628157100888&text=Halo, saya ingin bertanya Total Knee Replacement menggunakan Robotic." target="_blank" @click="useSpecialPageStore().reportConversion({slug: 'whatsapp-click-robotic-total-knee-replacement-rsmelinda2', path: '/sp/whatsapp-click-robotic-total-knee-replacement-rsmelinda2', phone: '628157100888'});">Order Sekarang</a>
                        </div>
                        <div class="sl-content ql-container ql-snow" style="position: relative;">
                            <div class="sl-content-team">
                                <div class="sct-member sct-author" v-if="articleDetail?.ex_author">
                                    <div class="sca-param">Penulis: </div>
                                    <div class="sca-value"> {{ articleDetail?.ex_author }}</div>
                                </div>
                                <!-- <div class="sct-member sct-reviewer" v-if="articleDetail.reviewer">
                                    <div class="sca-param">Ditinjau Oleh: </div>
                                    <div class="sca-value">{{ articleDetail.reviewer }}</div>
                                </div> -->
                            </div>
                            <div class="sl-content-text ql-editor" id="sl-content-text">
                                <p>Apakah Anda atau orang yang Anda cintai mengalami masalah pada sendi lutut yang membutuhkan penggantian dengan implan logam? Rumah Sakit Melinda 2 dengan bangga mempersembahkan layanan terbaru, Robotic Total Knee Replacement, yang akan mengembalikan kebebasan bergerak Anda dengan cara yang lebih canggih dan efisien.</p>
                                <br/>
                                <h2>Apa itu Robotic Total Knee Replacement?</h2>
                                <br/>
                                <p>Robotic Total Knee Replacement adalah prosedur medis mutakhir yang menggunakan teknologi robotik untuk membantu ahli bedah dalam memasang implan lutut dengan presisi yang luar biasa. Teknologi ini dirancang untuk memastikan bahwa implan tersebut sesuai dengan anatomi unik setiap pasien, sehingga menghasilkan pergerakan sendi yang lebih alami dan nyaman, seperti sebelum mengalami masalah persendian.</p>
                                <br/>
                                <h2>Keunggulan Robotic Total Knee Replacement</h2>
                                <br/>
                                <ul>
                                    <li>Cepat Pulih: Salah satu keunggulan utama dari Robotic Total Knee Replacement adalah waktu pemulihan yang luar biasa cepat. Pasien dapat mulai berjalan kembali hanya dalam waktu 4 hingga 5 jam setelah operasi. Ini berarti Anda bisa kembali ke aktivitas normal lebih cepat dan menikmati kualitas hidup yang lebih baik.</li>
                                    <li>Presisi Tinggi: Teknologi robotik memberikan presisi yang lebih tinggi dalam pemasangan implan, sehingga mengurangi risiko kesalahan dan komplikasi. Hal ini juga memastikan hasil yang lebih baik dan tahan lama.</li>
                                    <li>Pergerakan yang Alami: Dengan analisis yang mendetail, robot ini membantu memastikan bahwa implan yang dipasang akan memberikan pergerakan sendi yang alami dan nyaman. Ini penting untuk memastikan bahwa Anda bisa bergerak dengan bebas dan tanpa rasa sakit.</li>
                                </ul>
                                <br/>
                                <h2>Mengapa Memilih Rumah Sakit Melinda 2?</h2>
                                <br/>
                                <p>Rumah Sakit Melinda 2 memiliki tim ahli bedah ortopedi yang berpengalaman dan terlatih dalam menggunakan teknologi robotik ini. Prosedur ini dipimpin oleh Dr. Moechammad Satrio Nugroho Magetsari, SpOT(K), yang telah mendapatkan sertifikasi operasi robot. Kami menggunakan robot merek Smith + Nephew, dan bangga menjadi rumah sakit pertama di Indonesia yang mengadopsi teknologi canggih ini untuk layanan Robotic Total Knee Replacement. Dengan keahliannya, Anda dapat merasa tenang mengetahui bahwa Anda berada di tangan yang tepat.</p>
                                <br/>
                                <h2>Biaya Layanan</h2>
                                <br/>
                                <p>Layanan Robotic Total Knee Replacement kami tersedia mulai dari 140 juta rupiah, tergantung pada bahan implan yang dipilih. Meskipun saat ini kami tidak menawarkan diskon khusus, investasi ini sepadan dengan hasil yang Anda dapatkan: kebebasan bergerak dan kualitas hidup yang lebih baik.</p>
                                <br/>
                                <h2>Media Liputan</h2>
                                <br/>
                                <p>Kami bangga karena layanan inovatif ini telah diliput oleh berbagai media ternama, termasuk Pikiran Rakyat, Tribun, Bisnis Indonesia, Koran Gala, Antara, Republika, Media Indonesia, Kompas, IDN Times, Detik, PRSSNI, PRFM, Pentas TV, Republika Network, dan Radar Bandung. Liputan media ini menunjukkan kepercayaan dan pengakuan terhadap kualitas layanan yang kami berikan.</p>
                                <br/>
                                <h2>Cara Mendapatkan Layanan Ini</h2>
                                <br/>
                                <p>Untuk informasi lebih lanjut atau untuk membuat janji konsultasi, Anda dapat menghubungi kami di:</p>
                                <br/>
                                <div class="contact">
                                    <div class="contact-head">
                                        <h3>Rumah Sakit Melinda 2</h3>
                                    </div>
                                    <div class="contact-row">
                                        <div class="contact-row-param">
                                            <span>Whatsapp</span>
                                        </div>
                                        <div class="contact-row-value crv-button">
                                            <span><a href="https://api.whatsapp.com/send/?phone=+628157100888&text=Halo, saya ingin bertanya Total Knee Replacement menggunakan Robotic." target="_blank" @click="useSpecialPageStore().reportConversion({slug: 'whatsapp-click-robotic-total-knee-replacement-rsmelinda2', path: '/sp/whatsapp-click-robotic-total-knee-replacement-rsmelinda2', phone: '628157100888'});">0815 7100 888</a></span>
                                        </div>
                                    </div>
                                    <div class="contact-row">
                                        <div class="contact-row-param">
                                            <span>Alamat</span>
                                        </div>
                                        <div class="contact-row-value">
                                            <span>Jl. Dr. Cipto no 1, Bandung, Jawa Barat</span>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <p>Kami siap mendampingi Anda di setiap langkah menuju pemulihan yang lebih baik. Jangan ragu untuk menghubungi kami kapan saja.</p>
                                <br/>
                            </div>
                        </div>
                        <!-- <div class="reference" v-if="articleDetail?.reference">
                            <hr/>
                            <h2>Referensi</h2>
                            <ol>
                                <li v-for="rf in articleDetail?.reference" :key="rf">
                                    <a :href="rf.href" :id="'reference' + stringToHash(rf.href)" rel="nofollow" target="_blank">{{ rf.host }}</a>
                                </li>
                            </ol>
                        </div> -->
                    </div>
                    <!-- <div class="sc-share">
                        <h2>Share to</h2>
                        <div class="sc-share-inner">
                            <div class="scs-part">
                                <a href="https://www.facebook.com" target="_blank">
                                    <img src="../../assets/images/icons/cc-facebook.png" alt="facebook icon"/>
                                </a>
                            </div>
                            <div class="scs-part">
                                <a href="https://www.twitter.com" target="_blank">
                                    <img src="../../assets/images/icons/cc-twitter.png" alt="x icon"/>
                                </a>
                            </div>
                            <div class="scs-part">
                                <a href="https://www.linkedin.com" target="_blank">
                                    <img src="../../assets/images/icons/cc-linkedin.png" alt="linkedin icon"/>
                                </a>
                            </div>
                            <div class="scs-part">
                                <a href="https://www.instagram.com" target="_blank">
                                    <img src="../../assets/images/icons/cc-instagram.png" alt="instagram icon"/>
                                </a>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="sci-right">
                    <!-- <div class="cov-sr-index" id="cov-sr-index">
                        <div class="sr-index-icon" id="sr-index-icon" @click="soiIndexToggle();" v-if="articleDetailIndex">
                            <span>Daftar Isi</span>
                        </div>
                        <div class="sr-index" id="sr-index">
                            <div class="sr-index-title" v-if="articleDetailIndex">Daftar Isi</div>
                            <div class="soi-index" id="soi-index"></div>
                        </div>
                    </div> -->
                    <div class="sr-others">
                        <div class="sr-title">dr. Moechammad Satrio Nugroho Magetsari, SpOT(K)</div>
                        <div class="sr-others-inner">
                            <div class="soi-image">
                                <img src="../../assets/images/pictures/drsatrionugroho.jpg" alt="dr. Moechammad Satrio Nugroho Magetsari, SpOT(K)"/>
                            </div>
                            <!-- <router-link :to="'/articles/detail/' + related.slug" v-for="related in articleDetail?.relateds" :key="related" @click="useSpecialPageStore().setArticleDetail(related.slug); pageScrollTop();">
                                <div class="soi-part">
                                    <div class="soi-part-image">
                                        <img :src="related.thumb_small" :alt="related.name"/>
                                    </div>
                                    <div class="soi-part-detail">
                                        <h2>{{ wordsLimit(related.name, 90) }}</h2>
                                    </div>
                                </div>
                            </router-link> -->
                        </div>
                    </div>
                    <!-- <div class="sr-others">
                        <div class="sr-title">Kategori {{ articleDetail?.category.name }}</div>
                        <div class="sr-others-inner">
                            <router-link :to="'/articles/detail/' + sc.slug" v-for="sc in articleDetail?.same_category" :key="sc" @click="useSpecialPageStore().setArticleDetail(sc.slug); pageScrollTop();">
                                <div class="soi-part">
                                    <div class="soi-part-image">
                                        <img :src="sc.thumb_small" :alt="sc.name"/>
                                    </div>
                                    <div class="soi-part-detail">
                                        <h2>{{ wordsLimit(sc.name, 90) }}</h2>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div> -->
                    <!-- <div class="sr-categories" v-if="articleCategories">
                        <div class="sr-title">Kategori</div>
                        <div class="sr-categories-inner">
                            <div class="sr-link" v-for="category in articleCategories.data" :key="category.id">
                                <router-link :to="'/articles'">
                                    <h3 @click="setAutoSelectCategoryData(category);">{{ category.name }}</h3>
                                </router-link>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="sr-part sr-tag">
                        <h2>Tags</h2>
                        <div class="sr-tag-inner" v-if="articleDetail">
                            <router-link to="" v-for="tag in articleDetail.tags" :key="tag.id">{{ tag.name }}</router-link>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- <ArticleDetailSkeleton v-else/> -->
    </section>
</template>

<script setup>
    import { useSpecialPageStore } from "../../stores/specialpage";
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    // import { useRoute } from 'vue-router';
    // import { computed } from "@vue/runtime-core";
    // import ArticleDetailSkeleton from "../skeletons/ArticleDetailSkeleton.vue";
    // import { setAutoSelectCategoryData } from '@/functions/article';
    // import { stringToHash } from '@/functions/general';
    // const articleDetail = computed(() => useSpecialPageStore().articleDetail);
    // const articleDetailIndex = computed(() => useSpecialPageStore().articleDetailIndex);
    // const articleCategories = computed(() => useSpecialPageStore().articleCategories);
    // useSpecialPageStore().setArticleDetail(useRoute().params.slug);
    // useSpecialPageStore().setArticleCategories();
    // window.navigation.addEventListener("navigate", (event) => {
    //     var url = new URL(event.destination.url);
    //     checkPath(url.pathname);
    // });
    // function gtag_report_conversion(url) {
    //     var callback = function () {
    //         if (typeof(url) != 'undefined') {
    //         window.location = url;
    //         }
    //     };
    //     gtag('event', 'conversion', {
    //         'send_to': 'AW-16645614112/oO__CITyq8MZEKDUn4E-',
    //         'value': 1.0,
    //         'currency': 'IDR',
    //         'transaction_id': '',
    //         'event_callback': callback
    //     });
    //     return false;
    // }
</script>

<style scoped>
    @import url('../../assets/css/ql-editor.css');
    @import url('../../assets/css/article_detail_index.css');
    .ql-snow .ql-editor img {
        max-width: 100%;
    }
    section{
        position: relative;
        width: 100%;
        min-height: 15rem;
        padding-top: 0;
        padding-bottom: 2.5rem;
        color: #1B4674;
        transition: 0.2s;
    }
    .sl-head{
        color: var(--white);
    }
    .small-title{
        font-weight: 600;
        font-size: 1.125rem;
        margin: 0;
    }
    .main-title{
        font-family: poppins;
        font-size: 3.25rem;
        font-weight: 700;
        margin-top: 1rem;
        margin-bottom: 1rem;
        transition: 0.2s;
        line-height: 1.2;
    }
    .p-date{
        margin: 0;
        margin-top: 1.6875rem;
        font-size: 1rem;
        font-weight: 400;
        width: 31.9375rem;
        transition: 0.2s;
    }
    .section-content{
        width: 100%;
        height: 100%;
        padding-left: 10%;
        padding-right: 10%;
        transition: 0.2s;
    }
    .sc-head{
        color: var(--white);
        /* border: 1px solid green; */
    }
    .sh-image{
        width: 100%;
    }
    .sh-image img{
        width: 100%;
    }
    .sc-inner{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        /* padding-top: 2rem; */
        transition: 0.2s;
        background: var(--white);
    }
    .sci-left{
        width: 60%;
        transition: 0.2s;
    }
    .sl-img{
        display: flex;
        justify-content: center;
    }
    .sl-img img{
        /* width: auto; */
        width: 25%;
        transition: 0.2s;
    }
    .sl-order{
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 2.5rem 0 0 0;
    }
    .sl-order a{
        padding: 0.5rem 1rem 0.5rem 1rem;
        background: var(--primary);
        color: var(--white);
        border-radius: 50px;
    }
    .sl-content{
        text-align: justify;
        color: var(--grey);
        font-family: poppins-regular;
    }
    .sl-content-team{
        width: 100%;
        display: flex;
        padding: 1rem 0 0 0;
    }
    .sl-content img{
        max-width: 100%;
    }
    .sct-member{
        width: 50%;
        display: flex;
        flex-direction: column;
    }
    .sct-reviewer{
        text-align: right;
        justify-content: right;
    }
    .sca-value{
        font-weight: 700;
        color: var(--navy);
    }
    .sl-content-text{
        padding: 1rem 0 0 0;
    }
    .contact a{
        background: var(--primary);
        color: var(--white);
        text-decoration: none;
        padding: 0.5rem 1rem 0.5rem 1rem;
        border-radius: 50px;
    }
    .contact-row{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 1rem 0 1rem 0;
    }
    .contact-row-param{
        width: 30%;
        display: flex;
        align-items: center;
        font-weight: 700;
    }
    .contact-row-value{
        width: 70%;
        display: flex;
        align-items: center;
    }
    .crv-button{
        padding: 0.5rem 0 0.5rem 0;
    }
    .slc-part{
        margin-bottom: 2rem;
    }
    .slc-part h2{
        font-family: poppins;
        font-size: 1.75rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }
    .cite{
        color: var(--primary) !important;
        cursor: pointer;
    }
    .slc-part ul{
        list-style-type: none;
        padding-left: 0;
    }
    .slc-part ol{
        padding-left: 1rem;
    }
    .slc-part li{
        font-size: 1rem;
    }
    .sc-share{
        margin-top: 2rem;
    }
    .sc-share h2{
        font-family: poppins;
        font-size: 1rem;
        font-weight: 700;
    }
    .sc-share-inner{
        display: flex;
        flex-wrap: nowrap;
        padding-top: 1rem;
    }
    .scs-part{
        margin-right: 1rem;
    }
    .sci-right{
        width: 40%;
        padding-left: 2.5rem;
        transition: 0.2s;
    }
    .cov-sr-index{
        max-height: 500px;
        transition: 0.5s;
    }
    .sr-index-icon{
        position: fixed;
        right: -200px;
        bottom: 100px;
        z-index: 1;
        transition: 0.5s;
        cursor: pointer;
        user-select: none;
        border: 1px solid var(--softgrey);
        padding: 0.5rem 1rem 0.5rem 1rem;
        background: var(--white);
    }
    .sii-scrolled{
        right: 10%;
    }
    .sr-index{
        transition: 0.5s;
        z-index: 5;
    }
    .sr-index-scrolled{
        position: fixed;
        top: 100px;
        right: -100%;
        bottom: 150px;
        width: 350px;
        overflow: auto;
        background: var(--white);
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
        padding: 0.5rem;
    }
    .sr-index-active{
        right: 10%;
    }
    .sr-index-title{
        font-family: poppins;
        font-weight: 700;
        font-size: 1.25rem;
        color: var(--navy);
        padding: 1rem 0 1rem 0;
    }
    .sr-index-scrolled .sr-index-title{
        display: none;
    }
    .soi-index{
        max-height: 350px;
        overflow: auto;
    }
    .soi-index-scrolled{
        max-height: unset;
        overflow: unset;
    }
    .sr-title{
        font-family: poppins;
        font-weight: 700;
        font-size: 1.25rem;
        color: var(--navy);
        padding: 1rem 0 1rem 0;
    }
    .sr-others a{
        color: var(--navy);
    }
    .sr-others a:hover{
        color: var(--navy-hover);
    }
    .soi-image{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .soi-image img{
        width: 50%;
    }
    .soi-part{
        display: flex;
        width: 100%;
        padding: 0.5rem 0 0.5rem 0;
    }
    .soi-part-image{
        width: 25%;
        overflow: hidden;
        display: flex;
        align-items: center;
    }
    .soi-part-image img{
        width: 100%;
    }
    .soi-part-detail{
        width: 75%;
        padding: 0 0.5rem 0 1rem;
        display: flex;
        align-items: center;
    }
    .soi-part-detail h2{
        margin: 0 0 0 0;
        line-height: 1;
        font-size: 0.9rem;
    }
    .soi-part-detail p{
        font-family: arial;
        color: var(--grey);
    }
    .sr-categories{
        width: 100%;
        transition: 0.2s;
        margin-bottom: 2rem;
    }
    .sr-link{
        margin-top: 1rem;
    }
    .sr-link a{
        color: #1B4674;
        text-decoration: none;
    }
    .sr-tag{
        width: 100%;
        transition: 0.2s;
        margin-bottom: 2rem;
    }
    .sr-tag-inner{
        display: flex;
        flex-wrap: wrap;
        padding-top: 1rem;
        width: 100%;
    }
    .sr-tag-inner a{
        font-size: 1rem;
        color: #575757;
        border: 1px solid #575757;
        border-radius: 50px;
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        text-decoration: none;
        margin-top: 1rem;
        margin-right: 1rem;
    }
    @media only screen and (max-width: 1500px){
        .section-content{
            padding-left: 5%;
            padding-right: 5%;
        }
        .main-title{
            font-size: 2.6rem;
        }
        .sii-scrolled{
            right: 5%;
        }
        .sr-index-active{
            right: 5%;
        }
    }
    @media only screen and (max-width: 1050px){
        .sc-inner{
            padding-top: 0;
        }
        .sci-left{
            width: 100%;
        }
        .sl-img img{
            width: 100%;
        }
        .main-title{
            font-size: 2rem;
        }
        .p-date{
            width: auto;
        }
        .sci-right{
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding-top: 2rem;
            padding-left: 0;
        }
        .cov-sr-index{
            height: auto;
        }
        .sr-index-icon{
            position: fixed;
            right: 5%;
            bottom: 100px;
            z-index: 1;
            transition: 0.5s;
            cursor: pointer;
            user-select: none;
            border: 1px solid var(--softgrey);
            padding: 0.5rem 1rem 0.5rem 1rem;
            background: var(--white);
        }
        .sr-index{
            position: fixed;
            top: 100px;
            right: -200%;
            bottom: 150px;
            width: 350px;
            overflow: auto;
            background: var(--white);
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
            padding: 0.5rem;
        }
        .sr-index-active{
            right: 2.5%;
        }
        .sr-index-title{
            padding: 0;
        }
        .sr-check{
            margin-bottom: 1.5rem;
        }
        .sr-categories{
            width: 50%;
            margin-bottom: 0;
        }
        .sr-tag{
            width: 50%;
            margin-bottom: 0;
        }
    }
    @media only screen and (max-width: 750px){
        .sl-head{
            color: var(--grey);
        }
        .sc-head{
            color: var(--grey);
        }
        .small-title{
            font-size: 1rem;
        }
        .main-title{
            font-size: 1.5rem;
        }
        .section-content{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .sl-content-team{
            display: block;
        }
        .sct-member{
            width: 100%;
        }
        .sct-reviewer{
            text-align: left;
        }
        .contact-head{
            width: 100%;
            text-align: center;
        }
        .contact-row{
            /* justify-content: center; */
        }
        .contact-row-param{
            width: 100%;
            /* padding: 1rem 0 1rem 0; */
            text-align: center;
            display: inline;
        }
        .contact-row-value{
            width: 100%;
            /* padding: 1rem 0 1rem 0; */
            text-align: center;
            display: inline;
        }
        .sci-right{
            padding-top: 1rem;
        }
        .sr-index-icon{
            right: 2.5%;
        }
        .sr-check{
            padding: 0;
            margin-bottom: 1rem;
            box-shadow: unset;
            border-radius: 0;
        }
        .sr-categories{
            width: 100%;
        }
        .sr-tag{
            width: 100%;
        }
    }
</style>