<template>
    <div class="content">
        <TopBackgroundComponent/>
        <CrumbComponent :data="crumbdata"/>
        <OrderListComponent/>
    </div>
</template>
<script setup>
    import TopBackgroundComponent from '../components/TopBackgroundComponent.vue';
    import CrumbComponent from '@/components/CrumbComponent.vue';
    import OrderListComponent from '@/components/order/OrderListComponent.vue';
    const crumbdata = [
        {
            title: 'Home',
            type: 'link',
            to: '/'
        },
        {
            title: 'Order List',
            type: 'text'
        }
    ];
    window.scrollTo(0, 0);
</script>
<style scoped>
    /*  */
</style>
