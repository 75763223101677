import { showNotification, setCookie, getCookie } from "./general";
import { cartCookieVersion, sessionExpiration } from "@/equipments/settings";
import { useTransactionStore } from "@/stores/transaction";
// import { payment } from "@/equipments/dummies";
import { useMedicineStore } from "@/stores/medicine";

export function soToggle(id){
    const tg = document.getElementById(id);
    if(tg.style.maxHeight == '0px'){
        tg.style.maxHeight = tg.scrollHeight + 'px';
        tg.previousElementSibling.children[0].innerHTML = 'Sembunyikan';
        // console.log('Max Null');
    }else{
        tg.style.maxHeight = '0px';
        tg.previousElementSibling.children[0].innerHTML = 'Tampilkan';
        // console.log('Hello');
    }
    tg.classList.remove('hide-by-scroll');
}
export function qtyChange(a){
    const input = document.getElementById('input-qty');
    if(a == 'plus'){
        input.value = parseInt(input.value) + 1;
    }else{
        if(input.value > 1){
            input.value = parseInt(input.value) - 1;
        }
    }
}
export function iQtyInput(id, op){
    const tg = document.getElementById(id);
    if(op == '+'){
        if(tg.value > 0){
            tg.value = parseInt(tg.value) + 1;
        }
    }else{
        if(tg.value > 0){
            tg.value = parseInt(tg.value) - 1;
        }
    }
}
export function addToCart(seller_id, seller_name, product_name, product_image){
    const product_id = document.getElementById('srv-input-id').value;
    const product_unit = document.getElementById('srv-input-unit').value;
    const product_price = document.getElementById('srv-input-price').value;
    const input = document.getElementById('input-qty');
    if(useMedicineStore().selectedStock.total >= input.value){
        if(input.value > 0){
            setCart(seller_id, seller_name, product_id, product_name, product_unit, product_image, product_price, input.value, '+');
            showNotification('', 'Produk dimasukkan Cart', 1000, null);
        }
    }else{
        showNotification('', 'Stock/persediaan untuk varian yang anda pilih tidak cukup', 2000, 'var(--primary)');
    }
}
export function minCartQuantity(seller_id, seller_name, product_id, product_name, product_unit, product_image, product_price, qty){
    setCart(seller_id, seller_name, product_id, product_name, product_unit, product_image, product_price, qty, '-');
}
export function plusCartQuantity(seller_id, seller_name, product_id, product_name, product_unit, product_image, product_price, qty){
    setCart(seller_id, seller_name, product_id, product_name, product_unit, product_image, product_price, qty, '+');
}
export function setCart(seller_id, seller_name, product_id, product_name, product_unit, product_image, product_price, quantity, op){
    if(quantity > 0){
        const date = new Date();
        const time = date.getTime();
        const exdays = sessionExpiration; // =========================================================================================================== expiration days
        const version = cartCookieVersion; // ========================================================================================== current cart cookie version
        const cart = {header:{version:version},body:{seller:[{up:time,id:seller_id,name:seller_name,product:[{id:product_id,name:product_name,unit:product_unit,img:product_image,price:product_price,qt:quantity,checked:1}]}]}}; // ===== generating new cart
        if(getCookie('cart')){ // ====================================================================================================== 'cart' is cookie name
            const savedCart = JSON.parse(getCookie('cart')); // ======================================================================== saved cart
            if(savedCart.header.version == version){ // ================================================================================ if version match with the current version
                var i;
                var sellerexist = 0;
                var productexist = 0;
                for(i = 0; i < savedCart.body.seller.length; i++){ // ================================================================== checking seller, whether exist or not
                    if(savedCart.body.seller[i].id == seller_id){ // =================================================================== comparing the seller id
                        sellerexist = i + 1;
                        var j;
                        for(j = 0; j < savedCart.body.seller[i].product.length; j++){ // =============================================== checking product, whether exist or not
                            if(savedCart.body.seller[i].product[j].id == product_id){ // =============================================== comparing the product id
                                productexist = j + 1;
                            }
                        }
                    }
                }
                if(sellerexist > 0){ // ================================================================================================ if seller exist
                    const si = sellerexist - 1;
                    if(productexist > 0){
                        const pi = productexist - 1;
                        if(op == '+'){
                            savedCart.body.seller[si].product[pi].qt = parseInt(savedCart.body.seller[si].product[pi].qt) + parseInt(quantity); // updating new product quantity
                        }else{
                            if(savedCart.body.seller[si].product[pi].qt > 1){
                                savedCart.body.seller[si].product[pi].qt = parseInt(savedCart.body.seller[si].product[pi].qt) - parseInt(quantity); // updating new product quantity
                            }
                        }
                    }else{
                        savedCart.body.seller[si].up = time;
                        savedCart.body.seller[si].product.push({id:product_id,name:product_name,unit:product_unit,img:product_image,price:product_price,qt:quantity,checked:1}); // =============================== creating new product and the quantity
                    }
                    setCookie('cart', JSON.stringify(savedCart), exdays);
                }else{
                    savedCart.body.seller.push({up:time,id:seller_id,name:seller_name,product:[{id:product_id,name:product_name,unit:product_unit,img:product_image,price:product_price,qt:quantity,checked:1}]}); // ====================== creating new seller, product and the quantity
                    setCookie('cart', JSON.stringify(savedCart), exdays);
                }
            }else{
                setCookie('cart', JSON.stringify(cart), exdays);
            }
        }else{
            setCookie('cart', JSON.stringify(cart), exdays);
        }
        useTransactionStore().setCart(JSON.parse(getCookie('cart')));
        setTimeout(function(){
            useTransactionStore().syncCart(seller_id, product_id);
        }, 1000);
        // showNotification('', 'Produk dimasukkan Cart', 10000, null);
        // console.log(JSON.parse(getCookie('cart')));
        // console.log(getCookie('cart'));
    }
}
export function updateCart(data){
    const cart = {header:{version:cartCookieVersion},body:data};
    setCookie('cart', JSON.stringify(cart), sessionExpiration);
    useTransactionStore().setCart(JSON.parse(getCookie('cart')));
}
export function itemCheck(id){
    if(getCookie('cart')){
        const cart = JSON.parse(getCookie('cart'));
        for(var i = 0; i < cart.body.seller.length; i++){
            for(var j = 0; j < cart.body.seller[i].product.length; j++){
                if(cart.body.seller[i].product[j].id == id){
                    cart.body.seller[i].product[j].checked = 1;
                    useTransactionStore().itemCheck(id, {checked:true});
                }
            }
        }
        updateCart(cart.body);
    }
}
export function itemUncheck(id){
    if(getCookie('cart')){
        const cart = JSON.parse(getCookie('cart'));
        for(var i = 0; i < cart.body.seller.length; i++){
            for(var j = 0; j < cart.body.seller[i].product.length; j++){
                if(cart.body.seller[i].product[j].id == id){
                    cart.body.seller[i].product[j].checked = 0;
                    useTransactionStore().itemCheck(id, {checked:false});
                }
            }
        }
        updateCart(cart.body);
    }
}
export function clearCart(){
    // setCookie('cart', {header:{version:0}}, 0);
    useTransactionStore().deleteAllCart();
}
export function deleteSeller(id){
    useTransactionStore().deleteCartSeller(id);
}
export function deleteItem(id){
    useTransactionStore().deleteCartItem(id);
}
export function confirmCart(){
    useTransactionStore().getConfirmCart();
}
export function selectShipmentService(type, seller, so){
    const confirmCart = useTransactionStore().confirmCart;
    for(var i = 0; i < confirmCart.seller.length; i++){
        if(confirmCart.seller[i].id == seller){
            confirmCart.seller[i].shipment_selected = {id: so.id, name: so.name, price: so.price};
        }
    }
    useTransactionStore().selectShipment(type, {seller: seller, shipment: so});
    // if(type == 'cart'){
    // }
    // console.log(so);
    // document.getElementsByClassName('shipment-selected')[index].innerHTML = '<div><h5 style="margin: 0; margin-bottom: 0.5rem;">' + so.name + '</h5></div><div><small>' + '' + '</small></div><div style="color: brown;"><small>Rp' + so.price + '</small></div>';
}
export function paymentCheck(type, method, service){ // service = service.id
    const payment = useTransactionStore().payment;
    for(var i = 0; i < payment.length; i++){
        for(var j = 0; j < payment[i].service.length; j++){
            payment[i].service[j].checked = 0;
        }
    }
    for(i = 0; i < payment.length; i++){
        if(payment[i].id == method){
            for(j = 0; j < payment[i].service.length; j++){
                if(payment[i].service[j].id == service){
                    payment[i].service[j].checked = 1;
                }
            }
        }
    }
    // useTransactionStore().getConfirmCart();
    // useTransactionStore().checkPaymentSelected();
    if(type == 'direct'){
        useTransactionStore().checkPaymentSelected();
    }
    if(type == 'cart'){
        useTransactionStore().selectPayment(service);
    }
}
export function paymentUncheck(type){
    const payment = useTransactionStore().payment;
    for(var i = 0; i < payment.length; i++){
        for(var j = 0; j < payment[i].service.length; j++){
            payment[i].service[j].checked = 0;
        }
    }
    if(type == 'cart'){
        useTransactionStore().getConfirmCart();
    }
    useTransactionStore().checkPaymentSelected();
}
export function checkOut(type){
    // console.log(JSON.parse(getCookie('cart')));
    // const cart = JSON.parse(getCookie('cart'));
    // for(var i = 0; i < cart.body.seller.length; i++){
    //     for(var j = 0; j < cart.body.seller[i].product.length; j++){
    //         if(cart.body.seller[i].product[j].checked == 1){
    //             cart.body.seller[i].product.splice(j, 1);
    //         }
    //     }
    //     if(cart.body.seller[i].product.length == 0){
    //         cart.body.seller.splice(i, 1);
    //     }
    // }
    // console.log(cart);
    // if(type == 'cart'){
    // }
    useTransactionStore().checkOut(type);
}
export function resetInputQty(id){
    const quantity = document.getElementById(id);
    const minbuy = quantity.getAttribute('minvalue');
    if(quantity.value < minbuy){
        quantity.value = minbuy;
    }
}