<template>
    <section>
        <div class="section-header">
            <!-- <h2>Kategori</h2> -->
        </div>
        <div class="section-body">
            <template v-if="facilities">
                <FilteredFacilitiesComponent v-if="filteredFacilities"/>
                <template v-else>
                    <template v-if="!searchbarCategory.input.value">
                        <HealthFacilityListComponent v-if="facilities"/>
                        <!-- <div class="src-inner" v-if="facilities">
                            <div class="src-card" v-for="(facility) in facilities" :key="facility.id">
                                <router-link :to="'/fasilitas-kesehatan/detail/' + facility.slug" >
                                    <div class="src-card-inner">
                                        <div class="sci-icon">
                                            <img :src="facility.image" alt="melinda care" v-if="facility.image"/>
                                            <img src="../../assets/favicon.png" alt="melinda care logo" v-else/>
                                        </div>
                                        <div class="sci-descript">
                                            <h3>{{ facility.name }}</h3>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div> -->
                    </template>
                    <template v-else>
                        <div class="search-not-found">
                            <p>Tidak ditemukan Fasilitas Kesehatan dengan kata kunci :</p>
                            <div v-if="searchbarCategory.input.value">"{{ searchbarCategory.input.value }}"</div>
                            <div v-if="searchbarSelectCategory.input.value.length > 0">"{{ searchbarSelectCategory.input.value }}"</div>
                            <div v-if="searchbarCategory.select.value != 'all'">dalam {{ searchbarCategory.select.title }}</div>
                            <div v-else>dalam semua kategori</div>
                        </div>
                    </template>
                </template>
            </template>
            <HealthFacilityTileSkeleton v-else />
        </div>
    </section>
</template>
<script setup>
    import { computed } from "@vue/runtime-core";
    import { useFacilityStore } from "../../stores/facility";
    import HealthFacilityTileSkeleton from "../skeletons/HealthFacilityTileSkeleton.vue";
    import FilteredFacilitiesComponent from "@/components/health-facility/FilteredFacilitiesComponent.vue";
    import HealthFacilityListComponent from "@/components/health-facility/HealthFacilityListComponent.vue";
    const facilities = computed(() => useFacilityStore().allFacilities);
    const filteredFacilities = computed(() => useFacilityStore().filteredFacilities);
    const searchbarSelectCategory = computed(() => useFacilityStore().searchbarSelectCategory);
    const searchbarCategory = computed(() => useFacilityStore().searchbarCategory);
    useFacilityStore().getAllFacilities();
    // const imgsrc = process.env.VUE_APP_ROOT_API;
    // useFacilityStore().setFacilityCategories();
</script>
<style scoped>
    section{
        padding-left: 9%;
        padding-right: 9%;
        padding-bottom: 2rem;
        width: 100%;
        transition: 0.2s;
    }
    .section-header{
        padding: 1%;
        padding-top: 0;
    }
    .section-header h2{
        font-family: poppins;
        color: var(--white);
        margin: 0;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    .section-body{
        width: 100%;
    }
    .src-inner{
        display: flex;
        flex-wrap: wrap;
    }
    .src-card{
        width: 25%;
        height: 75px;
        /* background: var(--white); */
        /* border: 1px solid green; */
        /* border-radius: 10px; */
        padding: 1%;
        transition: 0.2s;
        user-select: none;
    }
    .src-card a{
        text-decoration: none;
        color: var(--grey);
    }
    .src-card-inner{
        display: flex;
        align-items: center;
        height: 100%;
        padding: 1%;
        background: var(--white);
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        overflow: hidden;
    }
    .sci-icon{
        width: 30%;
        height: 100%;
        /* padding: 5%; */
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sci-icon img{
        max-width: 100%;
        max-height: 100%;
        /* border: 1px solid green; */
    }
    .sci-descript{
        color: var(--grey);
        padding-left: 5%;
    }
    .sci-descript h3{
        font-size: 0.9rem;
    }
    /* .sb-inner{
        display: flex;
        flex-wrap: wrap;
    }
    .sb-card{
        position: relative;
        min-width: 290px;
        width: 25%;
        padding: 1%;
        transition: 0.2s;
    }
    .sb-card a{
        text-decoration: none;
        color: #575757;
    }
    .sbc-label{
        position: absolute;
        top: 7.5%;
        left: 10%;
    }
    .sbc-label span{
        padding: 5%;
        padding-left: 10px;
        padding-right: 10px;
        background: #75C6EF;
        color: #ffffff;
        border-radius: 50px;
    }
    .sbc-content{
        border: 1px solid rgb(230, 227, 227);
        border-radius: 10px;
        overflow: hidden;
        height: 270px;
    }
    .sbc-image{
        width: 100%;
        min-height: 150px;
        height: 41%;
        overflow: hidden;
        background: lightgrey;
    }
    .sbc-image img{
        width: 100%;
    }
    .sbc-detail{
        padding: 2.5%;
    }
    .sbc-detail h3{
        font-family: poppins;
        text-align: left;
        color: #575757;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin: 0;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }
    .sbc-detail p{
        font-family: poppins-light;
        font-weight: 600;
        font-size: 0.9rem;
        text-align: justify;
        color: #575757;
        margin: 0;
    } */
    .search-not-found{
        font-family: Arial, Helvetica, sans-serif;
        font-style: italic;
        text-align: center;
        color: var(--grey);
        word-break: break-all;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding-left: 4%;
            padding-right: 4%;
        }
        .src-card-inner{
            border-radius: 5px;
        }
    }
    @media only screen and (max-width: 1415px){
        /* .sb-card{
            width: 33.33%;
        } */
    }
    @media only screen and (max-width: 950px){
        /* .sb-card{
            width: 50%;
        } */
        .src-card{
            width: 33.33%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding-left: 1%;
            padding-right: 1%;
        }
        .section-header h2{
            color: var(--grey);
        }
        .src-card{
            width: 50%;
            margin-bottom: 1rem;
        }
    }
    @media only screen and (max-width: 650px){
        /* .sb-card{
            min-width: 250px;
        } */
    }
    @media only screen and (max-width: 550px){
        /* .sb-card{
            width: 100%;
        } */
        .src-card{
            width: 100%;
        }
    }
</style>