<template>
    <EventAnnouncementComponent/>
</template>

<script setup>
    import EventAnnouncementComponent from '@/components/event/EventAnnouncementComponent.vue';
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>