import { defineStore } from "pinia";
import axiosClient from "../axios.js";
import { showNotification } from "@/functions/general.js";
import { clearForm } from "@/functions/contact.js";

export const useContactStore = defineStore("contact", {
    state: () => ({
        // ==================== states used on contact page and its relation
        loading: false
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on contact page and its relation
        async submitForm(params){
            this.loading = true;
            try{
                const response = await axiosClient.post(`contactus`, params);
                if(response.status == 200){
                    showNotification('', 'Pesan terkirim', 2000, null);
                    clearForm();
                }
                this.loading = false;
            }catch(error){
                this.loading = false;
                showNotification('', error?.response?.data?.message, 2000, null);
                console.log(error);
            }
        }
    }
});