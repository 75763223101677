<template>
    <EventVerificationComponent/>
</template>

<script setup>
    import EventVerificationComponent from '@/components/event/EventVerificationComponent.vue';
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>