<template>
    <section>
        <div class="section-header">
            <h1>Konfirmasi Order</h1>
            <!-- {{ confirmCart }} -->
        </div>
        <template v-if="directOrder">
            <!-- {{ directOrderSchedule }} -->
            <div class="section-body">
                <div class="sb-inner">
                    <div class="sbi-product">
                        <!-- {{ shipmentOption }} -->
                        <div class="sbi-card">
                            <div class="sc-head">
                                <h2>{{ directOrder.service.detail.health_facility }}</h2>
                            </div>
                            <div class="sc-body">
                                <div class="sc-body-product">
                                    <div class="product-list product-list-services">
                                        <div class="pl-body">
                                            <div class="sc-body-image">
                                                <img :src="directOrder.service.detail.images[0].image" alt="melinda care" />
                                            </div>
                                            <div class="sc-body-description">
                                                <h3>{{ directOrder.service.detail.name }}</h3>
                                            </div>
                                            <div class="sc-body-qty">
                                                <span class="sbq-count">{{ directOrder.service.schedule.date_text }} <span style="color: grey;">{{ directOrder.service.schedule_selected.start_hour }} - {{ directOrder.service.schedule_selected.end_hour }}</span></span>
                                                <div class="sc-body-price">
                                                    <span>Rp{{ number_format(directOrder.service.detail.price, 0, ',', '.') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sc-body-shipment">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sb-button">
                    <div class="sbb-inner sticky-top-board">
                        <div class="cart-summary">
                            <div class="cs-inner">
                                <h2>Ringkasan Order</h2>
                                <div class="cs-row">
                                    <div class="cs-row-param">
                                        <span>Harga Layanan</span>
                                    </div>
                                    <div class="cs-row-value">
                                        <span>Rp{{ number_format(directOrder.service.detail.price, 0, ',', '.') }}</span>
                                    </div>
                                </div>
                                <hr/>
                                <div class="cs-row">
                                    <div class="cs-row-param">
                                        <strong>Total Bayar</strong>
                                    </div>
                                    <div class="cs-row-value">
                                        <strong>Rp{{ number_format(directOrder.service.detail.price, 0, ',', '.') }}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="buy-button">
                            <span @click="showModal('bb-modal');">Pilih Pembayaran</span>
                        </div>
                    </div>
                    <div class="modal" id="bb-modal" @click="hideModals">
                        <div class="modal-content">
                            <div class="modal-close" @click="hideModal('bb-modal');">x</div>
                            <div class="modal-content-head">
                                <h2>Pilih Metode Pembayaran</h2>
                            </div>
                            <div class="modal-content-body">
                                <div class="payment-list" v-if="payment">
                                    <!-- {{ payment }} -->
                                    <div class="payment-list-method" v-for="method in payment" :key="method">
                                        <h3>{{ method.name }}</h3>
                                        <div class="payment-list-card" v-for="service in method.service" :key="service">
                                            <div class="alc-inner" :id="'alc-inner-' + method.id + '-' +service.id">
                                                <div class="payment-list-check">
                                                    <span>Pilih</span>
                                                    <div class="alc-box" v-if="service.checked == 1" @click="paymentUncheck">
                                                        <img src="../../assets/images/icons/checked.png" alt="melinda care" />
                                                    </div>
                                                    <div class="alc-box" v-else @click="paymentCheck(method.id, service.id)"></div>
                                                </div>
                                                <div class="payment-list-detail">
                                                    <div class="ald-attention">
                                                        <h3>{{ service.name }}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="payment-list" v-else>
                                    <p>Tidak ada pembayaran yang tersedia</p>
                                </div>
                                <div class="payment-panel">
                                    <div class="payment-summary">
                                        <div class="cs-inner">
                                            <h2>Rincian Pembayaran</h2>
                                            <div class="cs-row">
                                                <div class="cs-row-param">
                                                    <span>Harga Barang</span>
                                                </div>
                                                <div class="cs-row-value">
                                                    <span>Rp{{ number_format(directOrder.service.detail.price, 0, ',', '.') }}</span>
                                                </div>
                                            </div>
                                            <!-- <div class="cs-row">
                                                <div class="cs-row-param">
                                                    <span>Biaya Layanan</span>
                                                </div>
                                                <div class="cs-row-value">
                                                    <span>Rp5.000</span>
                                                </div>
                                            </div> -->
                                            <hr/>
                                            <div class="cs-row">
                                                <div class="cs-row-param">
                                                    <strong>Total Bayar</strong>
                                                </div>
                                                <div class="cs-row-value">
                                                    <strong>Rp{{ number_format(directOrder.service.detail.price, 0, ',', '.') }}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="directOrderSchedule.payment">
                                        <div>
                                            <div class="payment-panel-button" v-if="!transactionProcess">
                                                <span class="pay-button" @click="checkOut">Bayar</span>
                                            </div>
                                            <div class="payment-panel-button" v-else>
                                                <span class="pay-button pay-button-process">Memproses Transaksi.....</span>
                                            </div>
                                        </div>
                                        <!-- <div v-else>
                                            <div class="payment-warning" v-if="!confirmCart">
                                                <small>Menunggu cart</small>
                                            </div>
                                            <div class="payment-panel-button">
                                                <span class="pay-button pay-button-false">Bayar</span>
                                            </div>
                                        </div> -->
                                    </div>
                                    <div v-else>
                                        <div class="payment-warning">
                                            <small>Pilih metode pembayaran</small>
                                        </div>
                                        <div class="payment-panel-button">
                                            <span class="pay-button pay-button-false">Bayar</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <p class="empty-message">Order anda kosong</p>
        </template>
    </section>
</template>

<script setup>
    import { computed } from "@vue/runtime-core";
    import { showModal, hideModals, hideModal, number_format } from '../../functions/general';
    import { paymentCheck, paymentUncheck, checkOut } from '@/functions/service';
    import { useTransactionStore } from '../../stores/transaction';
    import { useAddressStore } from "@/stores/address";
    // import OrderProductConfirmationSkeleton from '../skeletons/OrderProductConfirmationSkeleton.vue';
    // import { useRoute } from 'vue-router';
    // import { paymentCheck, paymentUncheck } from "@/functions/cart";
    // import { selectShipmentService, paymentCheck, paymentUncheck, checkOut } from '@/functions/cart';
    // import { regionToggle, aobSelect, selectProvince, selectProvinceEdit, selectCity, selectCityEdit, selectDistrict, selectDistrictEdit, selectSubDistrict, selectSubDistrictEdit, submitNewAddress, updateAddress, setMainAddress, selectAddress, deleteAddress } from "@/functions/address";
    // import router from '@/router';
    // import { useChatStore } from '../../stores/chat';
    useTransactionStore().createShipmentOption(10);
    useTransactionStore().setPayment();
    useTransactionStore().getConfirmCart();
    useAddressStore().setSelectedAddress();
    useAddressStore().setAddresses();
    useAddressStore().setProvinces();
    const payment = computed(() => useTransactionStore().payment);
    // const paymentSelected = computed(() => useTransactionStore().paymentSelected);
    const directOrder = computed(() => useTransactionStore().directOrder);
    const directOrderSchedule = computed(() => useTransactionStore().directOrderSchedule);
    const transactionProcess = computed(() => useTransactionStore().transactionProcess);
    // if(directOrder == null){
    //     router.push('/');
    // }else{
    //     console.log(directOrder);
    // }
    // const productType = useRoute().params.type;
    // const shipmentOption = useTransactionStore().shipmentOption;
    // const shipmentValidation = computed(() => useTransactionStore().shipmentValidation);
    // const confirmCart = computed(() => useTransactionStore().confirmCart);
    // const addressLoader = computed(() => useAddressStore().addressLoader);
    // const selectedAddress = computed(() => useAddressStore().selectedAddress);
    // const addresses = computed(() => useAddressStore().addresses);
    // const provinces = computed(() => useAddressStore().provinces);
    // const cities = computed(() => useAddressStore().cities);
    // const districts = computed(() => useAddressStore().districts);
    // const subDistricts = computed(() => useAddressStore().subDistricts);
    // const payment = computed(() => useTransactionStore().payment);
    // function editAddress(id){
    //     const ai = document.getElementById(id);
    //     if(ai.nextElementSibling.style.maxHeight == ai.nextElementSibling.scrollHeight + 'px'){
    //         ai.nextElementSibling.style.maxHeight = '0';
    //     }else{
    //         ai.nextElementSibling.style.maxHeight = ai.nextElementSibling.scrollHeight + 'px';
    //     }
    // }
    // function addAddress(id){
    //     const ai = document.getElementById(id);
    //     if(ai.nextElementSibling.style.display == 'block'){
    //         ai.nextElementSibling.style.display = 'none';
    //     }else{
    //         ai.nextElementSibling.style.display = 'block';
    //     }
    // }
    // function selectShipment(a){
    //     const id = document.getElementById(a);
    //     if(id.style.display == 'block'){
    //         id.style.display = ' none';
    //     }else{
    //         id.style.display = 'block';
    //     }
    // }
    // function showService(index, id){
    //     useTransactionStore().changeShipmentOption(index, id);
    //     const target = document.getElementById('sos-' + index);
    //     target.parentElement.parentElement.scrollLeft = target.parentElement.parentElement.scrollWidth;
    // }
    // function backToShipment(id){
    //     document.getElementById(id).scrollLeft = 0;
    // }
    // function orderChatDoctor(slug, specialist){
    //     useChatStore().setChatDoctor(slug, specialist);
    // }
</script>

<style scoped>
    section{
        padding-top: 2.5%;
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 2.5%;
        width: 100%;
        transition: 0.2s;
        color: var(--grey);
        transition: 0.2s;
    }
    section h1{
        color: var(--white);
        margin: 0;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 1rem;
    }
    .section-body{
        display: flex;
        transition: 0.2s;
    }
    .sb-inner{
        width: 70%;
        padding-right: 1rem;
        transition: 0.2s;
    }
    .sa-card{
        width: 100%;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
        margin-bottom: 1rem;
        border-radius: 5px;
        padding: 2%;
        padding-top: 2%;
        padding-bottom: 2%;
        background: var(--white);
    }
    .sa-card h2{
        margin: 0;
        margin-bottom: 1rem;
        font-size: 0.9rem;
    }
    .sa-card h3{
        margin: 0;
        margin-bottom: 0.5rem;
        font-size: 1rem;
    }
    .sa-card p{
        margin: 0;
        margin-bottom: 0.5rem;
    }
    .sa-button{
        margin-top: 2rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .sa-button span{
        background: var(--primary);
        color: var(--white);
        padding: 0.5rem;
        padding-left: 2rem;
        padding-right: 2rem;
        border-radius: 5px;
        cursor: pointer;
        user-select: none;
    }
    .address-loader{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation: pulse-bg-transparent 1s infinite;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--grey);
    }
    .modal-content-head{
        height: 10%;
    }
    .modal-content-head h2{
        margin: 0;
    }
    .modal-content-body{
        display: flex;
        flex-wrap: wrap;
        height: 90%;
        overflow: auto;
    }
    .address-list{
        width: 66%;
        height: 100%;
        overflow: auto;
        padding: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;
        border: 1px solid var(--softgrey);
        transition: 0.2s;
    }
    .address-panel{
        width: 34%;
        height: 100%;
        overflow: auto;
        padding-left: 1rem;
        transition: 0.2s;
    }
    .address-panel-button{
        padding-top: 1rem;
        padding-bottom: 1rem;
        white-space: nowrap;
        text-align: left;
    }
    .address-panel-button span{
        background: var(--primary);
        color: var(--white);
        padding: 0.5rem;
        padding-left: 2rem;
        padding-right: 2rem;
        border-radius: 5px;
        cursor: pointer;
        user-select: none;
    }
    .address-panel-form{
        display: none;
        text-align: left;
        transition: max-height 0.2s ease-in-out;
    }
    .address-list-card{
        width: 100%;
        background: var(--white);
        padding: 0.5rem;
        text-align: left;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        margin-bottom: 1rem;
    }
    .alc-inner{
        display: flex;
    }
    .address-list-check{
        width: 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        user-select: none;
    }
    .alc-box{
        width: 20px;
        height: 20px;
        border: 1px solid var(--softgrey);
        border-radius: 2px;
        cursor: pointer;
        transition: 0.1s;
    }
    .alc-box:hover{
        border: 2px solid var(--primary);
    }
    .alc-box img{
        width: 100%;
    }
    .address-list-detail{
        width: 75%;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .ald-label{
        margin-bottom: 1rem;
    }
    .ald-set-main{
        color: var(--primary);
        cursor: pointer;
        transition: 0.2s;
    }
    .ald-set-main:hover{
        color: var(--primary-hover);
    }
    .ald-street{
        font-style: italic;
    }
    .address-list-action{
        width: 15%;
        user-select: none;
    }
    .address-list-action span{
        cursor: pointer;
    }
    .ala-delete{
        display: flex;
        align-items: center;
        justify-content: center;
        color: brown;
        margin-top: 2rem;
    }
    .ala-edit{
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--grey);
        margin-top: 2rem;
    }
    .alc-form{
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-in-out;
    }
    .alc-form-inner{
        padding: 1rem;
        border: 1px solid var(--softgrey);
        margin-top: 1rem;
    }
    .afi-input{
        margin-bottom: 0.5rem;
    }
    .afi-input-input input{
        width: 100%;
        height: 2rem;
        padding: 1%;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        border: 1px solid var(--softgrey);
        outline: none;
        color: var(--grey);
    }
    .aii-select{
        position: relative;
    }
    .aii-select-button{
        display: flex;
        flex-wrap: nowrap;
        border: 1px solid var(--softgrey);
        cursor: pointer;
        padding: 0.5rem;
        user-select: none;
    }
    .asb-text{
        width: 90%;
        text-align: left;
    }
    .asb-icon{
        width: 10%;
        text-align: right;
    }
    .aii-option-button{
        padding: 0.5rem;
        cursor: pointer;
    }
    .aii-option{
        display: none;
        position: absolute;
        top: 115%;
        left: 0;
        width: 100%;
        max-height: 200px;
        overflow: auto;
        background: var(--white);
        border: 1px solid var(--softgrey);
        padding: 0.5rem;
        z-index: 1;
    }
    .afi-input-input textarea{
        width: 100%;
        height: 5rem;
        outline: none;
        border: 1px solid var(--softgrey);
        color: var(--grey);
        padding: 1%;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .afi-input-button{
        padding-top: 1rem;
    }
    .afi-input-button span{
        padding: 0.5rem;
        padding-left: 2rem;
        padding-right: 2rem;
        color: var(--white);
        background: var(--primary);
        cursor: pointer;
        transition: 0.2s;
        border-radius: 5px;
        user-select: none;
    }
    .afi-input-button span:hover{
        background: var(--primary-hover);
    }
    .payment-list{
        width: 75%;
        height: 90%;
        overflow: auto;
        padding: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;
        border: 1px solid var(--softgrey);
    }
    .payment-list-method h3{
        text-align: left;
        margin: 0;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }
    .payment-list-card{
        width: 100%;
        background: var(--white);
        padding: 0.5rem;
        text-align: left;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        margin-bottom: 1rem;
    }
    .payment-list-check{
        width: 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        user-select: none;
    }
    .payment-list-detail{
        width: 90%;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .payment-panel{
        width: 25%;
        text-align: left;
    }
    .payment-summary{
        padding: 1rem;
    }
    .payment-panel-button{
        padding: 1rem;
    }
    .payment-warning{
        color: red;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .payment-panel a{
        text-decoration: none;
    }
    .pay-button{
        background: var(--primary);
        color: var(--white);
        padding: 0.5rem;
        padding-left: 5rem;
        padding-right: 5rem;
        border-radius: 5px;
        cursor: pointer;
        user-select: none;
    }
    .pay-button-false{
        background: var(--grey);
        color: var(--white);
        cursor: not-allowed;
    }
    .pay-button-process{
        background: var(--grey);
        color: var(--white);
        font-size: 0.75rem;
        cursor: not-allowed;
    }
    .sbi-card{
        border-radius: 5px;
        padding: 2%;
        margin-bottom: 1rem;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
        transition: 0.2s;
        background: var(--white);
    }
    .sc-head{
        border-bottom: 1px solid var(--grey);
    }
    .sc-head h2{
        font-size: 1rem;
    }
    .sc-body{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    .sc-body-product{
        width: 66%;
        padding-right: 1rem;
    }
    .product-list{
        width: 100%;
        border-bottom: 1px solid var(--softgrey);
        padding: 2.5%;
    }
    .product-list-services{
        border-bottom: none;
    }
    .pl-body{
        display: flex;
        flex-wrap: wrap;
    }
    .sbc-icon{
        width: 20px;
        height: 20px;
        border: 2px solid var(--softgrey);
        border-radius: 2px;
    }
    .sc-body-image{
        width: 30%;
        padding: 1%;
        padding-left: 0;
        transition: 0.2s;
    }
    .sc-body-image img{
        width: 100%;
        border-radius: 10px;
    }
    .sc-body-description{
        width: 70%;
        transition: 0.2s;
        padding: 0.5rem;
    }
    .sc-body-qty{
        margin-top: 1rem;
        color: blue;
    }
    .sbq-unit{
        padding-left: 0.5rem;
    }
    .sc-body-price{
        padding-top: 0.5rem;
        color: brown;
    }
    .pl-footer{
        display: flex;
        flex-wrap: wrap;
    }
    .pf-text{
        width: 60%;
        transition: 0.2s;
    }
    .sc-body-shipment{
        width: 34%;
        transition: 0.2s;
    }
    .shipment{
        position: relative;
        width: 100%;
    }
    .shipment-inner{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 3.7rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    .shipment-btn{
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        height: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
        border: 1px solid var(--softgrey);
        cursor: pointer;
        user-select: none;
        align-items: center;
    }
    .shipment-btn-text{
        width: 90%;
    }
    .shipment-btn-icon{
        width: 10%;
        text-align: right;
        padding: 0.1rem;
    }
    .shipment-option{
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        overflow: hidden;
        display: none;
        border: 1px solid var(--softgrey);
        background: var(--white);
        z-index: 15;
        scroll-behavior: smooth;
    }
    .shipment-option-inner{
        display: flex;
    }
    .shipment-option-part{
        min-width: 100%;
        overflow: auto;
        padding: 5%;
    }
    .shipment-list{
        margin-bottom: 0.5rem;
        cursor: pointer;
        padding: 0.2rem;
    }
    .shipment-list:hover{
        background: var(--smoke);
    }
    .shipment-option-service{
        overflow: hidden;
    }
    .shipment-selected{
        padding-top: 5rem;
    }
    .sos-head{
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    .sos-head span{
        cursor: pointer;
        background: var(--white);
        color: var(--softgrey);
        padding: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        border: 1px solid var(--softgrey);
        border-radius: 5px;
        transition: 0.2s;
        height: 10%;
    }
    .sos-head span:hover{
        background: var(--smoke);
    }
    .sos-body{
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        height: 75%;
        overflow: auto;
    }
    .sod-list{
        cursor: pointer;
        padding: 0.2rem;
    }
    .sod-list:hover{
        background: var(--smoke);
    }
    .sb-button{
        position: relative;
        width: 30%;
        transition: 0.2s;
    }
    .sbb-inner{
        border-radius: 5px;
        min-height: 100px;
        z-index: 10;
        background: var(--white);
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
    }
    .cart-summary{
        padding: 1rem;
    }
    .cs-inner h2{
        margin: 0;
        margin-bottom: 0.5rem;
        font-size: 1rem;
    }
    .cs-row{
        display: flex;
        flex-wrap: nowrap;
        padding: 1% 0 1% 0;
    }
    .cs-row-param{
        width: 50%;
    }
    .cs-row-value{
        width: 50%;
    }
    .buy-button{
        position: relative;
        width: 100%;
        height: 50px;
    }
    .buy-button span{
        position: absolute;
        left: 1rem;
        bottom: 1rem;
        padding: 1%;
        padding-left: 2rem;
        padding-right: 2rem;
        background: var(--primary);
        color: var(--white);
        border-radius: 2px;
        cursor: pointer;
        user-select: none;
    }
    .cart-warning{
        color: red;
        padding: 0 1rem 0 1rem;
    }
    .buy-button-false{
        padding-top: 2rem;
    }
    .buy-button-false span{
        background: var(--softgrey);
        color: var(--grey);
        cursor: not-allowed;
    }
    .empty-message{
        color: var(--white);
        font-style: italic;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    @media only screen and (max-width: 1100px){
        .modal-content-body{
            border: 1px solid var(--softgrey);
        }
        .address-list{
            width: 100%;
            height: auto;
            border: none;
            padding-left: 1rem;
            padding-right: 1rem;
        }
        .address-panel{
            width: 100%;
            min-height: 100%;
            height: auto;
            padding-left: 1rem;
            padding-right: 1rem;
            margin-bottom: 1rem;
        }
        .payment-list{
            width: 100%;
            height: auto;
            border: none;
            padding-left: 1rem;
            padding-right: 1rem;
        }
        .payment-panel{
            width: 100%;
            min-height: 50%;
            height: auto;
            padding-left: 1rem;
            padding-right: 1rem;
        }
        .sc-body-check{
            width: 10%;
        }
        .sc-body-image{
            width: 27%;
        }
        .sc-body-description{
            width: 63%;
        }
    }
    @media only screen and (max-width: 1050px){
        .section-body{
            display: inline;
        }
        .sb-inner{
            width: 100%;
            padding-right: 0;
        }
        .sc-body-product{
            width: 100%;
        }
        .sc-body-shipment{
            width: 50%;
        }
        .sb-button{
            width: 100%;
            min-height: 310px;
        }
        .sbb-inner{
            border: 1px solid var(--softgrey);
            border-radius: 0;
            box-shadow: unset;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding-left: 2.5%;
            padding-right: 2.5%;
            background: var(--white);
        }
        section h1{
            color: var(--grey);
        }
        .sa-card{
            border-radius: 5px;
        }
        .sc-body{
            margin-bottom: 5rem;
            padding-left: 0;
        }
        .sc-body-product{
            padding-right: 0;
        }
        .product-list{
            padding-left: 0;
            padding-right: 0;
        }
        .sbi-card{
            box-shadow: unset;
            padding: 0;
        }
        .sc-body-check{
            width: 10%;
        }
        .sc-body-image{
            width: 50%;
        }
        .sc-body-description{
            width: 50%;
        }
        .sc-body-shipment{
            width: 100%;
        }
        .pf-text{
            width: 100%;
        }
        .pf-buttons{
            width: 100%;
        }
        .payment-summary{
            padding-left: 0;
        }
        .payment-panel-button{
            padding-left: 0;
        }
    }
    @media only screen and (max-width: 370px){
        .sc-body-check{
            width: 10%;
        }
        .sc-body-image{
            width: 100%;
        }
        .sc-body-description{
            width: 100%;
            padding: 0;
        }
    }
</style>