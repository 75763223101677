<template>
    <section>
        <div class="section-header">
            <!-- <h2>Kategori Obat</h2> -->
        </div>
        <div class="section-body">
            <template v-if="categories">
                <FilteredMedicines v-if="filteredMedicines"/>
                <template v-else>
                    <template v-if="!searchbarCategories.input.value">
                        <div class="src-inner">
                            <div class="src-card" v-for="category in categories" :key="category">
                                <router-link :to="'/obat/kategori/' + category.slug" @click="pageScrollTop" >
                                    <div class="src-card-inner">
                                        <div class="sci-icon">
                                            <img :src="category.image" alt="melinda care">
                                        </div>
                                        <div class="sci-descript">
                                            <h3>{{ category.name }}</h3>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="search-not-found">
                            <p>Tidak ditemukan obat dengan kata kunci</p>
                            <div v-if="searchbarCategories.input.value">"{{ searchbarCategories.input.value }}"</div>
                            <div v-if="searchbarSelectCategory.input.value.length > 0">"{{ searchbarSelectCategory.input.value }}"</div>
                            <div v-if="searchbarCategories.select.value != 'all'">dalam {{ searchbarCategories.select.title }}</div>
                            <div v-else>dalam Semua Kategori</div>
                        </div>
                    </template>
                </template>
            </template>
            <MedicineCategorySkeleton v-else/>
        </div>
    </section>
</template>
<script setup>
    import MedicineCategorySkeleton from "../skeletons/MedicineCategorySkeleton.vue";
    import FilteredMedicines from "@/components/medicine/FilteredMedicinesComponent.vue";
    import { pageScrollTop } from "@/functions/general";
    import { computed } from "@vue/runtime-core";
    import { useMedicineStore } from '@/stores/medicine';
    const filteredMedicines = computed(() => useMedicineStore().filteredMedicines);
    const categories = computed(() => useMedicineStore().categories);
    const searchbarCategories = computed(() => useMedicineStore().searchbarCategories);
    const searchbarSelectCategory = computed(() => useMedicineStore().searchbarSelectCategory);
    useMedicineStore().setCategories();
</script>
<style scoped>
    section{
        padding-left: 9%;
        padding-right: 9%;
        padding-bottom: 2.5%;
        width: 100%;
        transition: 0.2s;
    }
    .section-header{
        padding: 1%;
        padding-top: 0;
    }
    .section-header h2{
        font-family: poppins;
        color: var(--white);
        margin: 0;
    }
    .section-body{
        width: 100%;
    }
    .src-inner{
        display: flex;
        flex-wrap: wrap;
        transition: 0.2s;
    }
    .src-card{
        width: 25%;
        height: 75px;
        padding: 1%;
        transition: 0.2s;
        user-select: none;
    }
    .src-card a{
        text-decoration: none;
        color: var(--grey);
    }
    .src-card-inner{
        display: flex;
        align-items: center;
        height: 100%;
        padding: 1%;
        background: var(--white);
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        overflow: hidden;
    }
    .sci-icon{
        width: 30%;
        height: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sci-icon img{
        max-width: 100%;
        max-height: 100%;
    }
    .sci-descript{
        color: var(--grey);
        padding-left: 5%;
    }
    .sci-descript h3{
        font-size: 0.9rem;
    }
    .sb-inner{
        display: flex;
        flex-wrap: wrap;
    }
    .sb-card{
        position: relative;
        min-width: 100px;
        width: 25%;
        padding: 1%;
        transition: 0.2s;
    }
    .sb-card a{
        text-decoration: none;
        color: #575757;
    }
    .sbc-content{
        display: flex;
        border: 1px solid rgb(230, 227, 227);
        border-radius: 10px;
        overflow: hidden;
        height: 100px;
        padding: 5%;
        background: whitesmoke;
    }
    .sbc-image{
        display: flex;
        width: 25%;
        height: 100%;
        overflow: hidden;
        align-items: center;
    }
    .sbc-image img{
        height: 100%;
    }
    .sbc-detail{
        width: 75%;
        padding: 5%;
        display: flex;
        align-items: center;
    }
    .sbc-detail h3{
        font-family: poppins;
        text-align: center;
        color: #575757;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin: 0;
    }
    .search-not-found{
        font-family: Arial, Helvetica, sans-serif;
        font-style: italic;
        text-align: center;
        color: var(--grey);
        word-break: break-all;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding-left: 4%;
            padding-right: 4%;
        }
        .src-card-inner{
            border-radius: 5px;
        }
    }
    @media only screen and (max-width: 1415px){
        .sb-card{
            width: 33.33%;
        }
    }
    @media only screen and (max-width: 950px){
        .sb-card{
            width: 50%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding-left: 1%;
            padding-right: 1%;
        }
        .section-header h2{
            color: var(--grey);
        }
        .src-card{
            width: 50%;
            margin-bottom: 1rem;
        }
    }
    @media only screen and (max-width: 650px){
        .sb-card{
            min-width: 250px;
        }
    }
    @media only screen and (max-width: 550px){
        .sb-card{
            width: 100%;
        }
    }
    @media only screen and (max-width: 450px){
        .src-card{
            width: 100%;
        }
    }
</style>