<template>
    <section v-if="medicineList">
        <div class="section-header">
            <h2>{{ medicineList.name }}</h2>
        </div>
        <div class="section-body">
            <div class="sb-inner" v-if="medicineList.medicines.length > 0">
                <div class="sb-card" v-for="medicine in medicineList.medicines" :key="medicine">
                    <router-link :to="'/obat/detail/' + medicine.slug" @click="pageScrollTop">
                        <div class="sbc-content">
                            <!-- <div class="sbc-label">
                                <span>{{ medicineList.name }}</span>
                            </div> -->
                            <div class="sbc-image">
                                <img :src="medicine.images[0].image" alt="melinda care" />
                            </div>
                            <div class="sbc-detail">
                                <h3>{{ medicine.name }}</h3>
                                <div class="sd-category">
                                    <span>{{ medicineList.name }}</span>
                                </div>
                                <!-- <div class="sbc-description">
                                    <p>{{ wordsLimit(removeHTML(medicine.description), 100) }}</p>
                                </div> -->
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="sb-inner" v-else>
                <p class="empty-message">Tidak ditemukan obat pada kategori ini</p>
            </div>
        </div>
    </section>
    <MedicineListSkeleton v-else/>
</template>
<script setup>
    import MedicineListSkeleton from '../skeletons/MedicineListSkeleton.vue';
    import { useRoute } from 'vue-router';
    import { computed } from "@vue/runtime-core";
    import { useMedicineStore } from '@/stores/medicine';
    import { pageScrollTop } from '@/functions/general';
    const medicineList = computed(() => useMedicineStore().medicineList);
    useMedicineStore().setMedicineList(useRoute().params.category);
</script>
<style scoped>
    section{
        padding-left: 9%;
        padding-right: 9%;
        padding-bottom: 2.5%;
        width: 100%;
        transition: 0.2s;
    }
    .section-header{
        padding: 1%;
        padding-top: 0;
    }
    .section-header h2{
        font-family: poppins;
        color: var(--white);
        margin: 0;
    }
    .section-body{
        width: 100%;
    }
    .sb-inner{
        display: flex;
        flex-wrap: wrap;
    }
    .sb-card{
        position: relative;
        min-width: 290px;
        width: 25%;
        padding: 1%;
        transition: 0.2s;
        user-select: none;
    }
    .sb-card a{
        text-decoration: none;
        color: #575757;
    }
    .sbc-label{
        position: absolute;
        top: 0;
        left: 0;
    }
    .sbc-label span{
        padding: 5%;
        padding-top: 10%;
        padding-left: 10px;
        padding-right: 10px;
        background: rgb(58, 179, 58);
        color: var(--white);
        border-radius: 0 0 15px 0;
    }
    .sbc-content{
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        height: 370px;
        background: var(--white);
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .sbc-image{
        width: 100%;
        min-height: 150px;
        height: 41%;
        overflow: hidden;
        background: lightgrey;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sbc-image img{
        width: 100%;
    }
    .sbc-detail{
        padding: 2.5%;
    }
    .sbc-detail h3{
        font-family: poppins;
        text-align: left;
        color: #575757;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin: 0;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }
    .sd-category{
        background: var(--primary);
        color: var(--white);
        padding: 0.5rem;
        font-family: 'Courier New', Courier, monospace;
        font-size: 0.9rem;
        min-height: 75px;
    }
    /* .sbc-detail p{
        font-family: poppins-light;
        font-weight: 600;
        font-size: 0.9rem;
        text-align: justify;
        color: #575757;
        margin: 0;
    } */
    .empty-message{
        width: 100%;
        color: var(--white);
        padding: 1%;
        font-size: 1rem;
        font-style: italic;
        text-align: center;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding-left: 4%;
            padding-right: 4%;
        }
    }
    @media only screen and (max-width: 1415px){
        .sb-card{
            width: 25%;
        }
    }
    @media only screen and (max-width: 950px){
        .sb-card{
            width: 50%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding-left: 1%;
            padding-right: 1%;
        }
    }
    @media only screen and (max-width: 650px){
        .sb-card{
            min-width: 250px;
        }
    }
    @media only screen and (max-width: 550px){
        .sb-card{
            width: 100%;
        }
    }
</style>