import { defineStore } from "pinia";
import axiosClient from "../axios.js";
import { setCookie, getCookie, generateRandomHexString } from "@/functions/general.js";
import { sessionExpiration } from '@/equipments/settings';

export const useSpecialPageStore = defineStore("specialpage", {
    state: () => ({
        // ==================== states used on special page, home, doctor profile and its relation
        data: null,
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on special page, home, doctor profile and its relation
        async reportConversion(params){
            if(getCookie('session')){
                setCookie('session', getCookie('session'), sessionExpiration);
            }else{
                setCookie('session', generateRandomHexString(64), sessionExpiration);
            }
            const search = new URLSearchParams(window.location.search);
            let allParams = {};
            search.forEach((value, key) => {
                allParams[key] = value;
            });
            for (let key in params) {
                if(key !== 'slug' && key !== 'path'){
                    console.log(key);
                    allParams[key] = params[key];
                }
            }
            allParams.page_name = document.title;
            allParams.page_from = window.location.origin + window.location.pathname;
            try{
                await axiosClient.post(`/insight/static/${params.slug}`, allParams, {
                    headers: {
                        "Page": window.location.origin + params.path
                    }
                });
            }catch(error){
                console.log(error);
            }
        }
    }
});