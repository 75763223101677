import axios from "axios";
import { getCookie, setCookie, generateRandomHexString } from "./functions/general";
import { sessionExpiration } from '@/equipments/settings';
if(getCookie('session')){
  setCookie('session', getCookie('session'), sessionExpiration); // renew the cookie
}else{
  setCookie('session', generateRandomHexString(64), sessionExpiration);
}
// if(getCookie('currentLocation')){
//   setCookie('currentLocation', getCookie('currentLocation'), sessionExpiration); // renew the cookie
// }else{
//   setCookie('currentLocation', window.location.href, sessionExpiration);
// }
const axiosClient = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API + 'api/v1/',
  headers: { "Content-Type": "application/json", Accept: "application/json", "Cookie-Id": getCookie('session') }
});

axiosClient.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${sessionStorage.getItem("authToken")}`;
  return config;
});

export default axiosClient;

