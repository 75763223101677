import { defineStore } from "pinia";
// import axiosOne from "../axios_one.js";
// import axiosFormData from "../axiosformdata.js";
// import { showNotification, showNotificationConfirmation } from "@/functions/general.js";

export const useNotificationStore = defineStore("notification", {
    state: () => ({
        // ==================== states used on event scanner page and its relation
        notificationData: null,
        notificationConfirmationData: null,
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on event scanner page and its relation
        setNotificationData(data){
            try{
                this.notificationData = data;
            }catch(error){
                console.log(error);
            }
        },
        setNotificationConfirmationData(data){
            try{
                this.notificationConfirmationData = data;
            }catch(error){
                console.log(error);
            }
        },
    }
});