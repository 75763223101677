<template>
    <div class="section-content">
        <div class="sc-inner">
            <div class="sci-left">
                <div class="sl-head">
                    <p class="small-title">Melinda Care</p>
                    <h1 class="main-title"></h1>
                    <p class="p-date"></p>
                </div>
                <div class="sl-body">
                    <div class="sl-img">
                    </div>
                    <div class="sl-content">
                        <p class="p-100"></p>
                        <p class="p-100"></p>
                        <p class="p-100"></p>
                        <p class="p-100"></p>
                        <p class="p-100"></p>
                        <p class="p-75"></p>
                        <p class="p-100"></p>
                        <p class="p-100"></p>
                        <p class="p-100"></p>
                        <p class="p-100"></p>
                        <p class="p-100"></p>
                        <p class="p-50"></p>
                    </div>
                </div>
                <div class="sc-share">
                    <h2>Share to</h2>
                    <div class="sc-share-inner">
                        <div class="scs-part">
                            <a href="https://www.facebook.com" target="_blank">
                                <img src="../../assets/images/icons/cc-facebook.png" alt="food traveller">
                            </a>
                        </div>
                        <div class="scs-part">
                            <a href="https://www.twitter.com" target="_blank">
                                <img src="../../assets/images/icons/cc-twitter.png" alt="food traveller">
                            </a>
                        </div>
                        <div class="scs-part">
                            <a href="https://www.linkedin.com" target="_blank">
                                <img src="../../assets/images/icons/cc-linkedin.png" alt="food traveller">
                            </a>
                        </div>
                        <div class="scs-part">
                            <a href="https://www.instagram.com" target="_blank">
                                <img src="../../assets/images/icons/cc-instagram.png" alt="food traveller">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sci-right">
                <div class="sr-order">
                    <div class="sr-order-head">
                        <div class="soh-panel-button">Sembunyikan</div>
                    </div>
                    <div class="sr-order-body mhresize" id="sr-order-body">
                        <div class="sr-order-body-inner">
                            <div class="so-row">
                                <div class="so-row-param">Harga</div>
                                <div class="so-row-value">Rp.....</div>
                            </div>
                            <div class="so-row">
                                <div class="so-row-param">Jadwal</div>
                                <div class="so-row-value">
                                    <div class="srv-select">
                                        <div class="srv-button trigger">
                                            <div class="srv-button-text trigger">
                                                <div class="sbt-price trigger" style="width: 100%;">
                                                    <span class="med-price trigger">Pilih Jadwal</span>
                                                </div>
                                                <div class="sbt-unit trigger" style="width: 0;">
                                                    <span class="trigger"></span>
                                                </div>
                                            </div>
                                            <div class="srv-button-icon trigger" id="srv-icon-service">
                                                <img src="../../assets/images/icons/arrow-1.png" alt="melinda care" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="so-cart">
                                <div class="so-cart-buy">
                                    <button class="buy-button">Reservasi</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sr-part sr-categories">
                    <h2>Categories</h2>
                    <div class="sr-link">
                        <router-link to="">
                            <h3 class="sr-link-50"></h3>
                        </router-link>
                    </div>
                    <div class="sr-link">
                        <router-link to="">
                            <h3 class="sr-link-37"></h3>
                        </router-link>
                    </div>
                    <div class="sr-link">
                        <router-link to="">
                            <h3 class="sr-link-37"></h3>
                        </router-link>
                    </div>
                    <div class="sr-link">
                        <router-link to="">
                            <h3 class="sr-link-25"></h3>
                        </router-link>
                    </div>
                    <div class="sr-link">
                        <router-link to="">
                            <h3 class="sr-link-25"></h3>
                        </router-link>
                    </div>
                </div>
                <!-- <div class="sr-part sr-tag">
                    <h2>Tags</h2>
                    <div class="sr-tag-inner">
                        <router-link to="">Kesehatan</router-link>
                        <router-link to="">Obat</router-link>
                        <router-link to="">Penyakit</router-link>
                        <router-link to="">Nutrisi</router-link>
                        <router-link to="">Makanan</router-link>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script setup>
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
</script>

<style scoped>
    .small-title{
        color: #75C6EF;
        font-weight: 600;
        font-size: 1.125rem;
    }
    .main-title{
        font-family: poppins;
        font-size: 3.25rem;
        font-weight: 700;
        margin-top: 1rem;
        margin-bottom: 1rem;
        transition: 0.2s;
        line-height: 1.2;
        animation: pulse-bg-navy 1s infinite;
        width: 75%;
        height: 3rem;
        border-radius: 3rem;
    }
    .p-date{
        margin: 0;
        margin-top: 1.6875rem;
        font-size: 1rem;
        font-weight: 400;
        width: 45%;
        height: 1.5rem;
        transition: 0.2s;
        animation: pulse-bg 1s infinite;
        border-radius: 1.5rem;
    }
    .section-content{
        width: 100%;
        height: 100%;
        padding-left: 10%;
        padding-right: 10%;
        transition: 0.2s;
    }
    .sc-inner{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        padding-top: 2rem;
        transition: 0.2s;
    }
    .sci-left{
        width: 60%;
        transition: 0.2s;
    }
    .sl-body{
        padding-top: 2rem;
    }
    .sl-img{
        margin-bottom: 2rem;
        animation: pulse-bg 1s infinite;
        width: 100%;
        min-height: 500px;
    }
    .sl-content{
        text-align: justify;
    }
    .p-100{
        width: 100%;
        height: 1rem;
        animation: pulse-bg 1s infinite;
        border-radius: 1rem;
    }
    .p-75{
        width: 75%;
        height: 1rem;
        animation: pulse-bg 1s infinite;
        border-radius: 1rem;
    }
    .p-50{
        width: 50%;
        height: 1rem;
        animation: pulse-bg 1s infinite;
        border-radius: 1rem;
    }
    .sc-share{
        margin-top: 2rem;
    }
    .sc-share h2{
        font-family: poppins;
        font-size: 1rem;
        font-weight: 700;
    }
    .sc-share-inner{
        display: flex;
        flex-wrap: nowrap;
        padding-top: 1rem;
    }
    .scs-part{
        margin-right: 1rem;
    }
    .sci-right{
        width: 40%;
        padding-top: 36rem;
        padding-left: 5rem;
        transition: 0.2s;
    }
    .sr-order{
        position: relative;
        width: 100%;
        background: var(--white);
        margin-bottom: 2rem;
        z-index: 1;
    }
    .sr-order-head{
        position: relative;
        min-height: 27px;
        z-index: 5;
    }
    .soh-panel-button{
        position: absolute;
        top: 0;
        right: 0;
        display: none;
        padding: 10px;
        padding-bottom: 0;
        background: var(--white);
        border-top: 1px solid var(--smoke);
        border-top-left-radius: 10px;
        z-index: 5;
        transition: 0.2s;
    }
    .sr-order-body{
        max-height: 500px;
        transition: max-height 0.2s ease-in-out;
        z-index: 10;
    }
    .so-row{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 1rem;
    }
    .so-row-param{
        width: 37%;
    }
    .so-row-value{
        width: 63%;
    }
    .srv-select{
        position: relative;
        user-select: none;
    }
    .srv-button{
        display: flex;
        cursor: pointer;
        padding: 0.5rem;
        border: 1px solid var(--softgrey);
        border-radius: 5px;
    }
    .srv-button-text{
        display: flex;
        width: 90%;
    }
    .srv-button-text .sbt-price{
        padding-left: 0.5rem;
    }
    .srv-button-text .sbt-unit{
        padding-left: 1rem;
    }
    .sbt-price{
        width: 50%;
    }
    .sbt-unit{
        width: 50%;
    }
    .srv-button-icon{
        width: 10%;
        text-align: center;
    }
    .srv-button-icon img{
        transition: 0.2s;
    }
    .srv-option{
        position: absolute;
        top: 115%;
        left: 0;
        width: 100%;
        border: 1px solid var(--softgrey);
        border-radius: 5px;
        background: var(--white);
        display: none;
        padding: 0.5rem;
    }
    .srv-option-part{
        display: none;
    }
    .sop-active{
        display: block;
    }
    .sop-schedule-head{
        display: flex;
        margin-bottom: 1rem;
    }
    .ssh-button{
        width: 15%;
    }
    .ssh-button-btn{
        padding: 0.5rem;
        cursor: pointer;
        user-select: none;
        font-size: 0.9rem;
    }
    .ssh-title{
        width: 85%;
        text-align: center;
    }
    .ssh-title-btn{
        padding: 0.5rem;
        cursor: pointer;
        user-select: none;
        font-size: 0.9rem;
    }
    .srv-option-button{
        display: flex;
        padding: 0.5rem;
        cursor: pointer;
        transition: 0.2s;
    }
    .srv-option-button:hover{
        background: var(--smoke);
    }
    .med-price{
        color: blue;
    }
    .ssb-inner-false{
        min-height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.9rem;
        font-style: italic;
        color: var(--softgrey);
    }
    .service-calendar-head{
        display: flex;
        text-align: center;
    }
    .sch-button{
        width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: no;
    }
    .sch-title{
        width: 80%;
    }
    .scb-day{
        width: 100%;
        display: flex;
    }
    .scb-day-name{
        width: 30%;
        padding: 0.5rem;
        padding-left: 0;
    }
    .scb-day-date{
        width: 70%;
        display: flex;
    }
    .sdd-num{
        width: 15%;
        text-align: center;
    }
    .sdd-num-inner{
        position: relative;
    }
    .sni-available{
        position: absolute;
        width: 10px;
        height: 10px;
        top: 5px;
        right: 5px;
        background: rgba(39, 108, 197, 0.753);
        border-radius: 10px;
    }
    .sni-true{
        cursor: pointer;
    }
    .sni-true:hover{
        background: var(--smoke);
    }
    .sni-core{
        padding: 0.5rem;
        color: var(--grey);
    }
    .sni-core-active{
        background: var(--primary);
        color: var(--white);
    }
    .sni-core-outofrange{
        padding: 0.5rem;
    }
    .so-cart-buy{
        width: 50%;
        display: flex;
        justify-content: left;
    }
    .buy-button{
        /* background: var(--grey); */
        color: var(--white);
        padding: 0.5rem;
        min-width: 100px;
        border: none;
        cursor: not-allowed;
        animation: pulse-bg 1s infinite;
    }
    .sr-part h2{
        font-family: poppins;
        font-weight: 700;
        font-size: 1.25rem;
        color: #bbbbbb;
    }
    .sr-categories{
        width: 100%;
        transition: 0.2s;
        margin-bottom: 2rem;
    }
    .sr-link{
        margin-top: 1rem;
    }
    .sr-link a{
        color: #1B4674;
        text-decoration: none;
    }
    .sr-link h3{
        height: 1.2rem;
        border-radius: 1.2rem;
        animation: pulse-bg-navy 1s infinite;
    }
    .sr-link-50{
        width: 50%;
    }
    .sr-link-37{
        width: 37%;
    }
    .sr-link-25{
        width: 25%;
    }
    .sr-tag{
        width: 100%;
        transition: 0.2s;
        margin-bottom: 2rem;
    }
    .sr-tag-inner{
        display: flex;
        flex-wrap: wrap;
        padding-top: 1rem;
        width: 100%;
    }
    .sr-tag-inner a{
        font-size: 1rem;
        color: #575757;
        border: 1px solid #575757;
        border-radius: 50px;
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        text-decoration: none;
        margin-top: 1rem;
        margin-right: 1rem;
    }
    @media only screen and (max-width: 1500px){
        .section-content{
            padding-left: 5%;
            padding-right: 5%;
        }
        .main-title{
            font-size: 2.6rem;
        }
        .p-date{
            margin-top: 1rem;
        }
        .sci-right{
            padding-top: 27rem;
        }
    }
    @media only screen and (max-width: 1050px){
        .sc-inner{
            padding-top: 0;
        }
        .sci-left{
            width: 100%;
        }
        .sl-img img{
            width: 100%;
        }
        .main-title{
            font-size: 2rem;
        }
        .p-date{
            width: auto;
        }
        .sci-right{
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding-top: 2rem;
            padding-left: 0;
        }
        .sr-categories{
            width: 50%;
            margin-bottom: 0;
        }
        .sr-tag{
            width: 50%;
            margin-bottom: 0;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding-top: 1rem;
        }
        .section-content{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .sci-right{
            padding-top: 1rem;
        }
        .so-row-param{
            width: 100%;
            margin-bottom: 0.5rem;
        }
        .so-row-value{
            width: 100%;
        }
        .sr-categories{
            width: 100%;
        }
        .sr-tag{
            width: 100%;
        }
    }
</style>