import { defineStore } from "pinia";
import axiosClient from "../axios.js";
import { setPageData, wordsLimit, removeHTML } from "@/functions/general.js";

export const useFacilityStore = defineStore("facility", {
    state: () => ({
        // ==================== states used on fasilitas kesehatan page and its relation
        allFacilities: null,
        filteredFacilities: null,
        searchbarSelectCategory: {input: {value: ''}},
        searchbarCategory: {select: {title: 'Semua', value: 'all'}, input: {value: null}},
        ourSites: null,
        // =====================
        facilities: null,
        activeCategory: null,
        healthFacilityByCategory: null,
        facilityDetail: null,
        categories: null,
        filteredCategories: null
    }),
    getters: {
        // =====
    },
    actions: {
        // ==================== actions used on fasilitas kesehatan Page and its relation
        async getAllFacilities(){
            // this.resetFilter();
            try{
                if(!this.allFacilities){
                    this.filteredFacilities = null;
                    const response = await axiosClient.get(`facilities`);
                    this.allFacilities = response.data.data;
                    // console.log(this.allFacilities);
                    // console.log('size : ' + roughSizeOfObject(this.allFacilities) + ' bytes');
                }
            }catch(error){
                console.log(error);
            }
        },
        async setFacilities(){
            const response = await axiosClient.get(`healthfacility/content`);
            this.facilities = response.data;
            // this.getAllFacilities();
        },
        async setHfbc(slug){
            const response = await axiosClient.get(`healthfacility/type/` + slug);
            this.healthFacilityByCategory = response.data.content;
            this.activeCategory = response?.data?.data?.name;
            // console.log(response);
            // console.log(this.healthFacilityByCategory);
        },
        async setFacilityDetail(slug){
            this.facilityDetail = null;
            const response = await axiosClient.get(`facility/` + slug);
            this.facilityDetail = response.data.data;
            setPageData({
                title: this.facilityDetail.name,
                description: wordsLimit(removeHTML(this.facilityDetail.description), 200),
                ogData: {
                    title: this.facilityDetail.name,
                    type: 'website',
                    url: 'https://melindahospital.com/fasilitas-kesehatan/detail/' + slug,
                    image: this.facilityDetail.thumb_url,
                    description: wordsLimit(removeHTML(this.facilityDetail.description), 200)
                }
            });
            console.log(this.facilityDetail);
        },
        async setFacilityCategories(){
            const response = await axiosClient.get(`healthfacility/type`);
            this.categories = response.data.data;
            this.filteredCategories = response.data.data;
            this.getAllFacilities();
            console.log(this.filteredCategories);
        },
        // =====================
        async setOurSites(){
            try{
                const sitedata = [
                    {
                        id: 1,
                        name: 'RSIA Melinda',
                        category: 'Rumah Sakit Ibu dan Anak',
                        website: 'https://melindahospital.com/',
                        image: 'https://backend1.melindadev.com/images/history/62f4b14e8d89e.jpg'
                    },
                    {
                        id: 2,
                        name: 'RS Melinda 2',
                        category: 'Rumah Sakit Umum',
                        website: 'https://rsmelinda2.com/',
                        image: 'https://backend2.melindadev.com/images/history/62f4c7417d0f3.jpg'
                    },
                    {
                        id: 3,
                        name: 'Melinda Cardio Vascular',
                        category: 'Heart Center',
                        website: 'https://melindacvc.com/',
                        image: 'https://backend1.melindadev.com/images/history/62f4b14e8d89e.jpg'
                    }
                ];
                this.ourSites = sitedata;
                // const response = await axiosClient.get(`healthfacility/type/rumah-sakit`);
                // console.log(response);
            }catch(error){
                console.log(error);
            }
        },
        // =====================
        inputSelectCategory(value){
            // console.log(this.categories);
            if(this.categories && this.allFacilities){
                this.searchbarSelectCategory.input.value = value;
                if(value.length){
                    var newsd = [];
                    newsd = this.categories;
                    var result = [];
                    var inval = new RegExp(value, 'gi');
                    for(var i = 0; i < newsd.length; i++){
                        if(newsd[i].name.match(inval)){
                            result.push(newsd[i]);
                        }
                    }
                    this.filteredCategories = result;
                }else{
                    this.filteredCategories = this.categories;
                }
                // console.log(this.filteredCategories);
                if(this.allFacilities && value.length > 0){
                    var newsdx = [];
                    newsdx = this.allFacilities;
                    console.log(newsdx);
                    if(this.filteredCategories){
                        var resultx = [];
                        for(i = 0; i < this.filteredCategories.length; i++){
                            for(var j = 0; j < newsdx.length; j++){
                                if(newsdx[j].type_slug == this.filteredCategories[i].slug){
                                    resultx.push(newsdx[j]);
                                }
                                // for(var k = 0; k < newsdx[j].categories.length; k++){
                                //     if(newsdx[j].categories[k].slug == this.filteredCategories[i].slug){
                                //         resultx.push(newsdx[j]);
                                //     }
                                // }
                            }
                        }
                        // console.log(resultx[0]);
                        let uniqueresult = [...new Set(resultx.map(item => item))];
                        // console.log(uniqueresult);
                        // =============================
                        var resulty = [];
                        if(this.searchbarCategory.input.value){
                            var invalspec = new RegExp(this.searchbarCategory.input.value, 'gi');
                            for(i = 0; i < uniqueresult.length; i++){
                                if(uniqueresult[i].name.match(invalspec)){
                                    resulty.push(uniqueresult[i]);
                                }
                            }
                        }
                        // =============================
                        if(uniqueresult.length){
                            if(this.searchbarCategory.input.value){
                                this.filteredFacilities = resulty;
                            }else{
                                this.filteredFacilities = uniqueresult;
                                // console.log('result x');
                            }
                        }else{
                            this.filteredFacilities = [];
                        }
                    }else{
                        this.filteredFacilities = [];
                    }
                }else{
                    if(this.searchbarCategory.select.value != 'all'){
                        this.searchServiceAndCategory();
                    }else{
                        this.filteredFacilities = null;
                    }
                }
                console.log(this.filteredFacilities);
            }
        },
        selectCategory(category){
            this.searchbarCategory.select = {title: category.name, value: category.slug};
            this.searchServiceAndCategory();
            this.filteredCategories = this.categories;
        },
        inputSpecialist(value){
            this.searchbarCategory.input.value = value;
            if(this.searchbarSelectCategory.input.value == ''){
                this.searchServiceAndCategory();
            }else{
                this.beforeSelectedSpecialist();
            }
        },
        beforeSelectedSpecialist(){
            var newsdx = [];
            newsdx = this.allFacilities;
            if(this.filteredCategories){
                var resultx = [];
                for(var i = 0; i < this.filteredCategories.length; i++){
                    for(var j = 0; j < newsdx.length; j++){
                        for(var k = 0; k < newsdx[j].practice.length; k++){
                            if(newsdx[j].practice[k].specialization.slug == this.filteredCategories[i].slug){
                                resultx.push(newsdx[j]);
                            }
                        }
                    }
                }
                if(resultx.length){
                    var resulty = [];
                    var inval = new RegExp(this.searchbarCategory.input.value, 'gi');
                    for(i = 0; i < resultx.length; i++){
                        if(resultx[i].name.match(inval)){
                            resulty.push(resultx[i]);
                        }
                    }
                    this.filteredFacilities = resulty;
                }else{
                    this.filteredFacilities = [];
                }
            }else{
                this.filteredFacilities = null;
            }
        },
        searchServiceAndCategory(){
            if(this.allFacilities){
                var newsd = this.allFacilities;
                var result = [];
                if(this.searchbarCategory.select.value != 'all'){ // if selected specialist
                    for(var j = 0; j < newsd.length; j++){
                        if(newsd[j].type_slug == this.searchbarCategory.select.value){
                            result.push(newsd[j]);
                        }
                        // for(var k = 0; k < newsd[j].categories.length; k++){
                        //     if(newsd[j].categories[k].slug == this.searchbarCategory.select.value){
                        //         result.push(newsd[j]);
                        //     }
                        // }
                        // for(var k = 0; k < newsd[j].practice.length; k++){
                        //     if(newsd[j].practice[k].specialization.slug == this.searchbarCategory.select.value){
                        //         result.push(newsd[j]);
                        //     }
                        // }
                    }
                }else{
                    if(this.searchbarCategory.input.value){
                        result = this.allFacilities;
                    }
                }
                // console.log(result);
                var resultx = [];
                if(this.searchbarCategory.input.value){
                    var inval = new RegExp(this.searchbarCategory.input.value, 'gi');
                    for(var i = 0; i < result.length; i++){
                        if(result[i].name.match(inval)){
                            resultx.push(result[i]);
                        }
                    }
                }else{
                    resultx = result;
                }
                if(resultx.length){
                    this.filteredFacilities = resultx;
                }else{
                    this.filteredFacilities = null;
                }
                // console.log(this.filteredFacilities);
            }
        },
        searchSpecialist(){
            var newsd = [];
            newsd = this.categories;
            // console.log(newsd);
            if(this.searchbarCategory.input.value){
                var result = [];
                var inval = new RegExp(this.searchbarCategory.input.value, 'gi');
                for(var i = 0; i < newsd.length; i++){
                    if(newsd[i].name.match(inval)){
                        result.push(newsd[i]);
                    }
                }
                this.categoriesShow = result;
            }else{
                this.categoriesShow = newsd;
            }
        }
        // =====================
    }
});