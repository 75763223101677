<template>
    <div class="content">
        <TopBackgroundComponent/>
        <ResultComponent/>
    </div>
</template>
<script setup>
    import TopBackgroundComponent from "../components/TopBackgroundComponent.vue";
    import ResultComponent from "../components/order/ResultComponent.vue";
    window.scrollTo(0, 0);
</script>
<style scoped>
    /*  */
</style>
